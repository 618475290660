import { useState } from "react";
import { Stepper, Steppers } from "@appkit4/react-components";
import SupplementaryDetails from "./SupplementaryDetails";
import BusinessDetails from "./BusinessDetails";
import TechnologyDetails from "./TechnologyDetails";
import MigrationPlanning from "./MigrationPlanning";

const DataAssetSurveyReview: React.FC<{setIsReviewClicked: Function}> = ({setIsReviewClicked}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const onStepperChange = (i: number) => {
        setActiveIndex(i);
      }
      return (
        <Steppers space={1500} className="flex justify-between mt-4" activeIndex={activeIndex} onActiveIndexChange={onStepperChange}>
          <Stepper status='normal' label="Supplementary details">
            <SupplementaryDetails setActiveIndex={setActiveIndex} setIsReviewClicked={setIsReviewClicked}/>
          </Stepper>
          <Stepper status='normal' label="Business details">
            <BusinessDetails setActiveIndex={setActiveIndex}/>
          </Stepper>
          <Stepper status='normal' label="Technology details">
            <TechnologyDetails setActiveIndex={setActiveIndex} />
          </Stepper>
          <Stepper status='normal' label="Migration planning">
            <MigrationPlanning setActiveIndex={setActiveIndex} />
          </Stepper>
        </Steppers> 
      );
}

export default DataAssetSurveyReview;