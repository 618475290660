import { useState } from "react";
import { Stepper, Steppers } from "@appkit4/react-components";
import SupplementaryDetailsBusinessUser from "../survey/SupplementaryDetailsBusinessUser";
import BusinessDetailsBusinessUser from "../survey/BusinessDetailsBusinessUser";
import TechnologyDetailsBusinessUser from "../survey/TechnologyDetailsBusinessUser";
import MigrationPlanningBusinessUser from "../survey/MigrationPlanningBusinessUser";

const DataAssetSurveyDetails: React.FC<{setIsSurveyStarted: Function, setIsViewDetailsClicked: Function}> = ({setIsSurveyStarted, setIsViewDetailsClicked}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const onStepperChange = (i: number) => {
        setActiveIndex(i);
      }
      return (
        <Steppers space={1500} className="flex justify-between mt-4" activeIndex={activeIndex} onActiveIndexChange={onStepperChange}>
          <Stepper status='normal' label="Supplementary details">
            <SupplementaryDetailsBusinessUser setIsSurveyStarted={setIsSurveyStarted} setActiveIndex={setActiveIndex} setIsViewDetailsClicked={setIsViewDetailsClicked} />
          </Stepper>
          <Stepper status='normal' label="Business details">
            <BusinessDetailsBusinessUser setActiveIndex={setActiveIndex}/>
          </Stepper>
          <Stepper status='normal' label="Technology details">
            <TechnologyDetailsBusinessUser setActiveIndex={setActiveIndex} />
          </Stepper>
          <Stepper status='normal' label="Migration planning">
            <MigrationPlanningBusinessUser setActiveIndex={setActiveIndex} setIsSurveyStarted={setIsSurveyStarted} setIsViewDetailsClicked={setIsViewDetailsClicked}/>
          </Stepper>
        </Steppers> 
      );
}

export default DataAssetSurveyDetails;