import { Badge, Button, Notification, Panel } from "@appkit4/react-components";
import { useState } from "react";
import RemovalConfirmationModal from "./RemovalConfirmationModal";
import EditableFieldsDataAsset from "./EditableFieldsDataAsset";
import DataAssetFieldsFormProvider from "./DataAssetFieldsFormProvider";
import { useSelector } from "react-redux";
import { selectIsEditModeAsset, selectSelectedAsset } from "../../../store/project/projectSelectors";
import { assetCriticalityOptions, assetTypeOptions, dataAssetsWithPreviewMapping, frequencyOfUseOptions, migrationStatusOptions, radioOptions, surveyStatusIndicators, targetAudienceOptions } from "../../../services/constants";
import { setIsEditModeAsset } from "../../../store/project/projectSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import RequestOwnershipModal from "./RequestOwnershipModal";
import { KeycloakService } from "../../../services/KeycloakService";
import { getSurveyDetailsBU, reqToRemoveAssetOwnership, requestForAssetOwnership, updateDataAssetDetails, updateDataAssetOptionalDetails } from "../../../store/project/projectThunks";
import dayjs from "dayjs";
import DataAssetOptFieldsFormProvider from "./DataAssetOptFieldsFormProvider";
import OptionalEditableFieldsDataAsset from "./OptionalEditableFieldsDataAsset";
import { RequestDTO } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";

const DataAssetInfoBusinessUser: React.FC<{ selectedAssetRequestData: RequestDTO, setIsSurveyStarted: Function }> = ({ selectedAssetRequestData, setIsSurveyStarted }) => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [isRemovalModalShow, setIsRemovalModalShow] = useState(false);
    const [isRequestOwnerModalShow, setIsReqOwnerModalShow] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const selectedAsset = useSelector(selectSelectedAsset);
    
    const assetDetails = {
        "Asset type": assetTypeOptions?.filter(option => option.value === selectedAsset?.assetType)[0]?.label,
        "Asset description": selectedAsset?.assetDescription,
        "Created by": selectedAsset?.createdBy,
        "Created date": selectedAsset?.createdAt ? dayjs(new Date(selectedAsset.createdAt)).format("MM/DD/YYYY") : "",
        "Updated by": selectedAsset?.updatedBy,
        "Updated date": selectedAsset?.updatedAt ? dayjs(new Date(selectedAsset?.updatedAt)).format("MM/DD/YYYY") : "",
        "Environment": selectedAsset?.environment,
    }
    const assetDetailsUpdated = {
        ...assetDetails,
        "Development enviroment migrated": radioOptions.filter(option => option.value === selectedAsset?.devEnvCompleted)?.[0]?.label,
        "Development enviroment migrated date": selectedAsset?.devEnvCompletedDate ? dayjs(new Date(selectedAsset.devEnvCompletedDate)).format("MM/DD/YYYY") : "",
        "Data sources migrated": radioOptions.filter(option => option.value === selectedAsset?.dataSourcesMigrated)?.[0]?.label,
        "Data sources migrated date": selectedAsset?.dataSourcesMigratedDate ? dayjs(new Date(selectedAsset.dataSourcesMigratedDate)).format("MM/DD/YYYY") : "",
        "UAT environment migrated": radioOptions.filter(option => option.value === selectedAsset?.uatEnvCompleted)?.[0]?.label,
        "UAT environment migrated date": selectedAsset?.uatEnvCompletedDate ? dayjs(new Date(selectedAsset.uatEnvCompletedDate)).format("MM/DD/YYYY") : "",
        "Pre-production environment migrated": radioOptions.filter(option => option.value === selectedAsset?.prePodEnvCompleted)?.[0]?.label,
        "Pre-production environment migrated date": selectedAsset?.prePodEnvCompletedDate ? dayjs(new Date(selectedAsset.prePodEnvCompletedDate)).format("MM/DD/YYYY") : "",
        "Production environment migrated": radioOptions.filter(option => option.value === selectedAsset?.prodEnvCompleted)?.[0]?.label,
        "Production environment migrated date": selectedAsset?.prodEnvCompletedDate ? dayjs(new Date(selectedAsset.prodEnvCompletedDate)).format("MM/DD/YYYY") : "",
        "Complexity": assetCriticalityOptions.filter(option => option.value === selectedAsset?.assetComplexity)?.[0]?.label,
        "Frequency of use": frequencyOfUseOptions.filter(option => option.value === selectedAsset?.frequencyOfUse)?.[0]?.label,
        "Target audience": targetAudienceOptions.filter(option => option.value === selectedAsset?.targetAudience)?.[0]?.label,
        "Criticality": assetCriticalityOptions.filter(option => option.value === selectedAsset?.assetCriticality)?.[0]?.label
    }
    const isEditMode = useSelector(selectIsEditModeAsset);
    const closeRemovalModal = () => {
        setIsRemovalModalShow(false);
    }
    const closeReqOwnerModal = () => {
        setIsReqOwnerModalShow(false);
    }
    const removeAsset = (data: any) => {
        dispatch(reqToRemoveAssetOwnership({ assetBusinessId: selectedAsset?.assetBusinessId || "", assetTechnicalId: selectedAsset?.assetTechnicalId || "", requestDescription: data?.removeOwnership }))
        setIsRemovalModalShow(false);
    };
    const reqOwnershipOfAsset = (data: any) => {
        dispatch(requestForAssetOwnership({ assetBusinessId: selectedAsset?.assetBusinessId || "", assetTechnicalId: selectedAsset?.assetTechnicalId || "", requestDescription: data?.reqOwnerComment }))
        setIsReqOwnerModalShow(false);
    };
    const editDataAsset = (data: any) => {
        const payload = {
            assetBusinessId: selectedAsset.assetBusinessId,
            assetTechnicalId: selectedAsset.assetTechnicalId,
            assetName: data.assetName,
            assetType: data.assetType,
            assetDescription: data.assetDescription,
            environment: data.environment
        }
        dispatch(setIsEditModeAsset(false));
        dispatch(updateDataAssetDetails(payload));
    }
    const editDataAssetOptionalFields = (data: any) => {
        const payload = {
            assetBusinessId: selectedAsset.assetBusinessId,
            assetTechnicalId: selectedAsset.assetTechnicalId,
            assetCriticality: data?.assetCriticality,
            devEnvCompleted: data.isDevMigrated,
            devEnvCompletedDate: data.isDevMigrated === "yes" ? new Date(`${data.devMigrationDate} UTC`).toISOString() : undefined,
            dataSourcesMigrated: data.isDataSourcesMigrated,
            dataSourcesMigratedDate: data.isDataSourcesMigrated === "yes" ? new Date(`${data.dataSourcesMigrationDate} UTC`).toISOString() : undefined,
            uatEnvCompleted: data?.isUatMigrated,
            uatEnvCompletedDate: data?.isUatMigrated === "yes" ? new Date(`${data?.uatMigrationDate} UTC`).toISOString() : undefined,
            prePodEnvCompleted: data?.isPreProductionMigrated,
            prePodEnvCompletedDate: data?.isPreProductionMigrated === "yes" ? new Date(`${data?.preProductionMigrationDate} UTC`).toISOString() : undefined,
            prodEnvCompleted: data?.isProductionMigrated,
            prodEnvCompletedDate: data?.isProductionMigrated === "yes" ? new Date(`${data?.productionMigrationDate} UTC`).toISOString() : undefined,
            assetComplexity: data?.complexity,
            frequencyOfUse: data?.frequency,
            targetAudience: data?.targetAudience
        }
        dispatch(setIsEditModeAsset(false));
        dispatch(updateDataAssetOptionalDetails(payload));
    }
    const startSurvey = () => {
        setIsSurveyStarted(true);
        dispatch(getSurveyDetailsBU(selectedAsset?.assetBusinessId));
    }
    const requestOwnershipDisabled = selectedAssetRequestData?.requestStatus === "pending" && selectedAssetRequestData?.requestType === "ownershipOfAsset";
    const removeOwnershipDisabled = selectedAssetRequestData?.requestStatus === "pending" && selectedAssetRequestData?.requestType === "removeOwnership";
    // const editAssetDisabled = selectedAssetRequestData?.requestStatus === "pending" && selectedAssetRequestData?.requestType === "requestEditAsset";
    const icon = dataAssetsWithPreviewMapping?.filter((dataAsset) => dataAsset.name === selectedAsset?.assetType)?.[0]?.prefixIcon;
    const selectedStatus = surveyStatusIndicators.filter(statusDetail => statusDetail.status === selectedAsset.surveyStatus)[0]
    return (
        <>
            <div className="flex flex-row mt-4">
                <span className={`Appkit4-icon icon-${icon}-fill ap-font-16 pt-1 text-[#415385]`} aria-hidden="true"></span>
                <h1 className="font-sans font-medium text-xl text-[#415385]">{selectedAsset?.assetName}</h1>
            </div>
            {/* <div className="my-4">
                <Notification
                    title='Notification'
                    className="w-full m-0"
                    message={'Ownership of the asset has been denied because the current owner has more relevant expertise and responsibility for its management, ensuring continuity and accuracy in the migration process.'}
                    status=''
                    icon={<span className="Appkit4-icon icon-notifications-alert-fill ap-font-16 pt-1 text-[#FFBF1F]" aria-hidden="true"></span>}
                />
            </div> */}
            {!(requestOwnershipDisabled || selectedAsset.owner === KeycloakService.getEmail() || selectedAsset?.assetStatus === "pendingForReview") &&
                <div className="my-2">
                    <Panel title="Ownership of the asset" >
                        <div className="flex flex-row gap-2 justify-between">
                            <span>If this asset belongs to you, please click 'Request Ownership' and provide a justification in the comment area. Once ownership is confirmed, you will be able to edit the asset, request its removal if necessary, and proceed to fill out the asset's survey.</span>
                            <Button kind="primary" icon="icon-lockopen-unlock-outline" onClick={() => setIsReqOwnerModalShow(true)}>Request ownership</Button>
                        </div>
                    </Panel>
                    <RequestOwnershipModal visible={isRequestOwnerModalShow} closeModal={closeReqOwnerModal} reqOwnershipOfAsset={reqOwnershipOfAsset} />
                </div>
            }
            <div className="my-4 bg-white">
                <div className="flex justify-between">
                    <div className="flex flex-row gap-2 p-6 items-center">
                        <div className="font-sans font-medium text-xl">Asset survey</div>
                        <Badge type={selectedStatus?.statusIndicator} value={selectedStatus?.label} />
                        {(selectedAsset?.surveyStatus === "requested" || selectedAsset?.surveyStatus === "onGoing") &&
                            <>
                                <span className="Appkit4-icon icon-calendar-clock-outline ap-font-16 pt-1" aria-hidden="true"></span>
                                <span>{`Survey deadline by ${dayjs(new Date(selectedAsset?.surveyDeadline || "")).format("DD/MM/YYYY")}`}</span>
                            </>
                        }
                    </div>
                    {selectedAsset?.surveyStatus === "requested" && selectedAsset?.owner === KeycloakService?.getEmail() && <Button className="m-6" kind="primary" icon="icon-document-text-outline" onClick={startSurvey}>Start survey</Button>}
                    {selectedAsset?.surveyStatus === "onGoing" && selectedAsset?.owner === KeycloakService?.getEmail() && <Button className="m-6" kind="primary" icon="icon-view-outline" onClick={startSurvey}>View</Button>}
                </div>
            </div>
            <div className="my-4 flex gap-4 justify-bewtween">
                <div className="bg-white flex justify-between w-full p-6">
                    <div className="font-sans font-medium text-xl">Migration status</div>
                    <Badge value={selectedAsset?.assetBlocked ? "Blocked" : migrationStatusOptions.filter((status) => status.value === selectedAsset?.migrationStatus)?.[0]?.label} type={"info"} />
                </div>
                <div className="bg-white flex justify-between w-full p-6">
                    <div className="font-sans font-medium text-xl"><Badge className="mr-2" value={0} type={'primary'} />Use cases</div>
                    <Button kind='text' icon="icon-arrow-right-small-outline" onClick={() => navigate(`/project/${projectId}/useCase/`)}>See details</Button>
                </div>
            </div>
            <div className="my-4 bg-white">
                <div className="flex justify-between p-6">
                    <div className="font-sans font-medium text-xl">{isEditMode ? 'Edit details' : 'Details'}</div>
                    {isEditMode ?
                        <div className="flex justify-end gap-2">
                            <Button kind='secondary' icon="icon-arrow-left-small-outline" onClick={() => dispatch(setIsEditModeAsset(false))}>Go back</Button>
                            {/* {editAssetDisabled &&
                                <Button kind="primary" disabled >Waiting for approval</Button>
                            } */}
                        </div>
                        :
                        <Button kind='primary' disabled={selectedAsset.owner !== KeycloakService.getEmail()} icon="icon-pencil-outline" onClick={() => dispatch(setIsEditModeAsset(true))}>Edit</Button>
                    }
                </div>
                {!isEditMode ?
                    <div className="px-6 pb-6">
                        {selectedAsset?.isSupplimentaryDetailsUpdated ?
                            <>
                                {Object.entries(assetDetailsUpdated).map(([key, value]) => (
                                    <>
                                        {value && value !== "" &&
                                            <div className="flex justify-between bg-[#F3F3F3] border p-2" key={key}>
                                                <div>{key}</div>
                                                <div>{value}</div>
                                            </div>
                                        }
                                    </>
                                ))}
                            </>
                            :
                            <>
                                {Object.entries(assetDetails).map(([key, value]) => (
                                    <div className="flex justify-between bg-[#F3F3F3] border p-2" key={key}>
                                        <div>{key}</div>
                                        <div>{value}</div>
                                    </div>
                                ))}
                            </>
                        }

                    </div>
                    :
                    <div className="px-4 pb-6">
                        {/* {!editAssetDisabled ? */}
                            <DataAssetFieldsFormProvider onSubmit={editDataAsset}>
                                <EditableFieldsDataAsset selectedAsset={selectedAsset} />
                            </DataAssetFieldsFormProvider>
                            {/* :
                            <>
                                {Object.entries(assetDetails).map(([key, value]) => (
                                    <div className="flex justify-between bg-[#F3F3F3] border p-2" key={key}>
                                        <div>{key}</div>
                                        <div>{value}</div>
                                    </div>
                                ))}
                            </>
                        } */}
                        <DataAssetOptFieldsFormProvider onSubmit={editDataAssetOptionalFields}>
                            <OptionalEditableFieldsDataAsset selectedAsset={selectedAsset} />
                        </DataAssetOptFieldsFormProvider>
                    </div>
                }
            </div>
            {!(removeOwnershipDisabled || selectedAsset.owner !== KeycloakService.getEmail() || selectedAsset?.assetStatus === "pendingForReview") &&
                <div className="my-2">
                    <Panel title="Remove asset" >
                        <div className="flex flex-row gap-2 justify-between">
                            <span className="text-sm">If you have a reason for this asset to be removed, please write it in the comments and inform the manager.</span>
                            <Button kind="primary" icon="icon-delete-outline" onClick={() => setIsRemovalModalShow(true)}>Request removal</Button>
                        </div>
                    </Panel>
                    <RemovalConfirmationModal visible={isRemovalModalShow} closeModal={closeRemovalModal} removeAsset={removeAsset} />
                </div>
            }
        </>
    )
}

export default DataAssetInfoBusinessUser;