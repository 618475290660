import { useFormContext } from "react-hook-form";
import { ControlledInput } from "../../../core/components";
import BusinessDetailsFormProvider from "./BusinessDetailsFormProvider";
import ControlledRadioGroup from "../../../core/components/ControlledRadioGroup";
import { yesNoOptions } from "../../../services/constants";
import { useEffect, useState } from "react";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { getSurveyDetailsBU, getUseCaseList, updateSurveyDetailsBU } from "../../../store/project/projectThunks";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectSurveyDetails, selectUseCaseListData } from "../../../store/project/projectSelectors";
import ControlledMultiSelectUseCase from "../../../core/components/ControlledMultiSelectUseCase";
import { Button } from "@appkit4/react-components";
import dayjs from "dayjs";
import { SurveyDataDTO } from "../../../api";
import SurveyReviewCommentDetails from "./SurveyReviewCommentDetails";

type BusinessDetailsFormErrors = {
    departmentName: string;
    primaryUserGroup: string;
    isAssetTiedInProjet: string;
    tiedprojectName?: string;
};

const BusinessDetailsForm: React.FC<{setActiveIndex: Function, surveyDetailsData: SurveyDataDTO}> = ({setActiveIndex, surveyDetailsData}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { projectId } = useParams();
    const { formState: { errors } } = useFormContext<BusinessDetailsFormErrors>();
    const { getValues, setValue } = useFormContext();
    const [isUpdated, setIsUpdated] = useState(false);
    const useCasesList = useSelector(selectUseCaseListData);
    
    useEffect(() => {
        if(projectId){
            dispatch(getUseCaseList({ pageNumber: 1, pageSize: "100", searchString: "", projectBusinessId: projectId }));
        }  
    },[dispatch, projectId, setValue]);
    useEffect(() => {
        const surveyData = JSON.parse(JSON.stringify(surveyDetailsData?.surveyDetailsDTO?.surveyData));
        setValue("departmentName", surveyData?.businessDetails?.departmentsName);
        setValue("primaryUserGroup", surveyData?.businessDetails?.primaryUserGroups);
        setValue("isAssetTiedInProjet", surveyData?.businessDetails?.tiedInAnotherProject ? "yes" : "no");
        setValue("tiedprojectName", surveyData?.businessDetails?.tiedprojectName);
        setValue("useCases", surveyData?.businessDetails?.useCases);
        setIsUpdated(true);
    },[surveyDetailsData, setValue])
    const useCasesDropDownList = useCasesList?.data?.map(useCase => {return {value: useCase.useCaseBusinessId, label: useCase.name}})
    return(
        <div>
            <div className="flex justify-between">
                <h1 className="font-sans font-bold font-medium text-2xl py-4">Business details</h1>
                {surveyDetailsData?.surveyDetailsDTO?.updatedAt && surveyDetailsData?.surveyDetailsDTO?.updatedAt !== "" &&
                    <div className="w-fit mr-4 mt-4">
                        <span className={`Appkit4-icon icon-update-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                        <span>Last saved at {dayjs(new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "")).format("MM/DD/YYYY")}, </span>
                        <span>{Math.floor((new Date().getTime() - new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "").getTime()) / (1000 * 60 * 60 * 24))} days ago</span>
                    </div>
                }
            </div>
            <div className="p-8 bg-white">
                <div className="my-4">
                    <div className="mt-4 mb-2">Department name</div>
                    <ControlledInput name="departmentName" required={true} type="name" label="Department name" error={errors.departmentName} />
                    {errors.departmentName && <p className="text-red-500 mt-2">{errors.departmentName.message}</p>}
                </div>
                <div className="my-4">
                    <div className="mt-4 mb-2">Primary user group</div>
                    <ControlledInput name="primaryUserGroup" required={true} type="name" label="Primary user group" error={errors.primaryUserGroup} />
                    {errors.primaryUserGroup && <p className="text-red-500 mt-2">{errors.primaryUserGroup.message}</p>}
                </div>
                <div className="my-4">
                    <div className="mt-4 mb-2">Is the asset currently tied in another project</div>
                    <ControlledRadioGroup className="flex flex-col gap-2" name="isAssetTiedInProjet" options={yesNoOptions} value={getValues().isAssetTiedInProjet || 'no'}/>
                </div>
                {getValues().isAssetTiedInProjet === "yes" &&
                    <div className="my-4">
                        <div className="mt-4 mb-2">If yes, please specify the project</div>
                        <ControlledInput name="tiedprojectName" required={getValues().isAssetTiedInProjet === "yes"} type="name" label="Project" error={errors.tiedprojectName} />
                        {errors.tiedprojectName && <p className="text-red-500 mt-2">{errors.tiedprojectName.message}</p>}
                    </div>
                }
                <div className="my-4">
                    <div className="mt-4 mb-2">Select the use cases</div>
                    <ControlledMultiSelectUseCase name="useCases" required={false} label="Use cases" data={useCasesDropDownList || []} value={getValues().useCases} />
                </div>
                <div className="flex justify-end gap-2">
                    <Button kind='secondary' icon="icon-arrow-left-outline" onClick={() => setActiveIndex(0)}>Back</Button>
                    <Button kind='primary' icon="icon-check-hook-outline" disabled={!isUpdated} type="submit">Save and Next</Button>
                </div>
            </div>
        </div>
    )
}

const BusinessDetailsBusinessUser: React.FC<{setActiveIndex: Function}> = ({setActiveIndex}) => {
    const surveyDetailsData = useSelector(selectSurveyDetails);
    const dispatch = useDispatch<AppDispatch>();
    const submitBusinessDetails = async (data: any) => {
        const businessDetails = {
            departmentsName: data.departmentName,
            primaryUserGroups: data.primaryUserGroup,
            tiedInAnotherProject: data.isAssetTiedInProjet === "yes" ? true : false,
            tiedprojectName: data?.tiedprojectName,
            useCases: data.useCases,
        }
        await dispatch(updateSurveyDetailsBU({assetId: surveyDetailsData?.supplementaryDetails?.assetBusinessId, surveyState: "businessDetails", businessDetails: businessDetails}));
        await dispatch(getSurveyDetailsBU(surveyDetailsData?.supplementaryDetails?.assetBusinessId));
        setActiveIndex(2)
    }
    return(
        <>
            {surveyDetailsData?.surveyDetailsDTO?.reviewComment && surveyDetailsData?.surveyDetailsDTO?.reviewComment !== "" && surveyDetailsData?.surveyDetailsDTO?.surveyStatus === "onGoing" &&
                <SurveyReviewCommentDetails surveyData={surveyDetailsData?.surveyDetailsDTO} />
            }
            <BusinessDetailsFormProvider onSubmit={submitBusinessDetails}>
                <BusinessDetailsForm setActiveIndex={setActiveIndex} surveyDetailsData={surveyDetailsData}/>
            </BusinessDetailsFormProvider>
        </>
    )
}

export default BusinessDetailsBusinessUser;