import { Radio, RadioGroup } from '@appkit4/react-components';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

type ControlledRadioGroupProps = {
  name: string;
  label?: string;
  error?: FieldError,
  required?: boolean,
  options: Array<{ label: string, value: string }>,
  value: string,
  className?: string
};

const ControlledRadioGroup: React.FC<ControlledRadioGroupProps> = ({ name, label = "", error, required = false, options, value, className }) => {
  const { control, setValue, setError } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={required ? { required: `Missing ${label.toLowerCase()}` } : {}}
      render={() => <RadioGroup
        className={className || "flex gap-16"}
        name={label}
        onChange={(e: any) => {
          setValue(name, e)
          setError(name, {})
        }}
        value={value}
      >
        {options.map((option) =>
          <Radio value={option.value} key={option.value}>{option.label}</Radio>
        )}

      </RadioGroup>
      }
    />
  );
};

export default ControlledRadioGroup;
