import { Button, Modal, Select } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUsersListByRole } from "../../../store/project/projectSelectors";

interface OwnershipUpdateModalProps {
    visible: boolean;
    closeModal: Function;
    editOwnership: Function;
    ownerEmail?: string
}

const OwnershipUpdateModal: React.FC<OwnershipUpdateModalProps> = ({ visible, closeModal, editOwnership, ownerEmail }) => {
    const [emailValue, setEmailValue] = useState(ownerEmail);
    const listOfUsers = useSelector(selectUsersListByRole);
    const businessUsers = listOfUsers?.filter((usersList: any) => usersList.role === "BusinessUser")[0]?.users.map((user:any) => {return{value: user.email, label: user.email}});
    useEffect(() => {
        setEmailValue(ownerEmail);
    }, [ownerEmail]);
    return (
        <Modal
            visible={visible}
            title={"Ownership of asset"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
            footer={
                <>
                    <Button onClick={closeModal} kind="secondary">Cancel</Button>
                    <Button icon="icon-check-hook-outline" onClick={()=>editOwnership(emailValue)}>Proceed</Button>
                </>
            }
            bodyStyle={{ minHeight: '92px' }}
        >
            <div className="font-small text-[#696969] my-4">
                You can change the ownership to another user if necessary. Please review the changes thoroughly.
            </div>
            <Select className="py-2" data={businessUsers} dropdownRenderMode="portal" placeholder="owner" value={emailValue} onSelect={(e) => setEmailValue(e.toString())}></Select>
        </Modal>);
}

export default OwnershipUpdateModal;