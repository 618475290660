import { Accordion, AccordionItem, TextArea } from "@appkit4/react-components";
import { SurveyDTO } from "../../../api";
import { useState } from "react";
import dayjs from "dayjs";

const SurveyReviewCommentDetails: React.FC<{ surveyData: SurveyDTO }> = ({ surveyData }) => {
    const [activeKeys, setActiveKeys] = useState<string[]>(['1']);
    const onClickAccordion = (activeKeys: string[]) => {
        setActiveKeys(activeKeys);
    }
    return (
        <Accordion multiple={false} onClick={onClickAccordion} activeKeys={activeKeys}>
            <AccordionItem title={"Survey Review"} itemKey="1" className="my-6 bg-white hover:bg-white">
                <div className="flex flex-row justify-between">
                    <div>
                        <span>The manager has declined the survey. Please ensure the following aspects are checked:</span>
                    </div>
                    <div>
                        <span className="text-[#696969]">Submitted: </span>
                        <span> {dayjs(new Date(surveyData.updatedAt || "")).format("MM/DD/YYYY")}, </span>
                        <span>{Math.floor((new Date().getTime() - new Date(surveyData?.updatedAt || "").getTime()) / (1000 * 60 * 60 * 24))} days ago</span>
                    </div>
                </div>
                <div>
                    <TextArea title={"Review comments:"} value={surveyData?.reviewComment} readonly />
                </div>
            </AccordionItem>
        </Accordion>
    )
}

export default SurveyReviewCommentDetails;