import { Button, CalendarPicker } from "@appkit4/react-components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectSurveyListingData } from "../../../store/project/projectSelectors";
import { SurveyDTO } from "../../../api";
import { assetTypeOptions, dataAssetsWithPreviewMapping } from "../../../services/constants";

const AssignDetailsForAssets: React.FC<{setActiveIndex: Function, selectedSurveys: Array<string>, defineSurveysDeadline: Function}> = ({setActiveIndex, selectedSurveys, defineSurveysDeadline}) => {
    const [date, setDate] = useState();
    const surveyListOptions = useSelector(selectSurveyListingData);
    let updatedList: Array<any> = []
    surveyListOptions?.data?.forEach((listItem) =>{
        selectedSurveys?.forEach((survey) => {
            if(survey === listItem.assetBusinessId)
                updatedList.push(listItem)
        });
    });
    const defineDeadLine = () => {
        defineSurveysDeadline(selectedSurveys, date);
    }
    return(
        <div className="my-4">
            <h1 className="font-sans font-medium text-xl ml-4 mt-2">Assign details</h1>
            <div className="m-4">Please select a deadline for the asset surveys you have previously selected. This ensures that the surveys are completed on time and the necessary information is gathered efficiently.</div>
            <div className="bg-white">
                <h1 className="font-sans font-medium text-xl ml-4 mt-2">Deadline</h1>
                <div className="p-4 w-1/2">
                    <CalendarPicker name="deadLineDate" required={true} 
                    fieldWidth="full"
                    placeholder="mm/dd/yyyy"
                    locale="en"
                    fieldTitle={"Deadline"}
                    onValueChange={setDate}/>
                </div>
                <h1 className="font-sans font-medium text-xl ml-4 mt-2">Selected items</h1>
                <div className="m-4 border-2">
                    <div className="flex justify-start">
                        <div className="py-4 pl-10 w-1/3 font-bold">Name</div>
                        <div className="py-4 pl-16 w-1/3 font-bold">Type</div>
                    </div>
                    {updatedList?.map((survey: SurveyDTO) =>
                        <div className="flex justify-start my-4">
                            <div className="w-1/3 mx-8">
                                <span className={`Appkit4-icon icon-${dataAssetsWithPreviewMapping.filter(dataAsset => dataAsset.name === survey.assetType)[0]?.prefixIcon}-outline ap-font-24 px-4`} aria-hidden="true"></span>
                                <span className="pl-2">{survey.assetName}</span>
                            </div>
                            <div className="w-1/3">{assetTypeOptions.filter(option => option.value === survey?.assetType)?.[0]?.label}</div>
                        </div>
                    )}
                </div>
                <div className="flex justify-end gap-2 p-4">
                    <Button kind='secondary' onClick={() => setActiveIndex(0)}>Back</Button>
                    <Button kind='primary' disabled={!date} icon="icon-check-hook-outline" onClick={defineDeadLine}>Define deadline</Button>
                </div>
            </div>
        </div>
    )

    
}

export default AssignDetailsForAssets;