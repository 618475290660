import { Button, Loading, Panel } from '@appkit4/react-components';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { getProjectsList } from '../../../store/project/projectThunks';
import { selectProjectConfigData, selectProjectError, selectProjectLoading, selectScopeDataOverView, selectSelectedProjectData } from '../../../store/project/projectSelectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const ProjectDetailsContainer = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const selectedProject = useSelector(selectSelectedProjectData);
    const projectConfigData = useSelector(selectProjectConfigData);
    const scopeOverviewData = useSelector(selectScopeDataOverView);
    const loading = useSelector(selectProjectLoading);
    const error = useSelector(selectProjectError);
    const pullNewLineage = () => {
        navigate(`/project/${selectedProject?.projectBusinessId}/lineage`);
    }
    const dataAssetsDetails = [
        {
            name: "AI Model",
            icon: "bot",
            noOfAssets: scopeOverviewData?.aiModel
        },
        {
            name: "Applications",
            icon: "box",
            noOfAssets: scopeOverviewData?.application
        },
        {
            name: "Dashboards",
            icon: "dashboard",
            noOfAssets: scopeOverviewData?.dashboard
        },
        {
            name: "Reports",
            icon: "document-text",
            noOfAssets: scopeOverviewData?.report
        },
        {
            name: "SAS Scripts",
            icon: "query",
            noOfAssets: scopeOverviewData?.sasScript
        },
    ]
    if(loading){
        return(
            <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
        )
    }
    return (
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        dispatch(getProjectsList()); 
                        navigate("/dashboard")
                    }}>All Projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    {selectedProject?.name}
                </BreadcrumbItem>
            </Breadcrumb>
            <h1 className="font-sans font-medium text-xl mt-4">{selectedProject?.name}</h1>
            <div className="text-[#696969]">{selectedProject?.description}</div>
            <div className="flex mt-4">
                <Panel title={"Team Members"} className="flex-1 mr-2">
                    <p>This project has {(selectedProject?.adminList?.length || 0) + (selectedProject?.managerList?.length || 0) + (selectedProject?.businessUserList?.length || 0)} users</p>
                    <div className="flex justify-end mt-8">
                        <Button kind='secondary' icon="icon-community-outline" onClick={() => navigate(`/project/${selectedProject?.projectBusinessId}/users`)}>Manage members</Button>
                    </div>
                </Panel>
                <Panel title={"Project Configuration"} className="flex-1 ml-2">
                    <p>Edit the project and other options</p>
                    <div className="flex justify-end mt-8">
                        <Button kind='secondary' icon="icon-cogs-outline" onClick={() => navigate(`/project/${selectedProject?.projectBusinessId}/edit`)}>Configuration</Button>
                    </div>
                </Panel>
            </div>
            {projectConfigData?.config?.lineagePulled === true &&
                <div className="flex mt-4">
                    <Panel className="flex-1">
                        <div className="flex justify-between">
                            <div className="mt-4">
                                <h1 className="font-medium text-xl py-2">Project Assets</h1>
                                <p>{`${scopeOverviewData?.totalAssets} data assets available`}</p>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            {dataAssetsDetails?.map((dataAsset, index) =>
                                <div className="w-1/5 lg:w-1/5 sm:w-1/2 mt-4 px-2" key={index}>
                                    <Panel className="bg-gray-100">
                                        <div className="font-medium text-xl py-2">{dataAsset.name}</div>
                                        <span className={`Appkit4-icon icon-${dataAsset.icon}-outline ap-font-24`} aria-hidden="true"></span>
                                        <span className="ml-2 font-bold text-4xl">{dataAsset.noOfAssets}</span>
                                    </Panel>
                                </div>
                            )}
                        </div>
                    </Panel>
                </div>
            }
            <div className="flex mt-4">
                <Panel title={"View lineage model"} className="flex-1">
                    <p>During the migration, you will have the ability to access additional lineage modes or add any pending ones. Once these lineage models are available, you will receive a notification.</p>
                    <div className="flex justify-end mt-8">
                        {projectConfigData?.config?.lineagePulled === false &&
                            <div className="w-fit mr-4 mt-2">
                                <span className={`Appkit4-icon icon-flag-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                                Lineage has not been pulled.
                            </div>
                        }
                        {projectConfigData?.config?.lineagePulled === true &&
                            <div className="w-fit mr-4 mt-2">
                                <span className={`Appkit4-icon icon-update-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                                Last updated lineage on {dayjs(new Date(projectConfigData.config.lineagePulledUpdatedDate || "")).format("MM/DD/YYYY")}
                            </div>
                        }
                        <Button kind='secondary' icon="icon-upload-outline" onClick={pullNewLineage}>Lineage model</Button>
                    </div>
                </Panel>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default ProjectDetailsContainer;