import React, { useEffect, useState } from "react"
import TechnologyDetailsFormProvider from "./TechnologyDetailsFormProvider"
import { useSelector } from "react-redux"
import { selectSelectedAsset, selectSurveyDetails } from "../../../store/project/projectSelectors"
import { AssetDTO, SurveyDataDTO } from "../../../api"
import dayjs from "dayjs"
import ControlledRadioGroup from "../../../core/components/ControlledRadioGroup"
import { apiLayerOptions, techValuesOptions, trainingLifeCycleOptions, yesNoOptions } from "../../../services/constants"
import { useFormContext } from "react-hook-form"
import { ControlledDropdown, ControlledInput } from "../../../core/components";
import { Button } from "@appkit4/react-components"
import { AppDispatch } from "../../../store"
import { useDispatch } from "react-redux"
import { getSurveyDetailsBU, updateSurveyDetailsBU } from "../../../store/project/projectThunks"
import SurveyReviewCommentDetails from "./SurveyReviewCommentDetails"

type TechnologyFormErrors = {
    technologyVersion?: string;
    reasonForNotUseETL?: string
};

const TechnologyDetailsBusinessUser: React.FC<{ setActiveIndex: Function }> = ({ setActiveIndex }) => {
    const surveyDetailsData = useSelector(selectSurveyDetails);
    const dispatch = useDispatch<AppDispatch>();
    const submitTechDetails = async (data: any) => {
        let technologyDetails = {};
        if(surveyDetailsData?.surveyDetailsDTO?.assetType === "dashboard") {
            technologyDetails = {
                dashboard: {
                    realTimeData: data?.isDashboardshowRealData
                },
            };
        };
        if(surveyDetailsData?.surveyDetailsDTO?.assetType === "report") {
            technologyDetails = {
                report: {
                    technology: data?.technology,
                    version: data?.technologyVersion
                }
            }
        };
        if(surveyDetailsData?.surveyDetailsDTO?.assetType === "aiModel") {
            technologyDetails = {
                aiModel: {
                    trainingLifeCycle: data?.trainingLifeCycle,
                }
            }
        }
        if(surveyDetailsData?.surveyDetailsDTO?.assetType === "application") {
            technologyDetails = {
                application: {
                    apiLayerExists: data?.hasAPILayer === "yes" ? true : false,
                    apiLayer: data?.APILayerType
                }
            }
        }
        if(surveyDetailsData?.surveyDetailsDTO?.assetType === "sasScript") {
            technologyDetails = {
                sasScript: {
                    sasForETL: data?.isUsedForETL === "yes" ? true : false,
                    reason: data?.reasonForNotUseETL
                }
            }
        }
        await dispatch(updateSurveyDetailsBU({assetId: surveyDetailsData?.supplementaryDetails?.assetBusinessId, surveyState: "technologyDetails", technologyDetails: technologyDetails}));
        await dispatch(getSurveyDetailsBU(surveyDetailsData?.supplementaryDetails?.assetBusinessId));
        setActiveIndex(3)
    }
    const selectedAsset = useSelector(selectSelectedAsset);
    return (
        <>
            {surveyDetailsData?.surveyDetailsDTO?.reviewComment && surveyDetailsData?.surveyDetailsDTO?.reviewComment !== "" && surveyDetailsData?.surveyDetailsDTO?.surveyStatus === "onGoing" &&
                <SurveyReviewCommentDetails surveyData={surveyDetailsData?.surveyDetailsDTO} />
            }
            <TechnologyDetailsFormProvider onSubmit={submitTechDetails}>
                <TechDetailsForm selectedAsset={selectedAsset} setActiveIndex={setActiveIndex} surveyDetailsData={surveyDetailsData}/>
            </TechnologyDetailsFormProvider>
        </>
    );
}

const TechDetailsForm: React.FC<{ selectedAsset: AssetDTO, setActiveIndex: Function, surveyDetailsData: SurveyDataDTO }> = ({ selectedAsset, setActiveIndex, surveyDetailsData}) => {
    const { formState: { errors } } = useFormContext<TechnologyFormErrors>();
    const { getValues, setValue } = useFormContext();
    const [ isUpdated, setIsUpdated ] = useState(false);
    useEffect(() => {
        const surveyData = JSON.parse(JSON.stringify(surveyDetailsData?.surveyDetailsDTO?.surveyData));
        setValue("isDashboardshowRealData", surveyData?.technologyDetails?.dashboard?.realTimeData ? "yes" : "no");
        setValue("technology", surveyData?.technologyDetails?.report?.technology);
        setValue("technologyVersion", surveyData?.technologyDetails?.report?.version);
        setValue("trainingLifeCycle", surveyData?.technologyDetails?.aiModel?.trainingLifeCycle);
        setValue("hasAPILayer", surveyData?.technologyDetails?.application?.apiLayerExists ? "yes" : "no");
        setValue("APILayerType", surveyData?.technologyDetails?.application?.apiLayer);
        setValue("isUsedForETL", surveyData?.technologyDetails?.sasScript?.sasForETL ? "yes" : "no");
        setValue("reasonForNotUseETL", surveyData?.technologyDetails?.sasScript?.reason);
        setIsUpdated(true);
    },[surveyDetailsData, setValue])
    return (
        <div>
            <div className="flex justify-between">
                <h1 className="font-sans font-bold font-medium text-2xl py-4">Technology details</h1>
                {surveyDetailsData?.surveyDetailsDTO?.updatedAt && surveyDetailsData?.surveyDetailsDTO?.updatedAt !== "" &&
                    <div className="w-fit mr-4 mt-4">
                        <span className={`Appkit4-icon icon-update-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                        <span>Last saved at {dayjs(new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "")).format("MM/DD/YYYY")}, </span>
                        <span>{Math.floor((new Date().getTime() - new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "").getTime()) / (1000 * 60 * 60 * 24))} days ago</span>
                    </div>
                }
            </div>
            <div className="p-8 bg-white">
                {selectedAsset?.assetType === "dashboard" &&
                    <div className="my-4">
                        <div className="mt-4 mb-2">Is the dashboard showing real time data?</div>
                        <ControlledRadioGroup className="flex flex-col gap-2" name="isDashboardshowRealData" required={true} options={yesNoOptions} value={getValues().isDashboardshowRealData || 'no'}/>
                </div>
                }
                {selectedAsset?.assetType === "report" &&
                    <>
                        <div className="my-4">
                            <div className="mt-4 mb-2">What is the technology behind the report?</div>
                            <ControlledDropdown name="technology" label="Technology" data={techValuesOptions} />
                        </div>
                        {getValues().technology === "sapBusinessObject" &&
                            <div className="my-4">
                                <div className="mt-4 mb-2">If, the technology is SAP Business object which version is it?</div>
                                <ControlledInput name="technologyVersion" type="text" label="Version" required={getValues().technology === "sapBusinessObject"} error={errors.technologyVersion} />
                                {errors.technologyVersion && <p className="text-red-500 mt-2">{errors.technologyVersion.message}</p>}
                            </div>
                        }
                    </>
                }
                {selectedAsset?.assetType === "aiModel" &&
                    <div className="my-4">
                        <div className="mt-4 mb-2">What is the training life cycle?</div>
                        <ControlledDropdown name="trainingLifeCycle" label="Training life cycle" data={trainingLifeCycleOptions} />
                    </div>
                }
                {selectedAsset?.assetType === "application" &&
                    <>
                        <div className="my-4">
                            <div className="mt-4 mb-2">Does this application have an API layer?</div>
                            <ControlledRadioGroup className="flex flex-col gap-2" name="hasAPILayer" options={yesNoOptions} value={getValues().hasAPILayer || 'no'}/>
                        </div>
                        {getValues().hasAPILayer === "no" &&
                            <div className="my-4">
                                <div className="mt-4 mb-2">If no, what is the type?</div>
                                <ControlledDropdown name="APILayerType" label="Type" data={apiLayerOptions} />
                            </div>
                        }
                    </>
                }
                {selectedAsset?.assetType === "sasScript" &&
                    <>
                        <div className="my-4">
                            <div className="mt-4 mb-2">Is the SAS being exclusively used for ETL purpose?</div>
                            <ControlledRadioGroup className="flex flex-col gap-2" name="isUsedForETL" options={yesNoOptions} value={getValues().isUsedForETL || 'no'}/>
                        </div>
                        {getValues().isUsedForETL === "no" &&
                            <div className="my-4">
                                <div className="mt-4 mb-2">If no, Please provide a reason</div>
                                <ControlledInput name="reasonForNotUseETL" type="text" label="Label" required={getValues().isUsedForETL === "no"} error={errors.reasonForNotUseETL} />
                                {errors.reasonForNotUseETL && <p className="text-red-500 mt-2">{errors.reasonForNotUseETL.message}</p>}
                            </div>
                        }
                    </>
                }
                <div className="flex justify-end gap-2">
                    <Button kind='secondary' icon="icon-arrow-left-outline" onClick={() => setActiveIndex(1)}>Back</Button>
                    <Button kind='primary' icon="icon-check-hook-outline" disabled={!isUpdated} type="submit">Save and Next</Button>
                </div>
            </div>
        </div>
    )
}

export default TechnologyDetailsBusinessUser;