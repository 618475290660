import { useSelector } from "react-redux";
import { selectSelectedAsset } from "../../../store/project/projectSelectors";
import { Badge, Button, Input } from "@appkit4/react-components";
import { assetTypeOptions, dataAssetsWithPreviewMapping, environmentOptions, migrationStatusOptions, surveyStatusIndicators } from "../../../services/constants";
import dayjs from "dayjs";
import { useState } from "react";
import OwnershipUpdateModal from "./OwnershipUpdateModal";
import AssetBlockUnblockModal from "./AssetBlockUnblockModal";
import { updateAssetBlockUnblockStatus, updateOwnershipOfAsset } from "../../../store/project/projectThunks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";

const AssetDetailsSection = () => {
    const selectedAsset = useSelector(selectSelectedAsset);
    const dispatch = useDispatch<AppDispatch>();
    const [isOwnershipModalOpen, setIsOwnershipModalOpen] = useState(false);
    const [isBlockAssetModalOpen, setIsBlockAssetModalOpen] = useState(false);
    const assetDetails = {
        "Asset type": assetTypeOptions?.filter(option => option.value === selectedAsset?.assetType)[0]?.label,
        "Asset description": selectedAsset?.assetDescription,
        "Created by": selectedAsset?.createdBy,
        "Created date": selectedAsset?.createdAt ? dayjs(new Date(selectedAsset.createdAt)).format("MM/DD/YYYY") : "",
        "Updated by": selectedAsset?.updatedBy,
        "Updated date": selectedAsset?.updatedAt ? dayjs(new Date(selectedAsset?.updatedAt)).format("MM/DD/YYYY") : "",
        "Environment": environmentOptions?.filter(option => option.value === selectedAsset?.environment)[0]?.label
    }
    const closeAssetBlockModal = () => {
        setIsBlockAssetModalOpen(false);
    }
    const blockUnblockAsset = (blockStatus: boolean) => {
        dispatch(updateAssetBlockUnblockStatus({assetId: selectedAsset?.assetBusinessId, assetBlocked: blockStatus}))
        setIsBlockAssetModalOpen(false)
    }
    const closeOwnershipModal = () => {
        setIsOwnershipModalOpen(false);
    }
    const editOwnership = (userEmail: string) => {
        if(userEmail && userEmail !== "" )
            dispatch(updateOwnershipOfAsset({assetId: selectedAsset?.assetBusinessId, assetTechId: selectedAsset?.assetTechnicalId, assetOwner: userEmail}))
        setIsOwnershipModalOpen(false)
    }
    const icon = dataAssetsWithPreviewMapping?.filter((dataAsset) => dataAsset.name === selectedAsset?.assetType)?.[0]?.prefixIcon;
    const selectedStatus = surveyStatusIndicators.filter(statusDetail => statusDetail.status === selectedAsset.surveyStatus)[0]
    return (
    <div>
        <div className="flex flex-row mt-4">
            <span className={`Appkit4-icon icon-${icon}-fill ap-font-16 pt-1 text-[#415385]`} aria-hidden="true"></span>
            <h1 className="font-sans font-medium text-xl text-[#415385]">{selectedAsset?.assetName}</h1>
        </div>
        <div className="my-4 bg-white">
            <div className="flex justify-between">
                <div className="flex flex-row gap-2 p-6 items-center">
                    <div className="font-sans font-medium text-xl">Asset survey</div>
                    <Badge type={selectedStatus?.statusIndicator} value={selectedStatus?.label} />
                    {(selectedAsset?.surveyStatus === "requested" || selectedAsset?.surveyStatus === "onGoing") &&
                        <>
                            <span className="Appkit4-icon icon-calendar-clock-outline ap-font-16 pt-1" aria-hidden="true"></span>
                            <span>{`Survey deadline by ${dayjs(new Date(selectedAsset?.surveyDeadline || "")).format("DD/MM/YYYY")}`}</span>
                        </>
                    }
                </div>
            </div>
        </div>
        <div className="my-4 flex gap-4 justify-bewtween">
            <div className="bg-white flex justify-between w-full p-6">
                <div className="font-sans font-medium text-xl">Migration status</div>
                <Badge value={selectedAsset?.assetBlocked ? "Blocked" : migrationStatusOptions.filter((status) => status.value === selectedAsset?.migrationStatus)?.[0]?.label} type={"info"} />
            </div>
            <div className="bg-white flex justify-between w-full p-6">
                <div className="font-sans font-medium text-xl"><Badge className="mr-2" value={0} type={'primary'}/>Use cases</div>
                {/* <Button kind='text' icon="icon-arrow-right-small-outline" onClick={() => console.log("see details")}>See details</Button> */}
            </div>
        </div>
        <div className="px-6 pb-6 bg-white my-4">
            <div className="font-sans font-medium text-xl py-4">Details</div>
            {Object.entries(assetDetails).map(([key, value]) => (
                <div className="flex justify-between bg-[#F3F3F3] border p-2" key={key}>
                    <div>{key}</div>
                    <div>{value}</div>
                </div>
            ))}
        </div>
        <div className="px-6 pb-6 bg-white my-4">
            <div className="font-sans font-medium text-xl pt-4">Ownership of the asset</div>
            <div className="font-small text-[#696969] my-4">
                See here the current owner of the asset. You can change the ownership to another user if necessary. Please review the changes thoroughly.
            </div>
            <div className="flex justify-between gap-2">
                <Input readonly className="w-full" title="email" value={selectedAsset?.owner} />
                <Button kind="text" icon="icon-edit-outline" onClick={() => setIsOwnershipModalOpen(true)}></Button>
            </div>
            <OwnershipUpdateModal visible={isOwnershipModalOpen}  closeModal= {closeOwnershipModal} editOwnership = {editOwnership} ownerEmail={selectedAsset?.owner}/>
        </div>
        <div className="px-6 pb-6 bg-white my-4">
            <div className="font-sans font-medium text-xl pt-4">Asset Status Management</div>
            <div className="flex justify-between gap-2">
                <div className="font-small text-[#696969] my-4">
                    Here you can change the migration status of an asset. You have the flexibility to update the status at any time, ensuring that you can adapt to changes and maintain accurate project tracking as needed.
                 </div>
                <div>
                    <Button kind={selectedAsset?.assetBlocked ? "primary" : "negative"} icon={selectedAsset?.assetBlocked && "icon-refresh-outline"} onClick={() => setIsBlockAssetModalOpen(true)}>{selectedAsset?.assetBlocked ? "Unblock asset" : "Block asset"}</Button>
                </div>
                <AssetBlockUnblockModal visible={isBlockAssetModalOpen}  closeModal= {closeAssetBlockModal} blockUnblockAsset = {blockUnblockAsset} isAssetBlockedValue={selectedAsset?.assetBlocked}/>
            </div>
        </div>
    </div>)
}

export default AssetDetailsSection;