import { Button, Modal } from "@appkit4/react-components";
import { ControlledTextArea } from "../../../core/components";
import { useFormContext } from "react-hook-form";
import RequestFormProvider from "../dataAssets/RequestFormProvider";

interface DeleteUseCaseConfModalProps {
    visible: boolean;
    closeModal: Function;
    removeUseCase: any;
}

type RemoveOwnershipFormErrors = {
    removeUseCaseOwnershipComment: string;
};

const RemovalUseCaseConfirmationModal: React.FC<DeleteUseCaseConfModalProps> = ({ visible, closeModal, removeUseCase }) => {
    return (
        <Modal
            visible={visible}
            title={"Remove use case"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <RequestFormProvider onSubmit={removeUseCase}>
                <RemoveOwnershipForm />
            </RequestFormProvider>
        </Modal>);
}

const RemoveOwnershipForm = () => {
    const { formState: { errors } } = useFormContext<RemoveOwnershipFormErrors>();
    return (
        <div>
            <div className="font-small text-sm text-[#696969]">If you have a reason for this use case to be removed, please write it in the comments and inform the manager.</div>
            <div className="my-4">
                <ControlledTextArea name="removeUsecaseOwnershipCmmnt" required={true} label="Comment" error={errors.removeUseCaseOwnershipComment} maxLength={420} />
                {errors.removeUseCaseOwnershipComment && <p className="!text-red-500 mt-2">{errors.removeUseCaseOwnershipComment.message}</p>}
            </div>
            <div className="my-4 flex justify-end">
                <Button kind="primary" icon="icon-delete-outline" type="submit">Request removal</Button>
            </div>
        </div>
    )
}

export default RemovalUseCaseConfirmationModal;