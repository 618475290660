import { useSelector } from 'react-redux';
import {
  selectProjectError,
  selectSelectedProjectData,
  selectSelectedUseCaseDetails,
} from '../../../store/project/projectSelectors';
import { Breadcrumb, BreadcrumbItem, Button } from '@appkit4/react-components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UseCaseForm from '../../../components/project/useCases/UseCaseForm';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { editUseCaseData, getDetailsOfUseCase, getUseCaseList } from '../../../store/project/projectThunks';
import UseCasesTableData from '../../../components/project/useCases/UseCasesTableData';
import { UseCaseDTO } from '../../../api';

const UseCaseContainerBusinessUser: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [current, setCurrent] = useState(1);
  const [selectedValue, setSelectedValue] = useState("10");
  const selectedProject = useSelector(selectSelectedProjectData);
  const selectedUseCase = useSelector(selectSelectedUseCaseDetails);
  const [isAddUseCaseOpen, setIsAddUseCaseOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [useCaseType, setUseCasetype] = useState("");
  const navigate = useNavigate();
  const error = useSelector(selectProjectError);
  const addUseCaseClicked = (type: string) => {
    setUseCasetype(type);
    setIsAddUseCaseOpen(true);
  }

  const editUseCaseDetails = (payload: any) => {
    dispatch(editUseCaseData(payload));
  }

  useEffect(() => {
    if (!isAddUseCaseOpen && !isDetailsOpen)
      dispatch(getUseCaseList({ pageNumber: current, pageSize: selectedValue, searchString: "", projectBusinessId: selectedProject?.projectBusinessId }));
  }, [dispatch, current, selectedValue, isAddUseCaseOpen, isDetailsOpen, selectedProject]);

  const viewDetails = (row: UseCaseDTO) => {
    dispatch(getDetailsOfUseCase(row.useCaseBusinessId))
    setIsDetailsOpen(true);
  }

  return (
    <div className="m-8">
      <Breadcrumb>
        <BreadcrumbItem>
          <Button className="px-0"
            kind="text"
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            Your projects
          </Button>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Button
            className="px-0"
            kind="text"
            onClick={() => {
              navigate(`/project/${selectedProject?.projectBusinessId}`);
            }}
          >
            {selectedProject?.name}
          </Button>
        </BreadcrumbItem>
        {isDetailsOpen ? 
          <>
            <BreadcrumbItem>
              <Button
                className="px-0"
                kind="text"
                onClick={() => {
                  setIsAddUseCaseOpen(false);
                  setUseCasetype("");
                  setIsDetailsOpen(false);
                }}
              >
                Use case management
              </Button>
            </BreadcrumbItem>
            <BreadcrumbItem>{selectedUseCase?.name}</BreadcrumbItem>
          </>
          :
          isAddUseCaseOpen ?
            <>
              <BreadcrumbItem>
                <Button
                  className="px-0"
                  kind="text"
                  onClick={() => {
                    setIsAddUseCaseOpen(false);
                    setUseCasetype("");
                    setIsDetailsOpen(false);
                  }}
                >
                  Use case management
                </Button>
              </BreadcrumbItem>
              <BreadcrumbItem>Add use case</BreadcrumbItem>
            </>
            :
            <BreadcrumbItem>Use case management</BreadcrumbItem>
        }
      </Breadcrumb>

      {isAddUseCaseOpen && useCaseType === "manual" ?
        <UseCaseForm setIsAddUseCaseOpen={setIsAddUseCaseOpen} />
        :
        <div className="my-2">
          <UseCasesTableData current={current} setCurrent={setCurrent} selectedValue={selectedValue} setSelectedValue={setSelectedValue} viewDetails={viewDetails} isDetailsOpen={isDetailsOpen}
            addUseCaseClicked={addUseCaseClicked} editUseCaseDetails={editUseCaseDetails}/>
        </div>
      }
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>

  );
};

export default UseCaseContainerBusinessUser;
