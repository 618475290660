import React from 'react';
import { Steppers, Stepper } from '@appkit4/react-components/stepper';
import ProjectDetails from './ProjectDetails';
import ReviewProjectData from '../ReviewProjectData';
import { CreateProjectRequest } from '../../../api';
import SuccessProjectCreate from '../SuccessProjectCreate';
import ProjectConfiguration from '../lineageConfig/ProjectConfiguration';

interface ProjectFormProps {
  onSubmit: (project: CreateProjectRequest) => void;
  loading: boolean;
  isProjectCreated: boolean;
  activeIndex: number;
  setActiveIndex: Function;
}

const NewProjectForm: React.FC<ProjectFormProps> = ({ onSubmit, loading, isProjectCreated, activeIndex, setActiveIndex }) => {
  const onStepperChange = (i: number) => {
    setActiveIndex(i);
  }
  return (
    <Steppers space={1500} className="flex justify-between" activeIndex={activeIndex} onActiveIndexChange={onStepperChange}>
      <Stepper status='normal' label="Project details">
        <ProjectDetails setActiveIndex={setActiveIndex} />
      </Stepper>
      <Stepper status='normal' label="Project configuration">
        <ProjectConfiguration setActiveIndex={setActiveIndex} />
      </Stepper>
      <Stepper status='normal' label="Review">
        {!isProjectCreated ?
          <ReviewProjectData onSubmit={onSubmit} loading={loading} setActiveIndex={setActiveIndex} />
          :
          <SuccessProjectCreate />
        }
      </Stepper>
    </Steppers> 
  );
};
export default NewProjectForm;