import { yesNoOptions } from "../../../services/constants";
import { useEffect } from "react";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { getUseCaseList } from "../../../store/project/projectThunks";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Input, Radio, RadioGroup } from "@appkit4/react-components";
import dayjs from "dayjs";
import { selectSurveyDetails, selectUseCaseListData } from "../../../store/project/projectSelectors";

const BusinessDetails: React.FC<{setActiveIndex: Function}> = ({setActiveIndex}) => {
    const dispatch = useDispatch<AppDispatch>();
    const surveyDetailsData = useSelector(selectSurveyDetails);
    const { projectId } = useParams();
    const useCasesList = useSelector(selectUseCaseListData);
    const surveyData = JSON.parse(JSON.stringify(surveyDetailsData?.surveyDetailsDTO?.surveyData));
    let useCasesAttached: Array<string> = []
    useCasesList?.data?.forEach((useCase) => {
        surveyData?.businessDetails?.useCases.forEach((useCaseId: string) => {
            if(useCase.useCaseBusinessId === useCaseId) {
                useCasesAttached.push(useCase.name || "")
            }
        })
    })
    useEffect(() => {
        if(projectId){
            dispatch(getUseCaseList({ pageNumber: 1, pageSize: "100", searchString: "", projectBusinessId: projectId }));
        }  
    },[dispatch, projectId]);
    return(
        <div>
            <div className="flex justify-between">
                <h1 className="font-sans font-bold font-medium text-2xl py-4">Business details</h1>
                {surveyDetailsData?.surveyDetailsDTO?.updatedAt && surveyDetailsData?.surveyDetailsDTO?.updatedAt !== "" &&
                    <div className="w-fit mr-4 mt-4">
                        <span className={`Appkit4-icon icon-update-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                        <span>Last saved at {dayjs(new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "")).format("MM/DD/YYYY")}, </span>
                        <span>{Math.floor((new Date().getTime() - new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "").getTime()) / (1000 * 60 * 60 * 24))} days ago</span>
                    </div>
                }
            </div>
            <div className="p-8 bg-white">
                <div className="my-4">
                    <div className="mt-4 mb-2">Department name</div>
                    <Input title="Department name" readonly value={surveyData?.businessDetails?.departmentsName} />
                </div>
                <div className="my-4">
                    <div className="mt-4 mb-2">Primary user group</div>
                    <Input title="Primary user group" readonly value={surveyData?.businessDetails?.primaryUserGroups} />
                </div>
                <div className="my-4">
                    <div className="mt-4 mb-2">Is the asset currently tied in another project</div>
                    <RadioGroup value={surveyData?.businessDetails?.tiedInAnotherProject? "yes" : 'no'}>
                        {yesNoOptions.map((option) =>
                            <Radio value={option.value} key={option.value}>{option.label}</Radio>
                        )}
                    </RadioGroup>
                </div>
                {surveyData?.businessDetails?.tiedInAnotherProject &&
                    <div className="my-4">
                        <div className="mt-4 mb-2">If yes, please specify the project</div>
                        <Input title="Project name" readonly value={surveyData?.businessDetails?.tiedprojectName} />
                    </div>
                }   
                <div className="my-4">
                    <div className="mt-4 mb-2">Select the use cases</div>
                    <Input title="Use cases" readonly value={useCasesAttached}/>
                </div>
                <div className="flex justify-end gap-2">
                    <Button kind='secondary' icon="icon-arrow-left-outline" onClick={() => setActiveIndex(0)}>Back</Button>
                    <Button kind='primary' icon="icon-arrow-right-outline" onClick={() => setActiveIndex(2)}>Next</Button>
                </div>
            </div>
        </div>
    )
}

export default BusinessDetails;