import { Button, CalendarPicker, Modal } from "@appkit4/react-components";
import { useState } from "react";

interface AddDeadlineModalProps {
    visible: boolean;
    closeModal: Function;
    addDeadline: any;
}

const AddDeadlineModal: React.FC<AddDeadlineModalProps> = ({ visible, closeModal, addDeadline }) => {
    return (
        <Modal
            visible={visible}
            title={"Define deadline"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <AddDeadlineForm closeModal={closeModal} setDeadline={addDeadline}/>
        </Modal>);
}

const AddDeadlineForm: React.FC<{closeModal: Function, setDeadline: Function}> = ({closeModal, setDeadline}) => {
    const [date, setDate] = useState();
    return (
        <div>
            <div className="font-small text-sm text-[#696969]">Please select a deadline for the asset surveys you have selected. This ensures that the survey is completed on time and the necessary information is gathered efficiently.</div>
            <div className="my-4">
                <CalendarPicker name="deadLineDate" required={true} 
                fieldWidth="full"
                placeholder="mm/dd/yyyy"
                locale="en"
                    fieldTitle={"Deadline"}
                onValueChange={setDate}/>
            </div>
            <div className="my-4 flex justify-end gap-2">
                <Button kind="secondary" onClick={closeModal}>Back</Button>
                <Button kind="primary" disabled={!date} onClick={() => setDeadline(date)}>Save changes</Button>
            </div>
        </div>
    )
}

export default AddDeadlineModal;