import ActionOnAssetRequest from "./ActionOnAssetRequest";
import AddDataAssetScreenComp from "./AddDataAssetScreenComp";
import EditDataAssetScreenComp from "./EditDataAssetScreenComp";

const AssetRequestReviewManager: React.FC<{ reviewType: string, selectedAssetReqeust: any, actionOnRequest: Function }> = ({ reviewType, selectedAssetReqeust, actionOnRequest }) => {
    return (
        <>
            {reviewType === "requestEditAsset" &&
                <EditDataAssetScreenComp selectedAssetReqeust={selectedAssetReqeust} actionOnRequest={actionOnRequest} />}
            {reviewType === "requestAddAsset" &&
                <AddDataAssetScreenComp selectedAssetReqeust={selectedAssetReqeust} actionOnRequest={actionOnRequest} />}
            {(reviewType === "ownershipOfAsset" || reviewType === "removeOwnership") &&
                <ActionOnAssetRequest selectedAssetReqeust={selectedAssetReqeust} actionOnRequest={actionOnRequest} reviewType={reviewType} />}
        </>
    )
}

export default AssetRequestReviewManager;