import dayjs from "dayjs";
import { selectSurveyDetails } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import { assetCriticalityOptions, frequencyOfUseOptions, radioOptions, targetAudienceOptions } from "../../../services/constants";
import { Button, Input, Radio, RadioGroup } from "@appkit4/react-components";

const SupplementaryDetails: React.FC<{ setActiveIndex: Function, setIsReviewClicked: Function }> = ({setActiveIndex, setIsReviewClicked}) => {
    const surveyDetailsData = useSelector(selectSurveyDetails);  
    return (
        <div>
            <div className="flex justify-between">
                <h1 className="font-sans font-bold font-medium text-2xl py-4">Supplementary details</h1>
                {surveyDetailsData?.surveyDetailsDTO?.updatedAt && surveyDetailsData?.surveyDetailsDTO?.updatedAt !== "" &&
                    <div className="w-fit mr-4 mt-4">
                        <span className={`Appkit4-icon icon-update-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                        <span>Last saved at {dayjs(new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "")).format("MM/DD/YYYY")}, </span>
                        <span>{Math.floor((new Date().getTime() - new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "").getTime()) / (1000 * 60 * 60 * 24))} days ago</span>
                    </div>
                }
            </div>
            <div className="p-8 bg-white">
                <div className="my-4 flex flex-row gap-2">
                    <div className="w-1/3 text-base text-[#696969]">
                        Development environment migrated
                    </div>
                    <div className="w-1/3">
                        <RadioGroup className="flex gap-16" value={surveyDetailsData?.supplementaryDetails?.devEnvCompleted}>
                            {radioOptions.map((option) =>
                                <Radio value={option.value} key={option.value}>{option.label}</Radio>
                            )}
                        </RadioGroup>
                    </div>
                    <div className="w-1/3">
                        <Input title="Date" readonly value={surveyDetailsData.supplementaryDetails?.devEnvCompletedDate ? dayjs(new Date(surveyDetailsData?.supplementaryDetails?.devEnvCompletedDate || "")).format("MM/DD/YYYY") : ""}/>
                    </div>
                </div>
                <div className="my-4 flex flex-row gap-2">
                    <div className="w-1/3 text-base text-[#696969]">
                        Data sources migrated
                    </div>
                    <div className="w-1/3">
                        <RadioGroup className="flex gap-16" value={surveyDetailsData?.supplementaryDetails?.dataSourcesMigrated}>
                            {radioOptions.map((option) =>
                                <Radio value={option.value} key={option.value}>{option.label}</Radio>
                            )}
                        </RadioGroup>
                    </div>
                    <div className="w-1/3">
                        <Input title="Date" readonly value={surveyDetailsData.supplementaryDetails?.dataSourcesMigratedDate ? dayjs(new Date(surveyDetailsData?.supplementaryDetails?.dataSourcesMigratedDate || "")).format("MM/DD/YYYY") : ""}/>
                    </div>
                </div>
                <div className="my-4 flex flex-row gap-2">
                    <div className="w-1/3 text-base text-[#696969]">
                        UAT environment migrated
                    </div>
                    <div className="w-1/3">
                        <RadioGroup className="flex gap-16" value={surveyDetailsData?.supplementaryDetails?.uatEnvCompleted}>
                            {radioOptions.map((option) =>
                                <Radio value={option.value} key={option.value}>{option.label}</Radio>
                            )}
                        </RadioGroup>
                    </div>
                    <div className="w-1/3">
                        <Input title="Date" readonly value={surveyDetailsData.supplementaryDetails?.uatEnvCompletedDate ? dayjs(new Date(surveyDetailsData?.supplementaryDetails?.uatEnvCompletedDate || "")).format("MM/DD/YYYY") : ""}/>
                    </div>
                </div>
                <div className="my-4 flex flex-row gap-2">
                    <div className="w-1/3 text-base text-[#696969]">
                        Pre-production environment migrated
                    </div>
                    <div className="w-1/3">
                        <RadioGroup className="flex gap-16" value={surveyDetailsData?.supplementaryDetails?.prePodEnvCompleted}>
                            {radioOptions.map((option) =>
                                <Radio value={option.value} key={option.value}>{option.label}</Radio>
                            )}
                        </RadioGroup>
                    </div>
                    <div className="w-1/3">
                        <Input title="Date" readonly value={surveyDetailsData.supplementaryDetails?.prePodEnvCompletedDate ? dayjs(new Date(surveyDetailsData?.supplementaryDetails?.prePodEnvCompletedDate || "")).format("MM/DD/YYYY") : ""}/>
                    </div>
                </div>
                <div className="my-4 flex flex-row gap-2">
                    <div className="w-1/3 text-base text-[#696969]">
                        Production environment migrated
                    </div>
                    <div className="w-1/3">
                        <RadioGroup className="flex gap-16" value={surveyDetailsData?.supplementaryDetails?.prodEnvCompleted}>
                            {radioOptions.map((option) =>
                                <Radio value={option.value} key={option.value}>{option.label}</Radio>
                            )}
                        </RadioGroup>
                    </div>
                    <div className="w-1/3">
                        <Input title="Date" readonly value={surveyDetailsData.supplementaryDetails?.prodEnvCompletedDate ? dayjs(new Date(surveyDetailsData?.supplementaryDetails?.prodEnvCompletedDate || "")).format("MM/DD/YYYY") : ""}/>
                    </div>
                </div>
                <div className="my-4">
                    <Input title="Criticality" readonly value={assetCriticalityOptions.filter(option => option.value === surveyDetailsData?.supplementaryDetails?.assetCriticality)?.[0]?.label} />
                </div>
                <div className="my-4">
                    <Input title="Complexity" readonly value={assetCriticalityOptions.filter(option => option.value === surveyDetailsData?.supplementaryDetails?.assetComplexity)?.[0]?.label} />
                </div>
                <div className="my-4">
                    <Input title="Frequency" readonly value={frequencyOfUseOptions.filter(option => option.value === surveyDetailsData?.supplementaryDetails?.frequencyOfUse)?.[0]?.label} />
                </div>
                <div className="my-4">
                    <Input title="Target audience" readonly value={targetAudienceOptions.filter(option => option.value === surveyDetailsData?.supplementaryDetails?.targetAudience)?.[0]?.label} />
                </div>
                <div className="flex justify-end gap-2">
                    <Button kind='secondary' onClick={() => setIsReviewClicked(false)}>Cancel</Button>
                    <Button kind='primary' icon="icon-arrow-right-outline" onClick={() => setActiveIndex(1)} >Next</Button>
                </div>
            </div>
        </div>

    )
}

export default SupplementaryDetails;