import { Stepper, Steppers } from "@appkit4/react-components";
import { useState } from "react";
import SelectAssetItems from "./SelectAssetItems";
import AssignDetailsForAssets from "./AssignDetailsForAssets";

const RequestForMultipleSurveyDeadline: React.FC<{setIsRequestInfoClicked: Function, defineSurveysDeadline: Function}> = ({setIsRequestInfoClicked, defineSurveysDeadline}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedSurveys, setSelectedSurveys] = useState<Array<string>>([]);
    const onStepperChange = (i: number) => {
        setActiveIndex(i);
    }
    return (
        <Steppers space={1500} className="flex justify-between" activeIndex={activeIndex} onActiveIndexChange={onStepperChange}>
            <Stepper status='normal' label="Select items">
                <SelectAssetItems setActiveIndex={setActiveIndex} setIsRequestInfoClicked={setIsRequestInfoClicked} selectedSurveys={selectedSurveys}  setSelectedSurveys={setSelectedSurveys}/>
            </Stepper>
            <Stepper status='normal' label="Assign details">
                <AssignDetailsForAssets setActiveIndex={setActiveIndex} selectedSurveys={selectedSurveys} defineSurveysDeadline={defineSurveysDeadline}/>
            </Stepper>
        </Steppers>
    )
}

export default RequestForMultipleSurveyDeadline;