import React, { useState } from 'react';
import { Steppers, Stepper } from '@appkit4/react-components/stepper';
import UseCaseDetails from './UseCaseDetails';
import ReviewUseCase from './ReviewUseCase';

const UseCaseForm: React.FC<{setIsAddUseCaseOpen: Function}> = ({setIsAddUseCaseOpen}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const onStepperChange = (i: number) => {
        setActiveIndex(i);
    };
    return (
        <Steppers space={1500} className="flex justify-between mt-4" activeIndex={activeIndex} onActiveIndexChange={onStepperChange}>
            <Stepper status="normal" label="Insert details and assets">
                <UseCaseDetails setActiveIndex={setActiveIndex} setIsAddUseCaseOpen={setIsAddUseCaseOpen}/>
            </Stepper>
            <Stepper status="normal" label="Review">
                <ReviewUseCase setActiveIndex={setActiveIndex} setIsAddUseCaseOpen={setIsAddUseCaseOpen}/>
            </Stepper>
        </Steppers>
    );
};
export default UseCaseForm;
