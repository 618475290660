import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

type MigrationPlanningFormValues = {
  downTimeWindow: string
};

type MigrationPlanningFormProviderProps = {
  onSubmit: SubmitHandler<MigrationPlanningFormValues>;
  children: React.ReactNode;
};

const MigrationPlanningFormProvider: React.FC<MigrationPlanningFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<MigrationPlanningFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default MigrationPlanningFormProvider;