import React from 'react';
import { CreateNewProjectContainer } from '../../containers/project';
import { LoggedInFooter, LoggedInHeader } from '../../core/components';

const CreateNewProjectPage: React.FC = () => {
    return (
        <div className="flex flex-col h-screen bg-[#F3F3F3]">
            <LoggedInHeader />
            <div className='flex-1 bg-[#F3F3F3]'>
                <CreateNewProjectContainer />
            </div>
            <LoggedInFooter />
        </div>
    );
};

export default CreateNewProjectPage;