import { Breadcrumb, BreadcrumbItem, Button, Loading, Panel } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import ArchiveProjectConfModal from "../../../components/project/projectDetails/ArchiveProjectConfModal";
import EditProjectDetailsModal from "../../../components/project/projectDetails/EditProjectDetailsModal";
import EditProjectConfigModal from "../../../components/project/projectDetails/EditProjectConfigModal";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { archiveProject, getProjectConfigData, getProjectsList } from "../../../store/project/projectThunks";
import { selectProjectConfigData, selectProjectError, selectProjectLoading, selectSelectedProjectData } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProjectConfigContainer = () => {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const selectedProject = useSelector(selectSelectedProjectData);
    const projectConfigData = useSelector(selectProjectConfigData);
    const [isEditModalVisible, setIsVisibleEditModal] = useState(false);
    const [isConfigModalVisible, setIsConfigModalVisible] = useState(false);
    useEffect(() => {
        dispatch(getProjectConfigData(selectedProject?.projectBusinessId))
    },[dispatch, selectedProject?.projectBusinessId]);
    const closeConfigModal = () => {
        setIsConfigModalVisible(false);
    }
    const closeEditModal = () => {
        setIsVisibleEditModal(false);
    }
    const closeModal = () => {
        setVisible(false);
    }
    const archiveProjectMethod = () => {
        dispatch(archiveProject({projectTechnicalId: selectedProject?.projectTechnicalId, projectBusinessId: selectedProject?.projectBusinessId, status: "archived"}));
        setVisible(false);
    }
    const loading = useSelector(selectProjectLoading);
    const error = useSelector(selectProjectError);
    if(loading){
        return(
            <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
        )
    }
    return(
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        dispatch(getProjectsList()); 
                        navigate("/dashboard")
                    }}>All Projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => navigate(`/project/${selectedProject?.projectBusinessId}`)}>{selectedProject?.name}</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    Project Configuration
                </BreadcrumbItem>
            </Breadcrumb>
            <div>
                <h1 className="font-sans font-medium text-xl mt-4">Project Configuration</h1>
            </div>
            <Panel title={"Edit project details"} className="mt-4">
                <p>Here you can edit the project, change the title, date and description.</p>
                <div className="flex justify-end">
                    <Button kind='secondary' icon="icon-edit-outline" onClick={() => setIsVisibleEditModal(true)}>Edit</Button>
                </div>
                <EditProjectDetailsModal visible={isEditModalVisible} closeModal={closeEditModal} selectedProject={selectedProject} />
            </Panel>
            <Panel title={"Configurations"} className="mt-4">
                <div className="mt-2">
                    <p>GCS Bucket</p>
                    <p className="text-black">{selectedProject?.projectConfigDetails?.gcsBucket}</p>
                </div>
                <div className="mt-2">
                    <p  >BigQuery Dataset ID</p>
                    <p className="text-black">{selectedProject?.projectConfigDetails?.bigQueryId}</p>
                </div>
                <div className="flex justify-end">
                    <Button kind='secondary' icon="icon-edit-outline" onClick={() => setIsConfigModalVisible(true)}>Edit</Button>
                </div>
                <EditProjectConfigModal visible={isConfigModalVisible} closeModal={closeConfigModal} configData = {projectConfigData}/>
            </Panel>
            <Panel title={"Archive Project"} className="mt-4">
                <p>Archiving this project will move it to a separate section, restrict collaborators to view-only access, and remove editing privileges. This action cannot be undone. Confirm to proceed.</p>
                <div className="flex justify-end mt-4">
                    <Button kind='negative' disabled={selectedProject?.projectStatus === "archived"} icon="icon-archive-outline" onClick={() => setVisible(true)}>Archive Projet</Button>
                </div>
                <ArchiveProjectConfModal visible={visible} closeModal={closeModal} archiveProject={archiveProjectMethod} />
            </Panel>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    )
}

export default ProjectConfigContainer;