import { useSelector } from "react-redux";
import { selectSurveyDetails } from "../../../store/project/projectSelectors";
import { Badge, Button, TextArea } from "@appkit4/react-components";
import { surveyStatusIndicators } from "../../../services/constants";
import dayjs from "dayjs";
import '@appkit4/react-text-editor/dist/appkit4-react-texteditor.min.css';
import { useState } from "react";
import DataAssetSurveyReview from "../dataAssets/DataAssetSurveyReview";
import DenySurveyModal from "./DenySurveyModal";

const ReviewSurveyRequest: React.FC<{ setIsReviewClicked: Function, surveyReview: Function }> = ({ setIsReviewClicked, surveyReview }) => {
    const [isCommentSectionOpened, setIsCommentSectionOpened] = useState(false);
    const selectedSurveyDetail = useSelector(selectSurveyDetails);
    const [comment, setComment] = useState("");
    const [denyClicked, setDenyClicked] = useState(false);
    const selectedStatus = surveyStatusIndicators.filter(statusDetail => statusDetail.status === selectedSurveyDetail?.surveyDetailsDTO?.surveyStatus)[0];
    const denySurvey = () => {
        surveyReview({assetId: selectedSurveyDetail.surveyDetailsDTO.assetBusinessId, surveyState: "onGoing", comment: comment})
    }
    const approveSurvey = () => {
        surveyReview({assetId: selectedSurveyDetail.surveyDetailsDTO.assetBusinessId, surveyState: "complete", comment: comment})
    }
    const openCommentSection = () => {
        setIsCommentSectionOpened(!isCommentSectionOpened);
    }
    const btnState = isCommentSectionOpened ? "up" : "down";
    return (
        <>
            <div className="bg-white p-4">
                <div className="mb-4">
                    <div className="flex justify-between">
                        <div className="flex justify-start my-2">
                            <h1 className="font-sans font-medium text-xl mr-2">Survey review</h1>
                            <Badge type={selectedStatus?.statusIndicator} value={selectedStatus?.label} />
                        </div>
                        <div className="mr-4">
                            <span className={`Appkit4-icon icon-${btnState}-chevron-outline ap-font-16 ap-container-24 cursor-pointer`} aria-hidden="true" onClick={openCommentSection}></span>
                            <span className="text-[#415386]">Comment</span>
                        </div>
                    </div>
                    <div className="flex justify-between my-2">
                        <div>
                            <span className="text-[#696969] mr-2">Asset Owner</span>
                            <span>{selectedSurveyDetail?.surveyDetailsDTO?.owner}</span>
                        </div>
                        <div>
                            <span className="text-[#696969] mr-2">Submitted</span>
                            <span>{dayjs(new Date(selectedSurveyDetail?.surveyDetailsDTO?.updatedAt || "")).format("MM/DD/YYYY")}, </span>
                            <span>{Math.floor((new Date().getTime() - new Date(selectedSurveyDetail?.surveyDetailsDTO?.updatedAt || "").getTime()) / (1000 * 60 * 60 * 24))} days ago</span>
                        </div>
                    </div>
                    <div className="my-4">Please review the asset survey carefully to ensure all required information is accurate and complete. <b>Click the dropdown to access the comment field, write your comment, and click deny.</b> The user will be notified once you do so.</div>
                    {isCommentSectionOpened &&
                        <>
                            <div className="font-sans font-medium text-xl mb-2">Managers comment</div>
                            <TextArea required={false} value={comment} title={"Asset description"} onChange={(e) => setComment(e)} />
                        </>
                    }
                    <div className="flex justify-end gap-2 mt-4">
                        <Button kind='secondary' icon="icon-close-outline" onClick={() => setDenyClicked(true)}>Deny</Button>
                        <Button kind='primary' icon="icon-check-hook-outline" onClick={approveSurvey}>Approve</Button>
                    </div>
                    <DenySurveyModal denySurvey={denySurvey} setDenyClicked={setDenyClicked} denyClicked={denyClicked} />
                </div>
            </div>
            <DataAssetSurveyReview setIsReviewClicked={setIsReviewClicked} />
        </>
    )
}

export default ReviewSurveyRequest;