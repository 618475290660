import { Button, Modal } from "@appkit4/react-components";
import { ControlledTextArea } from "../../../core/components";
import { useFormContext } from "react-hook-form";
import RequestFormProvider from "../dataAssets/RequestFormProvider";

interface OwnershipUseCaseConfModalProps {
    visible: boolean;
    closeModal: Function;
    reqOwnershipOfUseCase: any;
}

type RemoveOwnershipFormErrors = {
    ownerReqUsecaseComment: string;
};

const RequestOwnershipUseCaseConfirmationModal: React.FC<OwnershipUseCaseConfModalProps> = ({ visible, closeModal, reqOwnershipOfUseCase }) => {
    return (
        <Modal
            visible={visible}
            title={"Use case ownership"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <RequestFormProvider onSubmit={reqOwnershipOfUseCase}>
                <RequestOwnershipForm />
            </RequestFormProvider>
        </Modal>);
}

const RequestOwnershipForm = () => {
    const { formState: { errors } } = useFormContext<RemoveOwnershipFormErrors>();
    return (
        <div>
            <div className="font-small text-sm text-[#696969]">If this use case belongs to you, please click 'Request Ownership' and provide a justification in the comment area.</div>
            <div className="my-4">
                <ControlledTextArea name="requestOwnerUsecaseCmmnt" required={true} label="Comment" error={errors.ownerReqUsecaseComment} maxLength={420} />
                {errors.ownerReqUsecaseComment && <p className="!text-red-500 mt-2">{errors.ownerReqUsecaseComment.message}</p>}
            </div>
            <div className="my-4 flex justify-end">
                <Button kind="primary" icon="icon-lockopen-unlock-outline" type="submit">Request ownership</Button>
            </div>
        </div>
    )
}

export default RequestOwnershipUseCaseConfirmationModal;