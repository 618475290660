import { Button, Modal } from "@appkit4/react-components";
import { useFormContext } from "react-hook-form";
import RequestFormProvider from "./RequestFormProvider";
import { ControlledTextArea } from "../../../core/components";
import { AssetDTO } from "../../../api";
import { KeycloakService } from "../../../services/KeycloakService";
import { reqToRemoveAssetOwnership, requestForAssetOwnership } from "../../../store/project/projectThunks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { setIsEditModeAsset } from "../../../store/project/projectSlice";
import { selectRequestDetailsForAssetData, selectSelectedAsset } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import { useEffect } from "react";

interface ManageAssetModalProps {
    visible: boolean;
    closeModal: Function;
    viewDetailsClicked: Function;
}

type RequestOwnerFormErrors = {
    ownerReqComment: string;
};

type RequestRmovalFormErrors = {
    ownerRemovalComment: string;
};

const ManageDataAssetModal: React.FC<ManageAssetModalProps> = ({ visible, closeModal, viewDetailsClicked }) => {
    const dispatch = useDispatch<AppDispatch>();
    const selectedAsset = useSelector(selectSelectedAsset);
    const selectedAssetRequestData = useSelector(selectRequestDetailsForAssetData);
    const requestOwnership = (data: any) => {
        dispatch(requestForAssetOwnership({ assetBusinessId: selectedAsset?.assetBusinessId, assetTechnicalId: selectedAsset?.assetTechnicalId, requestDescription: data?.reqOwnerComment }))
        closeModal();
    };
    const reqOwnerRemoval = (data: any) => {
        dispatch(reqToRemoveAssetOwnership({ assetBusinessId: selectedAsset?.assetBusinessId, assetTechnicalId: selectedAsset?.assetTechnicalId, requestDescription: data?.reqOwnerRemovalComment }))
        closeModal();
    };
    const requestOwnershipDisabled = selectedAssetRequestData?.requestStatus === "pending" && selectedAssetRequestData?.requestType === "ownershipOfAsset";
    const removeOwnershipDisabled = selectedAssetRequestData?.requestStatus === "pending" && selectedAssetRequestData?.requestType === "removeOwnership";
    const editAssetDisabled = selectedAssetRequestData?.requestStatus === "pending" && selectedAssetRequestData?.requestType === "requestEditAsset";
    return (
        <Modal
            visible={visible}
            header={<h1 className="font-sans font-medium text-xl mb-4">Asset actions</h1>}
            onCancel={closeModal}
            modalStyle={{ width: '36rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <RequestFormProvider onSubmit={requestOwnership}>
                <ReqOwnerModal selectedAsset={selectedAsset} requestOwnershipDisabled={requestOwnershipDisabled} visible={visible}/>
            </RequestFormProvider>
            <RequestFormProvider onSubmit={reqOwnerRemoval}>
                <ReqRemovalModal selectedAsset={selectedAsset} removeOwnershipDisabled={removeOwnershipDisabled} visible={visible}/>
            </RequestFormProvider>
            <div>
                <h1 className="font-sans font-medium text-xl mb-4">Edit asset</h1>
                <div className="font-small text-sm text-[#696969]">Click here if you would like to edit this asset.</div>
                <div className="my-4">
                    <Button kind="primary" disabled={editAssetDisabled || selectedAsset.owner !== KeycloakService.getEmail()} onClick={() => {
                        closeModal();
                        viewDetailsClicked(selectedAsset?.assetBusinessId)
                        dispatch(setIsEditModeAsset(true))
                    }
                    }>Edit asset</Button>
                </div>
            </div>
            <div className="flex justify-end gap-2">
                <Button kind='secondary' onClick={closeModal}>Cancel</Button>
            </div>
        </Modal>);
}

const ReqOwnerModal: React.FC<{ selectedAsset: AssetDTO, requestOwnershipDisabled: boolean, visible: boolean }> = ({ selectedAsset, requestOwnershipDisabled, visible }) => {
    const { formState: { errors }, reset } = useFormContext<RequestOwnerFormErrors>();
    useEffect(() => {
        reset()
    },[visible, reset]);
    return (
        <div>
            <h1 className="font-sans font-medium text-xl mb-4">Ownership of the asset</h1>
            <div className="font-small text-sm text-[#696969]">If this asset belongs to you, please click 'Request Ownership' and provide a justification in the comment area.</div>
            <div className="my-4">
                <ControlledTextArea name="reqOwnerComment" required={true} label="Comment" error={errors.ownerReqComment} maxLength={420} readonly={requestOwnershipDisabled || selectedAsset.owner === KeycloakService.getEmail() || selectedAsset?.assetStatus === "pendingForReview"}/>
                {errors.ownerReqComment && <p className="!text-red-500 mt-2">{errors.ownerReqComment.message}</p>}
            </div>
            <div className="my-4">
                <Button kind="primary" icon="icon-lockopen-unlock-outline" type="submit" disabled={requestOwnershipDisabled || selectedAsset.owner === KeycloakService.getEmail() || selectedAsset?.assetStatus === "pendingForReview"}>Request ownership</Button>
            </div>
        </div>
    )
}

const ReqRemovalModal: React.FC<{ selectedAsset: AssetDTO, removeOwnershipDisabled: boolean, visible: boolean }> = ({ selectedAsset, removeOwnershipDisabled, visible }) => {
    const { formState: { errors }, reset } = useFormContext<RequestRmovalFormErrors>();
    useEffect(() => {
        reset()
    },[visible, reset]);
    return (
        <div>
            <h1 className="font-sans font-medium text-xl mb-4">Remove asset</h1>
            <div className="font-small text-sm text-[#696969]">If you have a reason for this asset to be removed, please write it in the comments and inform the manager.</div>
            <div className="my-4">
                <ControlledTextArea name="reqOwnerRemovalComment" required={true} label="Comment" error={errors.ownerRemovalComment} maxLength={420} readonly={removeOwnershipDisabled || selectedAsset.owner !== KeycloakService.getEmail() || selectedAsset?.assetStatus === "pendingForReview"}/>
                {errors.ownerRemovalComment && <p className="!text-red-500 mt-2">{errors.ownerRemovalComment.message}</p>}
            </div>
            <div className="my-4">
                <Button kind="primary" type="submit" disabled={removeOwnershipDisabled || selectedAsset.owner !== KeycloakService.getEmail() || selectedAsset?.assetStatus === "pendingForReview"}>Request removal</Button>
            </div>
        </div>
    )
}

export default ManageDataAssetModal;