import dayjs from "dayjs";
import DataAssetOptFieldsFormProvider from "../dataAssets/DataAssetOptFieldsFormProvider";
import { selectSurveyDetails } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import { SurveyDataDTO } from "../../../api";
import ControlledRadioGroup from "../../../core/components/ControlledRadioGroup";
import { assetCriticalityOptions, frequencyOfUseOptions, radioOptions, targetAudienceOptions } from "../../../services/constants";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ControlledDatePicker, ControlledDropdown } from "../../../core/components";
import { Button } from "@appkit4/react-components";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { getSurveyDetailsBU, updateSurveyDetailsBU } from "../../../store/project/projectThunks";
import SurveyReviewCommentDetails from "./SurveyReviewCommentDetails";

type AssetFormFieldsErrors = {
    devMigrationDate: string;
    dataSourcesMigrationDate: string;
    uatMigrationDate: string;
    preProductionMigrationDate: string;
    productionMigrationDate: string;
};

const SupplementaryDetailsForm: React.FC<{ surveyDetailsData: SurveyDataDTO, setIsSurveyStarted: Function, setIsViewDetailsClicked: Function }> = ({ surveyDetailsData, setIsSurveyStarted, setIsViewDetailsClicked }) => {
    const { formState: { errors } } = useFormContext<AssetFormFieldsErrors>();
    const [isUpdated, setIsUpdated] = useState(false);
    const { setValue, getValues } = useFormContext();
    useEffect(() => {
        if(surveyDetailsData?.supplementaryDetails?.devEnvCompleted){
            setValue("assetCriticality", surveyDetailsData?.supplementaryDetails?.assetCriticality);
            setValue("isDevMigrated", surveyDetailsData?.supplementaryDetails?.devEnvCompleted || radioOptions[1].value);
            setValue("isDataSourcesMigrated", surveyDetailsData?.supplementaryDetails?.dataSourcesMigrated || radioOptions[1].value);
            setValue("isUatMigrated", surveyDetailsData?.supplementaryDetails?.uatEnvCompleted || radioOptions[1].value);
            setValue("isPreProductionMigrated", surveyDetailsData?.supplementaryDetails?.prePodEnvCompleted || radioOptions[1].value);
            setValue("isProductionMigrated", surveyDetailsData?.supplementaryDetails?.prodEnvCompleted || radioOptions[1].value);
            setValue("devMigrationDate", surveyDetailsData.supplementaryDetails?.devEnvCompletedDate ? dayjs(new Date(surveyDetailsData?.supplementaryDetails?.devEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
            setValue("dataSourcesMigrationDate", surveyDetailsData?.supplementaryDetails?.dataSourcesMigratedDate ? dayjs(new Date(surveyDetailsData?.supplementaryDetails?.dataSourcesMigratedDate)).format("MM/DD/YYYY") : new Date());
            setValue("uatMigrationDate", surveyDetailsData?.supplementaryDetails?.uatEnvCompletedDate ? dayjs(new Date(surveyDetailsData?.supplementaryDetails?.uatEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
            setValue("preProductionMigrationDate", surveyDetailsData?.supplementaryDetails?.prePodEnvCompletedDate ? dayjs(new Date(surveyDetailsData?.supplementaryDetails?.prePodEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
            setValue("productionMigrationDate", surveyDetailsData?.supplementaryDetails?.prodEnvCompletedDate ? dayjs(new Date(surveyDetailsData?.supplementaryDetails?.prodEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
            setValue("complexity", surveyDetailsData?.supplementaryDetails?.assetComplexity);
            setValue("frequency", surveyDetailsData?.supplementaryDetails?.frequencyOfUse);
            setValue("targetAudience", surveyDetailsData?.supplementaryDetails?.targetAudience);
            setIsUpdated(true)
        }
    }, [setValue, surveyDetailsData]);
    return (
        <div>
            <div className="flex justify-between">
                <h1 className="font-sans font-bold font-medium text-2xl py-4">Supplementary details</h1>
                {surveyDetailsData?.surveyDetailsDTO?.updatedAt && surveyDetailsData?.surveyDetailsDTO?.updatedAt !== "" &&
                    <div className="w-fit mr-4 mt-4">
                        <span className={`Appkit4-icon icon-update-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                        <span>Last saved at {dayjs(new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "")).format("MM/DD/YYYY")}, </span>
                        <span>{Math.floor((new Date().getTime() - new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "").getTime()) / (1000 * 60 * 60 * 24))} days ago</span>
                    </div>
                }
            </div>
            <div className="p-8 bg-white">
                <div className="my-4 flex flex-row gap-2">
                    <div className="w-1/3 text-base text-[#696969]">
                        Development environment migrated
                    </div>
                    <div className="w-1/3">
                        <ControlledRadioGroup name="isDevMigrated" required={true} options={radioOptions} value={getValues().isDevMigrated} />
                    </div>
                    <div className="w-1/3">
                        <ControlledDatePicker onChange={() => console.log("Called")} name="devMigrationDate" disabled={getValues().isDevMigrated !== "yes"} required={getValues().isDevMigrated === "yes"} label="Development environment migrated"
                            error={errors.devMigrationDate} />
                        {errors.devMigrationDate && <p className="text-red-500 mt-2">{errors.devMigrationDate.message}</p>}
                    </div>
                </div>
                <div className="my-4 flex flex-row gap-2">
                    <div className="w-1/3 text-base text-[#696969]">
                        Data sources migrated
                    </div>
                    <div className="w-1/3">
                        <ControlledRadioGroup name="isDataSourcesMigrated" required={true} options={radioOptions} value={getValues().isDataSourcesMigrated} />
                    </div>
                    <div className="w-1/3">
                        <ControlledDatePicker onChange={() => console.log("Called")} name="dataSourcesMigrationDate" disabled={getValues().isDataSourcesMigrated !== "yes"} required={getValues().isDataSourcesMigrated === "yes"} label="Data sources migrated"
                            error={errors.dataSourcesMigrationDate} />
                        {errors.dataSourcesMigrationDate && <p className="text-red-500 mt-2">{errors.dataSourcesMigrationDate.message}</p>}
                    </div>
                </div>
                <div className="my-4 flex flex-row gap-2">
                    <div className="w-1/3 text-base text-[#696969]">
                        UAT environment migrated
                    </div>
                    <div className="w-1/3">
                        <ControlledRadioGroup name="isUatMigrated" required={true} options={radioOptions} value={getValues().isUatMigrated} />
                    </div>
                    <div className="w-1/3">
                        <ControlledDatePicker onChange={() => console.log("Called")} name="uatMigrationDate" disabled={getValues().isUatMigrated !== "yes"} required={getValues().isUatMigrated === "yes"} label="UAT environment migrated"
                            error={errors.uatMigrationDate} />
                        {errors.uatMigrationDate && <p className="text-red-500 mt-2">{errors.uatMigrationDate.message}</p>}
                    </div>
                </div>
                <div className="my-4 flex flex-row gap-2">
                    <div className="w-1/3 text-base text-[#696969]">
                        Pre-production environment migrated
                    </div>
                    <div className="w-1/3">
                        <ControlledRadioGroup name="isPreProductionMigrated" required={true} options={radioOptions} value={getValues().isPreProductionMigrated} />
                    </div>
                    <div className="w-1/3">
                        <ControlledDatePicker onChange={() => console.log("Called")} name="preProductionMigrationDate" disabled={getValues().isPreProductionMigrated !== "yes"} required={getValues().isPreProductionMigrated === "yes"} label="Pre-production environment migrated"
                            error={errors.preProductionMigrationDate} />
                        {errors.preProductionMigrationDate && <p className="text-red-500 mt-2">{errors.preProductionMigrationDate.message}</p>}
                    </div>
                </div>
                <div className="my-4 flex flex-row gap-2">
                    <div className="w-1/3 text-base text-[#696969]">
                        Production environment migrated
                    </div>
                    <div className="w-1/3">
                        <ControlledRadioGroup name="isProductionMigrated" required={true} options={radioOptions} value={getValues().isProductionMigrated} />
                    </div>
                    <div className="w-1/3">
                        <ControlledDatePicker onChange={() => console.log("Called")} name="productionMigrationDate" disabled={getValues().isProductionMigrated !== "yes"} required={getValues().isProductionMigrated === "yes"} label="Production environment migrated"
                            error={errors.productionMigrationDate} />
                        {errors.productionMigrationDate && <p className="text-red-500 mt-2">{errors.productionMigrationDate.message}</p>}
                    </div>
                </div>
                <div className="my-4">
                    <ControlledDropdown name="assetCriticality" label="Criticality" data={assetCriticalityOptions} required={false} />
                </div>
                <div className="my-4">
                    <ControlledDropdown name="complexity" label="Complexity" data={assetCriticalityOptions} required={false} />
                </div>
                <div className="my-4">
                    <ControlledDropdown name="frequency" label="Frequency of use" data={frequencyOfUseOptions} required={false} />
                </div>
                <div className="my-4">
                    <ControlledDropdown name="targetAudience" label="Target Audience" data={targetAudienceOptions} required={false} />
                </div>
                
                <div className="flex justify-end gap-2">
                    <Button kind='secondary' onClick={() => {
                        setIsSurveyStarted(false)
                        setIsViewDetailsClicked(false)
                    }}>Cancel</Button>
                    <Button kind='primary' icon="icon-check-hook-outline" disabled={!isUpdated} type="submit">Save and Next</Button>
                </div>
            </div>
        </div>

    )
}

const SupplementaryDetailsBusinessUser: React.FC<{ setIsSurveyStarted: Function, setActiveIndex: Function, setIsViewDetailsClicked: Function }> = ({ setIsSurveyStarted, setActiveIndex, setIsViewDetailsClicked }) => {
    const dispatch = useDispatch<AppDispatch>();
    const surveyDetailsData = useSelector(selectSurveyDetails);
    const savOptionalFieldsDetails = async (data: any) => {
        const assetSupplementaryData = {
            assetBusinessId: surveyDetailsData?.supplementaryDetails?.assetBusinessId,
            assetTechnicalId: surveyDetailsData?.supplementaryDetails?.assetTechnicalId,
            assetCriticality: data?.assetCriticality,
            devEnvCompleted: data.isDevMigrated,
            devEnvCompletedDate: data.isDevMigrated === "yes" ? new Date(`${data.devMigrationDate} UTC`).toISOString() : undefined,
            dataSourcesMigrated: data.isDataSourcesMigrated,
            dataSourcesMigratedDate: data.isDataSourcesMigrated === "yes" ? new Date(`${data.dataSourcesMigrationDate} UTC`).toISOString() : undefined,
            uatEnvCompleted: data?.isUatMigrated,
            uatEnvCompletedDate: data?.isUatMigrated === "yes" ? new Date(`${data?.uatMigrationDate} UTC`).toISOString() : undefined,
            prePodEnvCompleted: data?.isPreProductionMigrated,
            prePodEnvCompletedDate: data?.isPreProductionMigrated === "yes" ? new Date(`${data?.preProductionMigrationDate} UTC`).toISOString() : undefined,
            prodEnvCompleted: data?.isProductionMigrated,
            prodEnvCompletedDate: data?.isProductionMigrated === "yes" ? new Date(`${data?.productionMigrationDate} UTC`).toISOString() : undefined,
            assetComplexity: data?.complexity,
            frequencyOfUse: data?.frequency,
            targetAudience: data?.targetAudience
        };
        await dispatch(updateSurveyDetailsBU({assetId: surveyDetailsData?.supplementaryDetails?.assetBusinessId, surveyState: "supplementaryDetails", supplementaryDetails: assetSupplementaryData}));
        await dispatch(getSurveyDetailsBU(surveyDetailsData?.supplementaryDetails?.assetBusinessId));
        setActiveIndex(1);
    }
    return (
        <>
            {surveyDetailsData?.surveyDetailsDTO?.reviewComment && surveyDetailsData?.surveyDetailsDTO?.reviewComment !== "" && surveyDetailsData?.surveyDetailsDTO?.surveyStatus === "onGoing" &&
                <SurveyReviewCommentDetails surveyData={surveyDetailsData?.surveyDetailsDTO}/>
            }
            <DataAssetOptFieldsFormProvider onSubmit={savOptionalFieldsDetails}>
                <SupplementaryDetailsForm surveyDetailsData={surveyDetailsData} setIsSurveyStarted={setIsSurveyStarted} setIsViewDetailsClicked={setIsViewDetailsClicked}/>
            </DataAssetOptFieldsFormProvider>
        </>
        
    )
}

export default SupplementaryDetailsBusinessUser;