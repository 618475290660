import { Button, Modal } from "@appkit4/react-components";

const DenySurveyModal: React.FC<{denySurvey: Function, setDenyClicked: Function, denyClicked: boolean}> = ({ denySurvey, setDenyClicked, denyClicked }) => {
    return (
        <Modal
            visible={denyClicked}
            title={"Deny survey"}
            onCancel={() => setDenyClicked(false)}
            modalStyle={{ width: '33.75rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <div className="font-small text-sm text-[#696969]">
                Are you sure you want to deny the survey and return it to the asset owner? Once you deny the survey, it will return to ongoing status, and this action cannot be undone.
            </div>
            <div className="mt-8 flex justify-end gap-2">
                <Button kind="secondary" onClick={() => setDenyClicked(false)}>Back</Button>
                <Button kind="primary" onClick={denySurvey}>Confirm</Button>
            </div>
        </Modal>);
}

export default DenySurveyModal;