import { Button, Modal } from "@appkit4/react-components";

interface DeleteUseCaseConfirmationModalProps {
    visible: boolean;
    closeModal: Function;
    deleteUseCase: Function;
}

const DeleteUseCaseConfirmationModal: React.FC<DeleteUseCaseConfirmationModalProps> = ({ visible, closeModal, deleteUseCase }) => {
    return (
        <Modal
            visible={visible}
            title={"Delete use case"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
            footer={
                <>
                    <Button onClick={closeModal} kind="secondary">Cancel</Button>
                    <Button icon="icon-archive-outline" kind="negative" onClick={deleteUseCase}>Delete</Button>
                </>
            }
            bodyStyle={{ minHeight: '92px' }}
        >
            <p>Are you sure you want to delete this use case? This action is permanent.</p>
        </Modal>);
}

export default DeleteUseCaseConfirmationModal;