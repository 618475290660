import { useState } from "react";
import { assetTypeOptions, environmentOptions } from "../../../services/constants";
import { Badge, Button, Input, TextArea } from "@appkit4/react-components";
import { useSelector } from "react-redux";
import { selectSelectedAsset } from "../../../store/project/projectSelectors";
import { RequestDTO } from "../../../api";
import DenialConfirmationModal from "./DenialConfirmationModal";

const AddDataAssetScreenComp:React.FC<{selectedAssetReqeust: RequestDTO, actionOnRequest: Function}> = ({selectedAssetReqeust, actionOnRequest}) => {
    const [isDenialModalShow, setIsDenialModalShow] = useState(false);
    const selectedAsset = useSelector(selectSelectedAsset);
    const denialSubheader = "Please review the request thoroughly. If the applicant can not add the asset, please justify the reason. The user will be notified."
    const closeDenialModal = () => {
        setIsDenialModalShow(false);
    }
    const denyAssetReq = (data: any) => {
        setIsDenialModalShow(false);
        actionOnRequest({requestTechnicalId: selectedAssetReqeust?.requestTechnicalId, requestBusinessId: selectedAssetReqeust?.requestBusinessId, actionType: "deny", actionComment: data?.denialRequest})
    };
    const approveAssetReq = () => {
        actionOnRequest({requestTechnicalId: selectedAssetReqeust?.requestTechnicalId, requestBusinessId: selectedAssetReqeust?.requestBusinessId, actionType: "approved"})
    }
    return(
        <div className="bg-white p-4">
            <div className="mb-4">
            <h1 className="font-sans font-medium text-xl">New Asset</h1>
            <div>The following user has added a new asset. Please review the details and deny or approve the request.</div>
            </div>
            <div>
                <div className="my-4">
                    <Input readonly required={false} type="text" value={selectedAsset?.createdBy} title="Created by"/>
                </div>
                <div className="my-4">
                    <Input readonly name="assetName" required={false} type="text" title="Asset name" value={selectedAsset?.assetName} />
                </div>
                <div className="my-4">
                    <Input readonly required={false} value={assetTypeOptions.filter((type) => type.value === selectedAsset?.assetType)?.[0]?.label} title="Asset type" />
                </div>
                <div className="my-4">
                    <TextArea readonly required={false} value={selectedAsset?.assetDescription} title={"Asset description"}/>
                </div>
                <div className="my-4">
                    <Input readonly required={false} type="text" label="Environment" value={environmentOptions?.filter((environment) => environment.value === selectedAsset?.environment)?.[0]?.label} title="Environment" />
                </div>
                {(selectedAsset?.assetType === 'aiModel' || selectedAsset?.assetType === 'sasScript' || selectedAsset?.assetType === 'application') &&
                    <>
                        <div className="bg-white border-2 p-2 text-[#696969] my-4">
                            <>
                                <div>Databases</div>
                                {selectedAsset?.databaseValue && selectedAsset?.databaseValue?.length > 0 &&
                                <div className="flex gap-0.5 flex-wrap">
                                    {selectedAsset?.databaseValue?.map((database, index) =>
                                        <Badge key={index} type={'primary'} value={database.name} />
                                    )}
                                </div>}
                            </>
                        </div>
                        <div className="bg-white border-2 p-2 text-[#696969] my-4">
                            <>
                                <div>Tables</div>
                                {selectedAsset?.tableValue && selectedAsset?.tableValue?.length > 0 &&
                                <div className="flex gap-0.5 flex-wrap">
                                    {selectedAsset?.tableValue?.map((table, index) =>
                                        <Badge key={index} type={'primary'} value={table.name} />
                                    )}
                                </div>}
                            </>
                        </div>
                    </>
                }
                {selectedAsset?.assetType === 'application' &&
                    <div className="bg-white border-2 p-2 text-[#696969] my-4">
                        <>
                            <div>Technologies</div>
                            {selectedAsset?.technologyValue && selectedAsset?.technologyValue?.length > 0 &&
                            <div className="flex gap-0.5 flex-wrap">
                                {selectedAsset?.technologyValue?.map((technology, index) =>
                                    <Badge key={index} type={'primary'} value={technology.name} />
                                )}
                            </div>}
                        </>
                    </div>
                }
            </div>
            <div className="flex justify-end gap-2">
                <Button kind='secondary' icon="icon-close-outline" onClick={() => setIsDenialModalShow(true)}>Deny</Button>
                <Button kind='primary' icon="icon-check-hook-outline" onClick={approveAssetReq}>Approve</Button>
            </div>
            <DenialConfirmationModal visible={isDenialModalShow} closeModal={closeDenialModal} denyAssetReq={denyAssetReq} denialHeader={"Denial of adding new asset"} denialSubheader={denialSubheader}/>
        </div>
    )
}

export default AddDataAssetScreenComp;