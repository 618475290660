import { Button, Loading, Column, Table, Badge, Accordion, AccordionItem, Select, Pagination } from '@appkit4/react-components';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { getAssetTableData, getProjectsList, getRequestDetailsForAsset, getUsers } from '../../../store/project/projectThunks';
import { selectGetAllAssetForProject, selectProjectError, selectProjectLoading, selectScopeDataOverView, selectSelectedAsset, selectSelectedProjectData } from '../../../store/project/projectSelectors';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setSelectedAsset } from '../../../store/project/projectSlice';
import { assetTypeOptions, dataAssetsWithPreviewMapping, itemsPerPageOptions, migrationStatusOptions, scopeOverviewKeyLabel, surveyStatusIndicators } from '../../../services/constants';
import dayjs from 'dayjs';
import AssetDetailsSection from '../../../components/project/dataAssets/AssetDetailsSection';
const ScopeDetailsContainerManager = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { projectId } = useParams();
    const navigate = useNavigate();
    const selectedProject = useSelector(selectSelectedProjectData);
    const selectedAsset = useSelector(selectSelectedAsset);
    const [current, setCurrent] = useState(1);
    const [selectedValue, setSelectedValue] = useState("10");
    const scopeOverviewData = useSelector(selectScopeDataOverView);
    const loading = useSelector(selectProjectLoading);
    const error = useSelector(selectProjectError);
    const listOfAssets = useSelector(selectGetAllAssetForProject);
    const [activeKeys, setActiveKeys] = useState<string[]>(["1"]);
    const [isAssetClicked, setIsAssetClicked] = useState(false);
    const onClickAccordion = (activeKeys: string[]) => {
        setActiveKeys(activeKeys);
    }
    const onPageChange = (page: number) => {
        setCurrent(page);
    }
    const scopeOverview = () => {
        const reqPayload = {
            searchString: "",
            isOwnedByMeChecked: false,
            isUnassignedChecked: false,
            pageNumber: current,
            pageSize: Number(selectedValue),
            projectId: projectId
        }
        if(projectId){
            dispatch(getAssetTableData(reqPayload));
            dispatch(getUsers());
        }
        setIsAssetClicked(false)
    }
    useEffect(() => {
        const reqPayload = {
            searchString: "",
            isOwnedByMeChecked: false,
            isUnassignedChecked: false,
            pageNumber: current,
            pageSize: Number(selectedValue),
            projectId: projectId
        }
        if(projectId){
            dispatch(getAssetTableData(reqPayload));
            dispatch(getUsers());
        }
            
    }, [dispatch, projectId, current, selectedValue])

    if (loading) {
        return (
            <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
        )
    }

    return (
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        dispatch(getProjectsList());
                        navigate("/dashboard");
                    }}>All Projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => navigate(`/project/${selectedProject?.projectBusinessId}`)}>{selectedProject?.name}</Button>
                </BreadcrumbItem>
                {isAssetClicked ? 
                    <>
                        <BreadcrumbItem>
                            <Button className="px-0" kind='text' onClick={scopeOverview}>Scope overview</Button>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {selectedAsset?.assetName}
                        </BreadcrumbItem>
                    </>
                    :
                    <BreadcrumbItem>
                        Scope overview
                    </BreadcrumbItem>
                }
                
            </Breadcrumb>
            {isAssetClicked ? 
                <AssetDetailsSection />
                :
                <>
                    <h1 className="font-sans font-medium text-xl mt-4 mb-2">Scope overview</h1>
                    <div className="text-[#696969] mb-8">Here you can view detailed information on all assets involved in the migration project. Track progress, identify assets,dependencies, and overview your cloud transition effectively.</div>   
                    <Accordion multiple={false} onClick={onClickAccordion} activeKeys={activeKeys}>
                        <AccordionItem className="bg-white hover:bg-white pb-4" templateHeader={() => <div className="text-xl">Assets overview</div>} itemKey="1">
                            <div className="flex flex-row border-b-2 border-[#9AA4BE] justify-between">
                                <div className="py-4">
                                    <span className={`ap-font-24 pr-2`} aria-hidden="true">{scopeOverviewData?.totalAssets}</span>
                                    <span>data assets in total</span>
                                </div> 
                            </div>
                            {Object.entries(scopeOverviewData).map(([key, value]) => (
                                key === "lastUpdated" && 
                                    <div className="flex justify-between border-b-2 p-2" key={key}>
                                        <div>{scopeOverviewKeyLabel?.filter(keyLabel=>keyLabel.value === key)[0].label}</div>
                                        <div>{value ? dayjs(new Date(value)).format("MM/DD/YYYY") : "NA"}</div>
                                    </div>
                            ))}
                            {Object.entries(scopeOverviewData).map(([key, value]) => (
                                key !== "totalAssets"  && key !== "lastUpdated" &&
                                    <div className="flex justify-between border-b-2 p-2" key={key}>
                                        <div>{scopeOverviewKeyLabel?.filter(keyLabel=>keyLabel.value === key)[0].label}</div>
                                        <div>{value}</div>
                                    </div>
                            ))}
                        </AccordionItem>
                    </Accordion>
                    <div className="my-8">
                        {listOfAssets && listOfAssets.data && listOfAssets?.data?.length > 0 ?
                            <Table originalData={JSON.parse(JSON.stringify(listOfAssets?.data))} hasTitle>
                                <Column field='assetType' sortKey='assetType' renderCell={
                                    (row) => {
                                        return (
                                            <>
                                                <span className={`Appkit4-icon icon-${dataAssetsWithPreviewMapping.filter(dataAsset => dataAsset.name === row.assetType)[0]?.prefixIcon}-outline ap-font-24 px-4`} aria-hidden="true"></span>
                                                <span className="pl-2">{assetTypeOptions.filter(option => option.value === row.assetType)[0]?.label}</span>
                                            </>
                                        )
                                    }
                                }>Type</Column>
                                <Column field='assetName' sortKey='assetName' renderCell={(row) => {
                                    return(
                                        <span className="underline text-[#415386] cursor-pointer" onClick={ async() => {
                                            setIsAssetClicked(true);
                                            dispatch(setSelectedAsset(row));
                                            await dispatch(getRequestDetailsForAsset(row.assetBusinessId));
                                        }}>{row.assetName}</span>
                                    )
                                }}>Name</Column>
                                <Column field='owner' sortKey='owner'>Owner</Column>
                                <Column field='dbName' sortKey='dbName'>DB Name</Column>
                                <Column field='dwhName' sortKey='dwhName'>DWH Name</Column>
                                <Column field='surveyStatus' renderCell={(row) => {
                                    const selectedStatus = surveyStatusIndicators.filter(statusDetail => statusDetail.status === row.surveyStatus)[0]
                                    return (
                                        !row.assetActiveStatus ?
                                            <div> --- </div>
                                            :
                                            <div>
                                                <Badge type={selectedStatus?.statusIndicator} value={selectedStatus?.label} />
                                                {/* {selectedStatus?.btnText &&
                                                    <div className="cursor-pointer px-2" onClick={() => console.log("button clicked")}>
                                                        <Badge icon={`icon-arrow-right-small-outline`} type="primary" value={selectedStatus?.btnText} />
                                                    </div>
                                                } */}
                                            </div>
                                            
                                    )
                                }}
                                >Survey status
                                </Column>
                                <Column field='migrationStatus' sortKey='migrationStatus'
                                    renderCell={(row) =>
                                        <span>{migrationStatusOptions.filter((status) => status.value === row.migrationStatus)[0].label}</span>
                                    }>Migration Status</Column>
                            </Table>
                            :
                            <div className="my-16 text-bold"> No Data found </div>
                        }
                        <div className="flex justify-between">
                            <div className="flex mt-4 gap-2 items-center">
                                <span>Show</span>
                                <Select className="!w-20"
                                    placeholder="drop"
                                    hideTitleOnInput={true}
                                    data={itemsPerPageOptions}
                                    value={selectedValue}
                                    onSelect={(e) => setSelectedValue(e.toString())}
                                >
                                </Select>
                                <span>items per page</span>
                            </div>
                            <Pagination current={current} total={listOfAssets?.totalPages || 1} onPageChange={onPageChange}></Pagination>
                        </div>
                    </div>
                    
                </>
            }
            
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default ScopeDetailsContainerManager;