import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

type BusinessDetailsFormValues = {
  departmentName: string;
  primaryUserGroup: string;
  isAssetTiedInProjet: string;
  tiedprojectName?: string;
  useCases?: Array<string> 
};

type BusinessDetailsFormProviderProps = {
  onSubmit: SubmitHandler<BusinessDetailsFormValues>;
  children: React.ReactNode;
};

const BusinessDetailsFormProvider: React.FC<BusinessDetailsFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<BusinessDetailsFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default BusinessDetailsFormProvider;
