import { Badge, Button, Column, Pagination, Panel, Select, Tab, Table, Tabs } from '@appkit4/react-components';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { getProjectsList, getSurveyDetailsBU, getSurveyListData, getSurveyOverviewData, requestDeadLineForSurvey, reviewSurveyMM } from '../../../store/project/projectThunks';
import { selectProjectError, selectSelectedProjectData, selectSurveyListingData, selectSurveyOverviewData } from '../../../store/project/projectSelectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { assetTypeOptions, dataAssetsWithPreviewMapping, environmentOptions, itemsPerPageOptions, migrationStatusOptions, surveyStatusIndicators } from '../../../services/constants';
import { useEffect, useState } from 'react';
import { SurveyDTO, surveyStatus2 } from '../../../api';
import AddDeadlineModal from '../../../components/project/survey/AddDeadlineModal';
import RequestForMultipleSurveyDeadline from '../../../components/project/survey/RequestForMultipleSurveyDeadline';
import ReviewSurveyRequest from '../../../components/project/survey/ReviewSurveyRequest';

const ProjectSurveyContainerManager = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const selectedProject = useSelector(selectSelectedProjectData);
    const surveyListingData = useSelector(selectSurveyListingData);
    const surveyOverviewData = useSelector(selectSurveyOverviewData);
    const error = useSelector(selectProjectError);
    const [current, setCurrent] = useState(1);
    const [selectedValue, setSelectedValue] = useState("10");
    const [activeIndex, setactiveIndex] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState("all");
    const [isDeadlineModalVisible, setIsDeadlineModalVisible] = useState(false);
    const [isRequestInfoClicked, setIsRequestInfoClicked] = useState(false);
    const [isReviewClicked, setIsReviewClicked] = useState(false);
    const [selectedRow, setSelectedRow] = useState<SurveyDTO>({
        assetTechnicalId: "",
        assetBusinessId: "",
        assetName: "",
        assetType: "report"
    });
    const dataAssetsData = [
        {
            status: "Completed",
            numberOfSurvey: surveyOverviewData?.complete,
            percentage: surveyOverviewData?.completePercentage
        },
        {
            status: "Ongoing",
            numberOfSurvey: surveyOverviewData?.onGoing,
            percentage: surveyOverviewData?.onGoingPercentage
        },
        {
            status: "Not Started",
            numberOfSurvey: surveyOverviewData?.notStarted,
            percentage: surveyOverviewData?.notStartedPercentage
        },
    ]
    const onTabChange = (i: number, value: string) => {
        setactiveIndex(i);
        setSelectedFilter(value)
    }
    const onPageChange = (page: number) => {
        setCurrent(page);
    }
    const requestSurvey = (row: SurveyDTO) => {
        setSelectedRow(row)
        setIsDeadlineModalVisible(true);
    }
    const reviewSurvey = async (row: SurveyDTO) => {
        await dispatch(getSurveyDetailsBU(row?.assetBusinessId));
        setSelectedRow(row)
        setIsReviewClicked(true)
        setIsRequestInfoClicked(false)
    }

    const closeDeadlineModal = () => {
        setIsDeadlineModalVisible(false);
    }
    const addDeadLine = async (deadLine: string) => {
        await dispatch(requestDeadLineForSurvey({ projectBusinessId: selectedProject?.projectBusinessId, assets: [selectedRow?.assetBusinessId], deadLine: new Date(`${deadLine} UTC`).toISOString()}));
        await dispatch(getSurveyListData({ pageNumber: current, pageSize: Number(selectedValue), projectBusinessId: selectedProject?.projectBusinessId, surveyStatus: selectedFilter}))
        await dispatch(getSurveyOverviewData(selectedProject?.projectBusinessId));
        setIsDeadlineModalVisible(false);
    }
    const defineSurveysDeadline = async (selectedSurveys: Array<string>, date: string) => {
        await dispatch(requestDeadLineForSurvey({ projectBusinessId: selectedProject?.projectBusinessId, assets: selectedSurveys, deadLine: new Date(`${date} UTC`).toISOString()}));
        await dispatch(getSurveyListData({ pageNumber: current, pageSize: Number(selectedValue), projectBusinessId: selectedProject?.projectBusinessId, surveyStatus: selectedFilter}))
        await dispatch(getSurveyOverviewData(selectedProject?.projectBusinessId));
        setIsRequestInfoClicked(false);
    }

    const surveyReview = async (payload: {assetId: string, surveyState: surveyStatus2, comment: string}) => {
        await dispatch(reviewSurveyMM(payload));
        await dispatch(getSurveyListData({ pageNumber: current, pageSize: Number(selectedValue), projectBusinessId: selectedProject?.projectBusinessId, surveyStatus: selectedFilter}))
        await dispatch(getSurveyOverviewData(selectedProject?.projectBusinessId));
        setIsReviewClicked(false);
    }

    useEffect(() => {
        if (selectedProject?.projectBusinessId) {
            dispatch(getSurveyListData({ pageNumber: current, pageSize: Number(selectedValue), projectBusinessId: selectedProject?.projectBusinessId, surveyStatus: selectedFilter }))
            dispatch(getSurveyOverviewData(selectedProject?.projectBusinessId));
        }
    }, [dispatch, current, selectedValue, selectedProject, selectedFilter])

    return (
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        dispatch(getProjectsList());
                        navigate("/dashboard")
                    }}>All Projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        navigate(`/project/${selectedProject?.projectBusinessId}`);
                    }}>{selectedProject?.name}</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    {(isRequestInfoClicked || isReviewClicked) ?
                        <Button className="px-0" kind='text' onClick={() => {
                            setIsRequestInfoClicked(false)
                            setIsReviewClicked(false)
                        }}>Survey overview</Button>
                        :
                        <span>Survey overview</span>
                    }
                </BreadcrumbItem>
                {isRequestInfoClicked &&
                    <BreadcrumbItem>
                        Request information
                    </BreadcrumbItem>
                }
                {isReviewClicked &&
                    <BreadcrumbItem>
                        Survey review
                    </BreadcrumbItem>
                }
            </Breadcrumb>
            {!isReviewClicked && 
                <>
                    <div className="flex justify-between">
                        <div>
                            <h1 className="font-sans font-medium text-xl mt-4">Survey overview</h1>
                            <h3>Track and monitor the status of all asset surveys and send reminders to users. </h3>
                        </div>
                        <div className="flex gap-4 font-medium">
                            <div className="bg-white flex w-64">
                                <div className="text-2xl ml-4 mr-2 my-4">Number of surveys</div>
                                <div className="text-6xl mr-4 ml-2 my-4">{surveyOverviewData?.numberOfSurvey}</div>
                            </div>
                            <div className="bg-white flex w-64">
                                <div className="text-2xl ml-4 mr-2 my-4">Waiting for review</div>
                                <div className="text-6xl mr-4 ml-2 my-4">{surveyOverviewData?.inReview}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between bg-white my-4 p-4">
                        {dataAssetsData.map((dataAsset, index) =>
                            <div className="w-1/3 my-4 mx-4" key={index}>
                                <Panel className="bg-gray-100 p-4">
                                    <div className="font-medium text-xl flex flex-row my-4 mx-2">
                                        <Badge className="px-4 mt-5 mr-2" type={dataAsset.status === "Completed" ? 'success' : dataAsset.status === "Ongoing" ? "warning" : "info-outlined"} />
                                        <div className='mt-4'>{dataAsset.status}</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div>
                                            <div className="ml-2 text-6xl">{dataAsset.numberOfSurvey}</div>
                                            <div className="ml-2">Number of Survey</div>
                                        </div>
                                        <div>
                                            <div className="ml-2 text-6xl">{dataAsset.percentage}%</div>
                                            <div className="ml-2">Percentage</div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                        )}
                    </div>
                </>
            }  
            {isRequestInfoClicked ?
                <RequestForMultipleSurveyDeadline setIsRequestInfoClicked={setIsRequestInfoClicked} defineSurveysDeadline={defineSurveysDeadline}/>
                :
                isReviewClicked ? 
                    <ReviewSurveyRequest setIsReviewClicked={setIsReviewClicked} surveyReview={surveyReview}/>
                    :
                    <div className="my-4 p-4 bg-white">
                        <div className="flex justify-between">
                            <div className="flex justify-start gap-2">
                                <h1 className="font-sans font-medium text-xl ml-4 mt-2">Survey overview</h1>
                                <span className="mt-3">Last update 15 min ago</span>
                            </div>
                            <div className="flex gap-2 mt-2 mr-2">
                                {/* <Button kind="secondary" icon="icon-download-light-outline">CSV</Button> */}
                                <Button kind="primary" icon="icon-plus-outline" onClick={() => {
                                    setIsRequestInfoClicked(true) 
                                    setIsReviewClicked(false)
                                }}>Request information</Button>
                            </div>
                        </div>
                        <div className="flex justify-start gap-1">
                            <h1 className="font-sans font-medium text-xl ml-4 w-1/6">Asset survey</h1>
                            <Tabs type='filled' activeIndex={activeIndex} onTabChange={onTabChange}>
                                <Tab label='All' value="all"></Tab>
                                <Tab label='Not Started' value="notStarted"></Tab>
                                <Tab label='Requested' value="requested"></Tab>
                                <Tab label='Ongoing' value="onGoing"></Tab>
                                <Tab label='In review' value="inReview"></Tab>
                                <Tab label='Complete' value="complete"></Tab>
                            </Tabs>
                        </div>
                        <div className='mr-6 my-4'>
                            {surveyListingData?.data && surveyListingData?.data?.length > 0 &&
                                <Table originalData={JSON.parse(JSON.stringify(surveyListingData?.data))} hasTitle className="bg-[#F3F3F3] m-4">
                                    <Column field='assetType' sortKey='assetType' renderCell={
                                        (row) => {
                                            return (
                                                <>
                                                    <span className={`Appkit4-icon icon-${dataAssetsWithPreviewMapping.filter(dataAsset => dataAsset.name === row.assetType)[0]?.prefixIcon}-outline ap-font-24 px-4`} aria-hidden="true"></span>
                                                    <span className="pl-2">{assetTypeOptions.filter(option => option.value === row.assetType)[0]?.label}</span>
                                                </>
                                            )
                                        }
                                    }>Type</Column>
                                    <Column field='assetName' sortKey='assetName'>Name</Column>
                                    <Column field='environment' sortKey='environment' renderCell={(row) => environmentOptions.filter(option => option.value === row.environment)?.[0]?.label}>Environment</Column>
                                    <Column field='owner' sortKey='owner'>Owner</Column>
                                    <Column field='surveyStatus' renderCell={(row) => {
                                        const selectedStatus = surveyStatusIndicators.filter(statusDetail => statusDetail.status === row.surveyStatus)[0]
                                        return <Badge type={selectedStatus?.statusIndicator} value={selectedStatus?.label} />
                                    }}>Survey status</Column>
                                    <Column field='assetTechnicalId' sortKey='assetTechnicalId' renderCell={(row) => {
                                        if(row?.surveyStatus !== "complete") 
                                            return <Button kind={row?.surveyStatus === "notStarted" ? "primary" : "secondary"} disabled={row?.surveyStatus === "requested" || row?.surveyStatus === "onGoing"} 
                                                onClick={() => row?.surveyStatus === "notStarted" ? requestSurvey(row) : reviewSurvey(row)}>
                                                {row?.surveyStatus === "notStarted" ? "Request" : "Review"}
                                            </Button>
                                    }}></Column>
                                    <Column field='wave' sortKey='wave'>Wave</Column>
                                    <Column field='migrationStatus' sortKey='migrationStatus' renderCell={(row) => migrationStatusOptions.filter(option => option.value === row.migrationStatus)?.[0]?.label}>Migration status</Column>
                                </Table>
                            }
                        </div>
                        <AddDeadlineModal visible={isDeadlineModalVisible} closeModal={closeDeadlineModal} addDeadline={addDeadLine} />
                        <div className="flex justify-between mx-4">
                            <div className="flex mt-4 gap-2 items-center">
                                <span>Show</span>
                                <Select className="!w-20"
                                    placeholder="drop"
                                    hideTitleOnInput={true}
                                    data={itemsPerPageOptions}
                                    value={selectedValue}
                                    onSelect={(e) => setSelectedValue(e.toString())}
                                    dropdownAlwaysDown={false}
                                >
                                </Select>
                                <span>items per page</span>
                            </div>
                            <Pagination current={current} total={surveyListingData?.totalPages || 1} onPageChange={onPageChange}></Pagination>
                        </div>
                    </div>
            }
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default ProjectSurveyContainerManager;