import { useFormContext } from "react-hook-form";
import { ControlledInput, ControlledDropdown, ControlledTextArea } from "../../../core/components";
import { Button, Input, Tag } from '@appkit4/react-components';
import { KeycloakService } from "../../../services/KeycloakService";
import AddUseCaseFormProvider from "./AddUseCaseFormProvider";
import { useCaseComplexityOptions, useCaseSizeOptions, useCaseTypeOptions } from "../../../services/constants";
import { useEffect, useState } from "react";
import DependenciesSelectionModal from "./DependenciesSelectionModal";
import { useSelector } from "react-redux";
import { selectDependenciesValuesOptions, selectOldDependenciesValuesOptions, selectSelectedProjectData, selectUsersListByRole } from "../../../store/project/projectSelectors";
import { getAllDependencies } from "../../../store/project/projectThunks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { UseCaseDependencies } from "../../../api";
import { setOldDependenciesOptions, setUseCaseFormValues } from "../../../store/project/projectSlice";

type UseCaseFormValues = {
    useCaseName: string;
    useCaseDesc: string;
    useCaseOwner?: string;
    useCaseType: string;
    useCaseSize: string;
    useCaseDependency: string;
    useCaseComplexity: string;
};

type UseCaseFormErrors = {
    useCaseName: string;
    useCaseDesc: string;
    useCaseType: string;
    useCaseSize: string;
    useCaseComplexity: string;
    useCaseOwner: string;
};

const UseCaseDetailsComp: React.FC<{ selectedDependencies: Array<string | number>, setSelectedDependencies: Function, setIsAddUseCaseOpen: Function }> = ({ selectedDependencies, setSelectedDependencies, setIsAddUseCaseOpen }) => {
    const dispatch = useDispatch<AppDispatch>();
    const listOfUsers = useSelector(selectUsersListByRole);
    const email = KeycloakService.getEmail();
    const selectedProject = useSelector(selectSelectedProjectData)
    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [isDependenciesModalVisible, setIsDependenciesModalVisible] = useState(false);
    const { formState: { errors } } = useFormContext<UseCaseFormErrors>();
    const dependenciesValue = useSelector(selectDependenciesValuesOptions);
    const oldDependenciesOptions = useSelector(selectOldDependenciesValuesOptions);
    let options = JSON.parse(JSON.stringify(dependenciesValue?.data))
    if (dependenciesValue && dependenciesValue.data && dependenciesValue?.data?.length > 0 && oldDependenciesOptions?.length > 0) {
        options = [...oldDependenciesOptions, ...options];
    }
    const { setValue } = useFormContext();
    useEffect(() => {
        if(KeycloakService.getRoles().includes("business-user"))
            setValue("useCaseOwner", KeycloakService.getEmail())
    },[setValue]);

    const handleScroll = (event: any) => {
        const target = event?.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight && dependenciesValue?.data?.length === 10) {
            dispatch(setOldDependenciesOptions(dependenciesValue?.data))
            setPageNumber(pageNumber + 1)
        }
    }
    const listOfBusinessUsers = listOfUsers.filter(list => list.role === "BusinessUser")?.[0]?.users.map((user:any) => {return{value: user.email, label: user.email}})
    const loggedInUserRole = KeycloakService?.getRoles().includes("migration-manager") ? "manager" : "businessUser";

    const handleSearch = (e: string) => {
        dispatch(setOldDependenciesOptions([]));
        setPageNumber(0);
        setSearchText(e);
    }

    useEffect(() => {
        dispatch(getAllDependencies({ pageNumber: pageNumber, searchString: searchText, pageSize: '10', projectBusinessId: selectedProject?.projectBusinessId }));
    }, [dispatch, searchText, pageNumber, selectedProject]);

    const cancelProject = () => {
        setIsAddUseCaseOpen(false);
    }

    const closeDependenciesModal = () => {
        setIsDependenciesModalVisible(false)
    }

    const addDependencyToPayload = (dependencies: Array<{
        useCaseTechnicalId?: string;
        useCaseBusinessId?: string;
        name?: string;
    }>) => {
        setSelectedDependencies(dependencies);
        setIsDependenciesModalVisible(false);
    }

    return (
        <div className='flex-1 bg-[#F3F3F3] mt-4'>
            <h1 className="font-sans font-medium text-xl mt-4">Add use case</h1>
            <h3 className="mb-4">To add a new use case, please ensure you fill in all the mandatory fields. The content of the use cases will be based on the information provided in the asset surveys.</h3>
            <div className="p-6 bg-white">
                <h1 className="font-sans font-medium text-xl">Details</h1>
                <div className="my-4">
                    <ControlledInput name="useCaseName" required={true} label="Name" error={errors.useCaseName} />
                    {errors.useCaseName && <p className="text-red-500 mt-2">{errors.useCaseName.message}</p>}
                </div>
                <div className="my-4">
                    <ControlledTextArea name="useCaseDesc" required={true} label="Description" error={errors.useCaseDesc} />
                    {errors.useCaseDesc && <p className="text-red-500 mt-2">{errors.useCaseDesc.message}</p>}
                </div>
                <div className="my-4">
                    {loggedInUserRole === "manager" ?
                        <>
                            <ControlledDropdown name="useCaseOwner" label="Owner" error={errors.useCaseOwner} data={listOfBusinessUsers} required={true} />
                            {errors.useCaseOwner && <p className="!text-red-500 mt-2">{errors.useCaseOwner.message}</p>}
                        </>
                        :
                        <Input readonly title="Owner" value={email} />
                    }
                    
                </div>
                <div className="my-4">
                    <ControlledDropdown name="useCaseType" label="Type" error={errors.useCaseType} data={useCaseTypeOptions} required={true} />
                    {errors.useCaseType && <p className="!text-red-500 mt-2">{errors.useCaseType.message}</p>}
                </div>
                <div className="my-4">
                    <ControlledDropdown name="useCaseSize" label="Size" error={errors.useCaseSize} data={useCaseSizeOptions} required={true} />
                    {errors.useCaseSize && <p className="!text-red-500 mt-2">{errors.useCaseSize.message}</p>}
                </div>
                <div className="my-4">
                    <ControlledDropdown name="useCaseComplexity" label="Complexity" error={errors.useCaseComplexity} data={useCaseComplexityOptions} required={true} />
                    {errors.useCaseComplexity && <p className="!text-red-500 mt-2">{errors.useCaseComplexity.message}</p>}
                </div>
                <div className="my-4">
                    {selectedDependencies?.length > 0 ?
                        <div className="flex justify-between border-2 rounded px-2 py-4">
                            <div>
                                <div>Dependencies</div>
                                <div className="flex gap-0.5 flex-wrap">
                                    {selectedDependencies.map((selectedDependency, index) =>
                                        <Tag key={index} type='filled' onClose={() => setSelectedDependencies(selectedDependencies.filter((dependency) => dependency !== selectedDependency))}>{dependenciesValue?.data?.filter((dependency: UseCaseDependencies) => dependency.useCaseTechnicalId === selectedDependency)?.[0]?.name}</Tag>
                                    )}
                                </div>
                            </div>
                            <div className="Appkit4-icon icon-plus-outline ap-font-16 ap-container-16 cursor-pointer pr-2" aria-hidden="true" onClick={() => setIsDependenciesModalVisible(true)}></div>
                        </div>
                        :
                        <div className="flex justify-between border-2 rounded px-2 py-4">
                            <div className="text-[#696969]">Dependencies</div>
                            <div className="Appkit4-icon icon-plus-outline ap-font-16 ap-container-16 cursor-pointer pr-2" aria-hidden="true" onClick={() => setIsDependenciesModalVisible(true)}></div>
                        </div>
                    }
                </div>
                <DependenciesSelectionModal visible={isDependenciesModalVisible} closeModal={closeDependenciesModal} dependencies={selectedDependencies} addDependencyToPayload={addDependencyToPayload} searchText={searchText}
                    options={options} handleScroll={handleScroll} handleSearch={handleSearch} />
                <div className="flex justify-end gap-2 mt-4">
                    <Button kind='secondary' onClick={cancelProject}>Cancel</Button>
                    <Button kind='primary' icon="icon-arrow-right-outline" type="submit">Review</Button>
                </div>
            </div>
        </div>
    )
}

const UseCaseDetails: React.FC<{ setActiveIndex: Function, setIsAddUseCaseOpen: Function }> = ({ setActiveIndex, setIsAddUseCaseOpen }) => {
    const [selectedDependencies, setSelectedDependencies] = useState<Array<string | number>>([]);
    const dispatch = useDispatch<AppDispatch>();
    const handleSubmit = (data: UseCaseFormValues) => {
        const useCaseFormValues = {
            ...data,
            dependencies: selectedDependencies
        }
        dispatch(setUseCaseFormValues(useCaseFormValues))
        setActiveIndex(1);
    };
    return (
        <AddUseCaseFormProvider onSubmit={handleSubmit}>
            <UseCaseDetailsComp selectedDependencies={selectedDependencies} setSelectedDependencies={setSelectedDependencies} setIsAddUseCaseOpen={setIsAddUseCaseOpen} />
        </AddUseCaseFormProvider>
    )
}
export default UseCaseDetails;