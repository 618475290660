import { Breadcrumb, BreadcrumbItem, Button } from "@appkit4/react-components";
import { selectProjectError, selectRequestDetailsForAssetData, selectSelectedAsset, selectSelectedProjectData } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import DataAssetsDetailsBusinessUser from "../../../components/project/dataAssets/DataAssetsDetailsBusinessUser";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DataAssetInfoBusinessUser from "../../../components/project/dataAssets/DataAssetInfoBusinessUser";
import { getAssetDetails, getRequestDetailsForAsset, getSurveyDetailsBU } from "../../../store/project/projectThunks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { setIsEditModeAsset } from "../../../store/project/projectSlice";
import DataAssetSurveyDetails from "../../../components/project/dataAssets/DataAssetSurveyDetails";

const DataAssetsContainerBusinessUser = () => {
    const [isViewDetailsClicked, setIsViewDetailsClicked] = useState(false);
    const [isSurveyStarted, setIsSurveyStarted] = useState(false);
    const [selectedAssetBusinessId, setSelectedAssetBusinessId] = useState("");
    const selectedProject = useSelector(selectSelectedProjectData);
    const selectedAssetRequestData = useSelector(selectRequestDetailsForAssetData);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const error = useSelector(selectProjectError); 
    const viewDetailsClicked = async (assetBusinessId: string) => {
        await dispatch(getRequestDetailsForAsset(assetBusinessId));
        setIsViewDetailsClicked(true);
        setSelectedAssetBusinessId(assetBusinessId);
    }
    const continueClicked = async (assetBusinessId: string) => {
        await dispatch(getRequestDetailsForAsset(assetBusinessId));
        await dispatch(getSurveyDetailsBU(assetBusinessId));
        await dispatch(getAssetDetails(assetBusinessId));
        setIsViewDetailsClicked(true);
        setIsSurveyStarted(true);
    }
    const selectedAsset = useSelector(selectSelectedAsset);
    useEffect(() => {
        if(selectedAssetBusinessId && selectedAssetBusinessId !== "" && !isSurveyStarted)
            dispatch(getAssetDetails(selectedAssetBusinessId));
    }, [dispatch, selectedAssetBusinessId, isSurveyStarted]);
    return (
        <div className="m-8">
            {isViewDetailsClicked ?
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Button className="px-0" kind='text' onClick={() => {
                            dispatch(setIsEditModeAsset(false));
                            navigate("/dashboard");
                        }}>Your projects</Button>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Button className="px-0" kind='text' onClick={() => {
                            dispatch(setIsEditModeAsset(false)); 
                            setIsViewDetailsClicked(false)
                        }}>{selectedProject?.name}</Button>
                    </BreadcrumbItem>
                    {isSurveyStarted ?
                        <>
                            <BreadcrumbItem>
                                <Button className="px-0" kind='text' onClick={() => setIsSurveyStarted(false)}>{selectedAsset?.assetName}</Button>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                Asset survey
                            </BreadcrumbItem>
                        </>
                        :
                        <BreadcrumbItem>
                            {selectedAsset?.assetName}
                        </BreadcrumbItem>
                    }  
                </Breadcrumb>
                :
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Button className="px-0" kind='text' onClick={() => {
                            dispatch(setIsEditModeAsset(false));
                            navigate("/dashboard")
                        }}>Your projects</Button>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {selectedProject?.name}
                    </BreadcrumbItem>
                </Breadcrumb>
            }
            {isViewDetailsClicked ? 
                 isSurveyStarted ? 
                    <DataAssetSurveyDetails setIsSurveyStarted={setIsSurveyStarted} setIsViewDetailsClicked={setIsViewDetailsClicked}/>
                    :
                    <DataAssetInfoBusinessUser selectedAssetRequestData={selectedAssetRequestData} setIsSurveyStarted={setIsSurveyStarted} />
                :
                <DataAssetsDetailsBusinessUser selectedProject={selectedProject} viewDetailsClicked={viewDetailsClicked} continueClicked={continueClicked}/>
            }
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    )
}

export default DataAssetsContainerBusinessUser;