import React from "react"
import { useSelector } from "react-redux"
import { selectSelectedAsset, selectSurveyDetails } from "../../../store/project/projectSelectors"
import dayjs from "dayjs"
import { apiLayerOptions, techValuesOptions, trainingLifeCycleOptions, yesNoOptions } from "../../../services/constants"
import { Button, Input, Radio, RadioGroup } from "@appkit4/react-components"

const TechnologyDetails: React.FC<{ setActiveIndex: Function }> = ({ setActiveIndex }) => {
    const surveyDetailsData = useSelector(selectSurveyDetails);
    const selectedAsset = useSelector(selectSelectedAsset);
    const surveyData = JSON.parse(JSON.stringify(surveyDetailsData?.surveyDetailsDTO?.surveyData));
    return (
        <div>
            <div className="flex justify-between">
                <h1 className="font-sans font-bold font-medium text-2xl py-4">Technology details</h1>
                {surveyDetailsData?.surveyDetailsDTO?.updatedAt && surveyDetailsData?.surveyDetailsDTO?.updatedAt !== "" &&
                    <div className="w-fit mr-4 mt-4">
                        <span className={`Appkit4-icon icon-update-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                        <span>Last saved at {dayjs(new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "")).format("MM/DD/YYYY")}, </span>
                        <span>{Math.floor((new Date().getTime() - new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "").getTime()) / (1000 * 60 * 60 * 24))} days ago</span>
                    </div>
                }
            </div>
            <div className="p-8 bg-white">
                {selectedAsset?.assetType === "dashboard" &&
                    <div className="my-4">
                        <div className="mt-4 mb-2">Is the dashboard showing real time data?</div>
                        <RadioGroup className="flex flex-col gap-2" value={surveyData?.technologyDetails?.dashboard?.realTimeData ? "yes" : "no"}>
                            {yesNoOptions.map((option) =>
                                <Radio value={option.value} key={option.value}>{option.label}</Radio>
                            )}
                        </RadioGroup>
                </div>
                }
                {selectedAsset?.assetType === "report" &&
                    <>
                        <div className="my-4">
                            <div className="mt-4 mb-2">What is the technology behind the report?</div>
                            <Input title="Technology" readonly value={techValuesOptions?.filter(option => option.value === surveyData?.technologyDetails?.report?.technology)?.[0]?.label} />
                        </div>
                        {surveyData?.technologyDetails?.report?.technology === "sapBusinessObject" &&
                            <div className="my-4">
                                <div className="mt-4 mb-2">If, the technology is SAP Business object which version is it?</div>
                                <Input title="Version" readonly value={surveyData?.technologyDetails?.report?.technologyVersion} />
                            </div>
                        }
                    </>
                }
                {selectedAsset?.assetType === "aiModel" &&
                    <div className="my-4">
                        <div className="mt-4 mb-2">What is the training life cycle?</div>
                        <Input title="Training life cycle" readonly value={trainingLifeCycleOptions?.filter(option => option.value === surveyData?.technologyDetails?.aiModel?.trainingLifeCycle)?.[0]?.label} />
                    </div>
                }
                {selectedAsset?.assetType === "application" &&
                    <>
                        <div className="my-4">
                            <div className="mt-4 mb-2">Does this application have an API layer?</div>
                            <RadioGroup className="flex flex-col gap-2" value={surveyData?.technologyDetails?.application?.apiLayerExists ? "yes" : "no"}>
                                {yesNoOptions.map((option) =>
                                    <Radio value={option.value} key={option.value}>{option.label}</Radio>
                                )}
                            </RadioGroup>
                        </div>
                        {!surveyData?.technologyDetails?.application?.apiLayerExists &&
                            <div className="my-4">
                                <div className="mt-4 mb-2">If no, what is the type?</div>
                                <Input title="Type" readonly value={apiLayerOptions?.filter(option=>option.value === surveyData?.technologyDetails?.application?.apiLayer)?.[0]?.label} />
                            </div>
                        }
                    </>
                }
                {selectedAsset?.assetType === "sasScript" &&
                    <>
                        <div className="my-4">
                            <div className="mt-4 mb-2">Is the SAS being exclusively used for ETL purpose?</div>
                            <RadioGroup className="flex flex-col gap-2" value={surveyData?.technologyDetails?.sasScript?.sasForETL ? "yes" : "no"}>
                                {yesNoOptions.map((option) =>
                                    <Radio value={option.value} key={option.value}>{option.label}</Radio>
                                )}
                            </RadioGroup>
                        </div>
                        {!surveyData?.technologyDetails?.sasScript?.sasForET &&
                            <div className="my-4">
                                <div className="mt-4 mb-2">If no, Please provide a reason</div>
                                <Input title="Reason" readonly value ={surveyData?.technologyDetails?.sasScript?.reason} />
                            </div>
                        }
                    </>
                }
                <div className="flex justify-end gap-2">
                    <Button kind='secondary' icon="icon-arrow-left-outline" onClick={() => setActiveIndex(1)}>Back</Button>
                    <Button kind='primary' icon="icon-arrow-right-outline" onClick={() => setActiveIndex(3)}>Next</Button>
                </div>
            </div>
        </div>
    )
}

export default TechnologyDetails;