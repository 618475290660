import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectSelectedAsset, selectSurveyDetails } from "../../../store/project/projectSelectors"
import { AssetDTO, SurveyDataDTO } from "../../../api"
import dayjs from "dayjs"
import { useFormContext } from "react-hook-form"
import { Button } from "@appkit4/react-components"
import MigrationPlanningFormProvider from "./MigrationPlanningFormProvider"
import { ControlledInput } from "../../../core/components"
import { AppDispatch } from "../../../store"
import { useDispatch } from "react-redux"
import { getSurveyDetailsBU, updateSurveyDetailsBU } from "../../../store/project/projectThunks"
import SuccesssSurvey from "./SuccessSurvey"
import SurveyReviewCommentDetails from "./SurveyReviewCommentDetails"

type MigrationPlanFormErrors = {
    downTimeWindow: string;
};

const MigrationPlanningBusinessUser: React.FC<{ setActiveIndex: Function, setIsSurveyStarted: Function, setIsViewDetailsClicked: Function }> = ({ setActiveIndex, setIsSurveyStarted, setIsViewDetailsClicked }) => {
    const [isSurveyDone, setIsSurveyDone] = useState(false);
    const selectedAsset = useSelector(selectSelectedAsset);
    const surveyDetailsData = useSelector(selectSurveyDetails);
    const dispatch = useDispatch<AppDispatch>();
    const submitMigrationPlanDetails = async (data: any) => {
        const migrationPlanning = {
            downTime: data?.downTimeWindow
        }
        await dispatch(updateSurveyDetailsBU({assetId: surveyDetailsData?.supplementaryDetails?.assetBusinessId, surveyState: "migrationPlanning", migrationPlanning: migrationPlanning}));
        await dispatch(getSurveyDetailsBU(surveyDetailsData?.supplementaryDetails?.assetBusinessId));
        setIsSurveyDone(true);
    }
    return (
        <>
            {isSurveyDone ? 
                <SuccesssSurvey setIsSurveyStarted={setIsSurveyStarted} surveyData={surveyDetailsData?.surveyDetailsDTO} setIsViewDetailsClicked={setIsViewDetailsClicked}/>
                :
                <>
                    {surveyDetailsData?.surveyDetailsDTO?.reviewComment && surveyDetailsData?.surveyDetailsDTO?.reviewComment !== "" && surveyDetailsData?.surveyDetailsDTO?.surveyStatus === "onGoing" &&
                        <SurveyReviewCommentDetails surveyData={surveyDetailsData?.surveyDetailsDTO} />
                    }
                    <MigrationPlanningFormProvider onSubmit={submitMigrationPlanDetails}>
                        <MigrationPlanningForm selectedAsset={selectedAsset} setActiveIndex={setActiveIndex} surveyDetailsData={surveyDetailsData}/>
                    </MigrationPlanningFormProvider>
                </>
            }
        </>
        
    );
}

const MigrationPlanningForm: React.FC<{ selectedAsset: AssetDTO, setActiveIndex: Function, surveyDetailsData: SurveyDataDTO }> = ({ selectedAsset, setActiveIndex, surveyDetailsData }) => {
    const [isUpdated, setIsUpdated] = useState(false);  
    const { formState: { errors } } = useFormContext<MigrationPlanFormErrors>();
    const { setValue } = useFormContext();
    useEffect(() => {
        const surveyData = JSON.parse(JSON.stringify(surveyDetailsData?.surveyDetailsDTO?.surveyData));
        setValue("downTimeWindow", surveyData?.migrationPlanning?.downTime);
        setIsUpdated(true);
    },[surveyDetailsData, setValue])
    return (
        <div>
            <div className="flex justify-between">
                <h1 className="font-sans font-bold font-medium text-2xl py-4">Migration planning</h1>
                {surveyDetailsData?.surveyDetailsDTO?.updatedAt && surveyDetailsData?.surveyDetailsDTO?.updatedAt !== "" &&
                    <div className="w-fit mr-4 mt-4">
                        <span className={`Appkit4-icon icon-update-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                        <span>Last saved at {dayjs(new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "")).format("MM/DD/YYYY")}, </span>
                        <span>{Math.floor((new Date().getTime() - new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "").getTime()) / (1000 * 60 * 60 * 24))} days ago</span>
                    </div>
                }
            </div>
            <div className="p-8 bg-white">
                <div className="my-4">
                        <div className="mt-4 mb-2">What is the acceptable downtime window for this asset?</div>
                        <ControlledInput name="downTimeWindow" type="text" label="Downtime" required={true} error={errors.downTimeWindow} />
                        {errors.downTimeWindow && <p className="text-red-500 mt-2">{errors.downTimeWindow.message}</p>}
                </div>
                <div className="flex justify-end gap-2">
                    <Button kind='secondary' icon="icon-arrow-left-outline" onClick={() => setActiveIndex(2)}>Back</Button>
                    <Button kind='primary' icon="icon-check-hook-outline" disabled={!isUpdated} type="submit">Submit survey</Button>
                </div>
            </div>
        </div>
    )
}

export default MigrationPlanningBusinessUser;