import { Button } from "@appkit4/react-components";
import backGroundImage from "../../../assets/images/SuccessProject.svg"
import dayjs from "dayjs";
import { SurveyDTO } from "../../../api";

const SuccesssSurvey: React.FC<{ setIsSurveyStarted: Function, surveyData: SurveyDTO,setIsViewDetailsClicked: Function }> = ({ setIsSurveyStarted, surveyData, setIsViewDetailsClicked }) => {
    const backgroundImageStyle = {
        backgroundImage: `url(${backGroundImage})`,
        backgroundRepeat: 'no-repeat',
        height: '13rem'
    };

    const gotoDashboard = () => {
        setIsSurveyStarted(false);
        setIsViewDetailsClicked(false);
    }

    return (
        <div>
            <div className="flex justify-between">
                <h1 className="font-sans font-bold font-medium text-2xl py-4">Survey</h1>
                <div className="w-fit mr-4 mt-4">
                    <span className={`Appkit4-icon icon-update-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                    <span>Last saved at {dayjs(new Date(surveyData.updatedAt || "")).format("MM/DD/YYYY")}, </span>
                    <span>{Math.floor((new Date().getTime() - new Date(surveyData.updatedAt || "").getTime()) / (1000 * 60 * 60 * 24))} days ago</span>
                </div>
            </div>
            <div className="px-4 py-4 bg-white">
                <div className="px-4 py-4 bg-white">
                    <div className="mb-4">
                        <div style={backgroundImageStyle} className="bg-center"></div>
                        <div className="flex flex justify-center text-center items-center">
                            <span className="text-green-500 mx-2 Appkit4-icon icon-circle-checkmark-fill ap-font-24" aria-hidden="true"></span>
                            <span>The survey was successfully completed. The survey will be sent and reviewed by the migration manager.</span>
                        </div>
                        <div className="flex justify-end">
                            <Button kind='primary' icon="icon-arrow-right-outline" onClick={gotoDashboard}>Go to asset overview</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccesssSurvey;