import { Accordion, AccordionItem, Badge, Button, Column, Input, Pagination, Select, Table, Tag } from "@appkit4/react-components";
import { ControlledDropdown, ControlledInput, ControlledTextArea } from "../../../core/components";
import AddUseCaseFormProvider from "./AddUseCaseFormProvider";
import { useFormContext } from "react-hook-form";
import { selectDependenciesValuesOptions, selectOldDependenciesValuesOptions, selectSelectedProjectData, selectSelectedUseCaseDetails, selectUseCaseAssetsList, selectUsersListByRole } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import { assetTypeOptions, dataAssetsWithPreviewMapping, itemsPerPageOptions, migrationStatusOptions, surveyStatusIndicators, useCaseComplexityOptions, useCaseSizeOptions, useCaseTypeOptions } from "../../../services/constants";
import { useEffect, useState } from "react";
import { UseCaseDependencies, UseCaseDTO } from "../../../api";
import DependenciesSelectionModal from "./DependenciesSelectionModal";
import { setOldDependenciesOptions } from "../../../store/project/projectSlice";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { getAllDependencies } from "../../../store/project/projectThunks";
import { KeycloakService } from "../../../services/KeycloakService";

type UseCaseFormErrors = {
    useCaseName: string;
    useCaseDesc: string;
    useCaseType: string;
    useCaseSize: string;
    useCaseComplexity: string;
    useCaseOwner: string;
};

const UseCaseDetailsEditComp: React.FC<{ selectedDependencies: Array<string | number>, setSelectedDependencies: Function, selectedUseCase: UseCaseDTO, setIsEditModeUseCase: Function }> =
    ({ selectedDependencies, setSelectedDependencies, selectedUseCase, setIsEditModeUseCase }) => {
        const dispatch = useDispatch<AppDispatch>();
        const selectedProject = useSelector(selectSelectedProjectData)
        const listOfUsers = useSelector(selectUsersListByRole);
        const [searchText, setSearchText] = useState("");
        const useCaseAssetsData = useSelector(selectUseCaseAssetsList);
        const [currentPage, setCurrentPage] = useState(1);
        const [selectedPageSize, setSelectedPageSize] = useState("10");
        const [activeKeys, setActiveKeys] = useState<string[]>(['1']);
        const [pageNumber, setPageNumber] = useState(1);
        const [isDependenciesModalVisible, setIsDependenciesModalVisible] = useState(false);
        const { formState: { errors } } = useFormContext<UseCaseFormErrors>();
        const { setValue } = useFormContext();
        const dependenciesValue = useSelector(selectDependenciesValuesOptions);
        const oldDependenciesOptions = useSelector(selectOldDependenciesValuesOptions);
        let options = JSON.parse(JSON.stringify(dependenciesValue?.data))
        if (dependenciesValue && dependenciesValue.data && dependenciesValue?.data?.length > 0 && oldDependenciesOptions?.length > 0) {
            options = [...oldDependenciesOptions, ...options];
        }
        let updatedOptions = options.filter((option: UseCaseDependencies) => option.useCaseTechnicalId !== selectedUseCase?.useCaseTechnicalId);
        const listOfBusinessUsers = listOfUsers.filter(list => list.role === "BusinessUser")?.[0]?.users.map((user:any) => {return{value: user.email, label: user.email}})
        const loggedInUserRole = KeycloakService?.getRoles().includes("migration-manager") ? "manager" : "businessUser";

        const onClickAccordion = (activeKeys: string[]) => {
            setActiveKeys(activeKeys);
        }
        const onPageChange = (page: number) => {
            setCurrentPage(page);
        }
        const handleScroll = (event: any) => {
            const target = event?.target;
            if (target.scrollHeight - target.scrollTop === target.clientHeight && dependenciesValue?.data?.length === 10) {
                dispatch(setOldDependenciesOptions(dependenciesValue?.data))
                setPageNumber(pageNumber + 1)
            }
        }

        const handleSearch = (e: string) => {
            dispatch(setOldDependenciesOptions([]));
            setPageNumber(0);
            setSearchText(e);
        }

        useEffect(() => {
            dispatch(getAllDependencies({ pageNumber: pageNumber, searchString: searchText, pageSize: '10', projectBusinessId: selectedProject.projectBusinessId }));
        }, [dispatch, searchText, pageNumber, selectedProject]);

        const closeDependenciesModal = () => {
            setIsDependenciesModalVisible(false)
        }

        const addDependencyToPayload = (dependencies: Array<string | number>) => {
            setSelectedDependencies(dependencies);
            setIsDependenciesModalVisible(false);
        }

        const cancelUseCaseEdit = () => {
            setIsEditModeUseCase(false);
        }

        useEffect(() => {
            setValue("useCaseName", selectedUseCase?.name || "");
            setValue("useCaseDesc", selectedUseCase?.description || "");
            setValue("useCaseOwner", selectedUseCase?.owner)
            setValue("useCaseType", selectedUseCase?.type);
            setValue("useCaseSize", selectedUseCase?.size);
            setValue("useCaseComplexity", selectedUseCase?.complexity || "");
            setValue("useCaseComment", selectedUseCase?.comment || "");
        }, [setValue, selectedUseCase]);

        return (
            <div className="p-6 bg-white">
                <h1 className="font-sans font-medium text-xl">Use case details</h1>
                <div className="my-4">
                    <ControlledInput name="useCaseName" required={true} label="Name" error={errors.useCaseName} />
                    {errors.useCaseName && <p className="text-red-500 mt-2">{errors.useCaseName.message}</p>}
                </div>
                <div className="my-4">
                    <ControlledTextArea name="useCaseDesc" required={true} label="Description" error={errors.useCaseDesc} />
                    {errors.useCaseDesc && <p className="text-red-500 mt-2">{errors.useCaseDesc.message}</p>}
                </div>
                <div className="my-4">
                    {loggedInUserRole === "manager" ?
                        <>
                            <ControlledDropdown name="useCaseOwner" label="Owner" error={errors.useCaseOwner} data={listOfBusinessUsers} required={true} />
                            {errors.useCaseOwner && <p className="!text-red-500 mt-2">{errors.useCaseOwner.message}</p>}
                        </>
                        :
                        <Input readonly title="Owner" value={selectedUseCase?.owner} />
                    }
                    
                </div>
                <div className="my-4">
                    <ControlledDropdown name="useCaseType" label="Type" error={errors.useCaseType} data={useCaseTypeOptions} required={true} />
                    {errors.useCaseType && <p className="!text-red-500 mt-2">{errors.useCaseType.message}</p>}
                </div>
                <div className="my-4">
                    <ControlledDropdown name="useCaseSize" label="Size" error={errors.useCaseSize} data={useCaseSizeOptions} required={true} />
                    {errors.useCaseSize && <p className="!text-red-500 mt-2">{errors.useCaseSize.message}</p>}
                </div>
                <div className="my-4">
                    <ControlledDropdown name="useCaseComplexity" label="Complexity" error={errors.useCaseComplexity} data={useCaseComplexityOptions} required={true} />
                    {errors.useCaseComplexity && <p className="!text-red-500 mt-2">{errors.useCaseComplexity.message}</p>}
                </div>
                <div className="my-4">
                    {selectedDependencies?.length > 0 ?
                        <div className="flex justify-between border-2 rounded px-2 py-4">
                            <div>
                                <div>Dependencies</div>
                                <div className="flex gap-0.5 flex-wrap">
                                    {selectedDependencies.map((selectedDependency, index) =>
                                        <Tag key={index} type='filled' onClose={() => setSelectedDependencies(selectedDependencies.filter((dependency) => dependency !== selectedDependency))}>{dependenciesValue?.data?.filter((dependency: UseCaseDependencies) => dependency.useCaseTechnicalId === selectedDependency)?.[0]?.name}</Tag>
                                    )}
                                </div>
                            </div>
                            <div className="Appkit4-icon icon-plus-outline ap-font-16 ap-container-16 cursor-pointer pr-2" aria-hidden="true" onClick={() => setIsDependenciesModalVisible(true)}></div>
                        </div>
                        :
                        <div className="flex justify-between border-2 rounded px-2 py-4">
                            <div className="text-[#696969]">Dependencies</div>
                            <div className="Appkit4-icon icon-plus-outline ap-font-16 ap-container-16 cursor-pointer pr-2" aria-hidden="true" onClick={() => setIsDependenciesModalVisible(true)}></div>
                        </div>
                    }
                </div>
                <DependenciesSelectionModal visible={isDependenciesModalVisible} closeModal={closeDependenciesModal} dependencies={selectedDependencies} addDependencyToPayload={addDependencyToPayload} searchText={searchText}
                    options={updatedOptions} handleScroll={handleScroll} handleSearch={handleSearch} />
                <div className="my-4">
                    <Input readonly title="Migration status" value={migrationStatusOptions.filter(option => option.value === selectedUseCase?.migrationStatus)?.[0]?.label} />
                </div>
                <Accordion multiple={false} onClick={onClickAccordion} activeKeys={activeKeys}>
                    <AccordionItem title={"Related data assets"} itemKey="1" className="my-6 bg-white hover:bg-white">
                        <div className="my-4">
                            <Input readonly title="Assets" value={selectedUseCase?.asset} />
                        </div>
                        {useCaseAssetsData && useCaseAssetsData.data && useCaseAssetsData.data.length > 0 ?
                            <Table className="shadow-none border" originalData={useCaseAssetsData?.data ? JSON.parse(JSON.stringify(useCaseAssetsData?.data)) : []} hasTitle>
                                <Column field='assetType' sortKey='assetType' renderCell={
                                    (row) => {
                                        return (
                                            <>
                                                <span className={`Appkit4-icon icon-${dataAssetsWithPreviewMapping.filter(dataAsset => dataAsset.name === row.assetType)[0]?.prefixIcon}-outline ap-font-24 px-4`} aria-hidden="true"></span>
                                                <span className="pl-2">{assetTypeOptions.filter(option => option.value === row.assetType)[0]?.label}</span>
                                            </>
                                        )
                                    }
                                }>Type</Column>
                                <Column field='assetName' sortKey='assetName'>Name</Column>
                                <Column field='owner' sortKey='owner'>Owner</Column>
                                <Column field='surveyStatus' sortKey='surveyStatus' renderCell={(row) => {
                                    const selectedStatus = surveyStatusIndicators.filter(statusDetail => statusDetail.status === row.surveyStatus)[0]
                                    return (
                                        !row.assetActiveStatus ?
                                            <div className="ml-12"> --- </div>
                                            :
                                            <div>
                                                <Badge type={selectedStatus?.statusIndicator} value={selectedStatus?.label} />
                                            </div>
                                    )
                                }}>Survey status</Column>
                                <Column field='migrationStatus' sortKey='migrationStatus' renderCell={(row) => migrationStatusOptions?.filter(option => option.value === row.migrationStatus)?.[0].label}>Migration status</Column>
                            </Table>
                            :
                            <div>There is no data to display</div>
                        }
                        <div className="flex justify-between">
                            <div className="flex mt-4 gap-2 items-center">
                                <span>Show</span>
                                <Select className="!w-20"
                                    placeholder="drop"
                                    hideTitleOnInput={true}
                                    data={itemsPerPageOptions}
                                    value={selectedPageSize}
                                    onSelect={(e) => setSelectedPageSize(e.toString())}
                                    dropdownAlwaysDown={false}
                                >
                                </Select>
                                <span>items per page</span>
                            </div>
                            <Pagination current={currentPage} total={useCaseAssetsData?.totalPages || 1} onPageChange={onPageChange}></Pagination>
                        </div>
                    </AccordionItem>
                </Accordion>
                <div>
                    <h1 className="font-sans font-medium text-xl mt-4">Comment</h1>
                    <h3 className="mb-4">Please use this field to provide any additional context, feedback, or specific instructions related to the use case.  </h3>
                    <ControlledTextArea name="useCaseComment" label="Comments" />
                </div>
                <div className="flex justify-end gap-2 mt-4">
                    <Button kind='secondary' onClick={cancelUseCaseEdit}>Cancel</Button>
                    <Button kind='primary' icon="icon-arrow-right-outline" type="submit">Submit change</Button>
                </div>
            </div>
        )
    }

const EditUseCaseData: React.FC<{ editUseCase: Function, setIsEditModeUseCase: Function }> = ({ editUseCase, setIsEditModeUseCase }) => {
    const selectedUseCase = useSelector(selectSelectedUseCaseDetails);
    let dependencies: Array<string | number> = [];
    if(selectedUseCase && selectedUseCase.dependencies && selectedUseCase.dependencies.length > 0)
        dependencies = selectedUseCase?.dependencies?.map(dependencies => dependencies.useCaseTechnicalId || '')
    const [selectedDependencies, setSelectedDependencies] = useState<Array<string | number>>(dependencies || []);
    const handleEdit = (data: any) => {
        const useCaseFormValues = {
            ...data,
            dependencies: selectedDependencies
        }
        editUseCase(useCaseFormValues)
    };
    useEffect(() => {
        let dependencies: Array<string | number> = [];
        if(selectedUseCase && selectedUseCase.dependencies && selectedUseCase.dependencies.length > 0)
            dependencies = selectedUseCase?.dependencies?.map(dependencies => dependencies.useCaseTechnicalId || '')
        setSelectedDependencies(dependencies)
    }, [selectedUseCase, selectedUseCase.dependencies])
    return (
        <AddUseCaseFormProvider onSubmit={handleEdit}>
            <UseCaseDetailsEditComp selectedDependencies={selectedDependencies} setSelectedDependencies={setSelectedDependencies} selectedUseCase={selectedUseCase} setIsEditModeUseCase={setIsEditModeUseCase} />
        </AddUseCaseFormProvider>
    )
}

export default EditUseCaseData;