import { useSelector } from "react-redux";
import { selectDependenciesValuesOptions, selectSelectedProjectData, selectUseCaseFormValues } from "../../../store/project/projectSelectors";
import { useCaseComplexityOptions, useCaseFormKeyValues, useCaseSizeOptions, useCaseTypeOptions } from "../../../services/constants";
import { Button, Input } from "@appkit4/react-components";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { addNewUseCase } from "../../../store/project/projectThunks";

const ReviewUseCase: React.FC<{ setActiveIndex: Function, setIsAddUseCaseOpen: Function }> = ({ setActiveIndex, setIsAddUseCaseOpen }) => {
    const [addComment, setAddComment] = useState("");
    const dispatch = useDispatch<AppDispatch>();
    const selectedProject = useSelector(selectSelectedProjectData);
    const useCaseFormValues = useSelector(selectUseCaseFormValues);
    const dependenciesValue = useSelector(selectDependenciesValuesOptions);
    let dependencyValues: Array<any> = [];
    dependencyValues.push(useCaseFormValues.dependencies.map((selectedDependency) =>
        dependenciesValue?.data?.filter((dependency) => dependency.useCaseTechnicalId === selectedDependency)?.[0]?.name
    ));
    const createUseCase = async () => {
        const payload = {
            name: useCaseFormValues?.useCaseName,
            description: useCaseFormValues?.useCaseDesc,
            type: useCaseFormValues?.useCaseType,
            size: useCaseFormValues?.useCaseSize,
            complexity: useCaseFormValues?.useCaseComplexity,
            owner: useCaseFormValues?.useCaseOwner,
            dependencies: useCaseFormValues?.dependencies,
            comment: addComment,
            projectBusinessId: selectedProject?.projectBusinessId
        }
        await dispatch(addNewUseCase(payload))
        setIsAddUseCaseOpen(false);
    }
    return (
        <div className='flex-1 bg-[#F3F3F3] mt-4'>
            <h1 className="font-sans font-medium text-xl mt-4">Add use case</h1>
            <h3 className="mb-4">To add a new use case, please ensure you fill in all the mandatory fields. The content of the use cases will be based on the information provided in the asset surveys.</h3>
            <div className="p-6 bg-white">
                <div className="py-4">
                    <h1 className="font-sans font-medium text-xl mb-2">Detail review</h1>
                    {Object.entries(useCaseFormValues).map(([key, value]) => (
                        <div className="flex justify-between border-b-2 p-2" key={key}>
                            {key === "dependencies" ?
                                <>
                                    <div>{useCaseFormKeyValues?.filter(keyLabel => keyLabel.value === key)[0].label}</div>
                                    <div>{`${dependencyValues}`}</div>
                                </>
                                :
                                key === "useCaseType" ?
                                    <>
                                        <div>{useCaseFormKeyValues?.filter(keyLabel => keyLabel.value === key)[0].label}</div>
                                        <div>{useCaseTypeOptions?.filter(option => option.value === value)[0].label}</div>
                                    </>
                                    :
                                    key === "useCaseSize" ?
                                        <>
                                            <div>{useCaseFormKeyValues?.filter(keyLabel => keyLabel.value === key)[0].label}</div>
                                            <div>{useCaseSizeOptions?.filter(option => option.value === value)[0].label}</div>
                                        </>
                                        :
                                        key === "useCaseComplexity" ?
                                            <>
                                                <div>{useCaseFormKeyValues?.filter(keyLabel => keyLabel.value === key)[0].label}</div>
                                                <div>{useCaseComplexityOptions?.filter(option => option.value === value)[0].label}</div>
                                            </>
                                            :
                                            <>
                                                <div>{useCaseFormKeyValues?.filter(keyLabel => keyLabel.value === key)[0].label}</div>
                                                <div>{value}</div>
                                            </>
                            }
                        </div>
                    ))}
                </div>
                <div className="py-4">
                    <h1 className="font-sans font-medium text-xl mt-4">Comment</h1>
                    <h3 className="mb-4">Please use this field to provide any additional context, feedback, or specific instructions related to the use case. </h3>
                    <Input value={addComment} onChange={setAddComment} title="Additional comments" />
                </div>
                <div className="flex justify-end gap-2 mt-4">
                    <Button kind='secondary' onClick={() => setActiveIndex(0)}>Back</Button>
                    <Button kind='primary' icon="icon-plus-outline" onClick={createUseCase}>Add use case</Button>
                </div>
            </div>
        </div>
    )
}

export default ReviewUseCase;