import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

type UseCaseFormValues = {
  useCaseName: string;
  useCaseDesc: string;
  useCaseOwner: string;
  useCaseType: string;
  useCaseSize: string;
  useCaseComplexity: string;
  useCaseDependency: string;
  useCaseComment?: string;
};

type UseCaseFormProviderProps = {
  onSubmit: SubmitHandler<UseCaseFormValues>;
  children: React.ReactNode;
};

const AddUseCaseFormProvider: React.FC<UseCaseFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<UseCaseFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default AddUseCaseFormProvider;
