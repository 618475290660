import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

type TechnologyDetailsFormValues = {
  isDashboardshowRealData?: string;
  technology?: string;
  technologyVersion?: string;
  trainingLifeCycle?: string;
  hasAPILayer?: string;
  APILayerType?: string;
  isUsedForETL?: string;
  reasonForNotUseETL?: string
};

type TechnologyDetailsFormProviderProps = {
  onSubmit: SubmitHandler<TechnologyDetailsFormValues>;
  children: React.ReactNode;
};

const TechnologyDetailsFormProvider: React.FC<TechnologyDetailsFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<TechnologyDetailsFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default TechnologyDetailsFormProvider;