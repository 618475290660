import { Button, Loading, Panel, Column, Table, Badge, Accordion, AccordionItem } from '@appkit4/react-components';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { getProjectsList, getScopeData, getSurveyListData, getSurveyOverviewData, getUseCaseList } from '../../../store/project/projectThunks';
import { selectNewAssetRequestsList, selectProjectError, selectProjectLoading, selectScopeDataOverView, selectSelectedProjectData, selectSurveyListingData, selectSurveyOverviewData, selectUseCaseListData, selectUserEmailsForProject } from '../../../store/project/projectSelectors';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrgName, removeDuplicates } from '../../../core/util/utility';
import { useCallback, useEffect, useState } from 'react';
import { setUserEmailsForProject } from '../../../store/project/projectSlice';
import { assetTypeOptions, dataAssetsWithPreviewMapping, environmentOptions, migrationStatusOptions, requestTypeMapping, scopeOverviewKeyLabel, surveyStatusIndicators, useCaseComplexityOptions, useCaseSizeOptions, useCaseTypeOptions } from '../../../services/constants';
import dayjs from 'dayjs';

const ProjectDetailsContainerManager = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { projectId } = useParams();
    const navigate = useNavigate();
    const selectedProject = useSelector(selectSelectedProjectData);
    const useCaseListData = useSelector(selectUseCaseListData);
    const surveyListingData = useSelector(selectSurveyListingData);
    const surveyOverviewData = useSelector(selectSurveyOverviewData);
    const loading = useSelector(selectProjectLoading);
    const error = useSelector(selectProjectError);
    const scopeOverviewData = useSelector(selectScopeDataOverView);
    const [activeKeys, setActiveKeys] = useState<string[]>(["1", "2", "3", "4"]);
    const onClickAccordion = (activeKeys: string[]) => {
        setActiveKeys(activeKeys);
    }
    const getEmailsList = useCallback(() => {
        const emailsArray = [];
        const createEmailsArray: Array<any> = [];
        selectedProject.managerList?.forEach((email: string) => {
            const arrayElement = { email: email, company: getOrgName(email), role: "migration-manager" };
            createEmailsArray.push(arrayElement);
        });
        selectedProject.adminList?.forEach((email: string) => {
            const arrayElement = { email: email, company: getOrgName(email), role: "admin" };
            createEmailsArray.push(arrayElement);
        });
        selectedProject.businessUserList?.forEach((email: string) => {
            const arrayElement = { email: email, company: getOrgName(email), role: "business-user" };
            createEmailsArray.push(arrayElement);
        });
        emailsArray.push(...createEmailsArray);
        const updatedArrayList = removeDuplicates(emailsArray, 'email');
        return updatedArrayList;
    }, [selectedProject]);
    const allNewAssetRequestsList = useSelector(selectNewAssetRequestsList);
    const newRequestsData = allNewAssetRequestsList && allNewAssetRequestsList?.data ? JSON.parse(JSON.stringify(allNewAssetRequestsList?.data)) : [];
    const dataAssetsData = [
        {
            status: "Completed",
            numberOfSurvey: surveyOverviewData?.complete,
            percentage: surveyOverviewData?.completePercentage
        },
        {
            status: "Ongoing",
            numberOfSurvey: surveyOverviewData?.onGoing,
            percentage: surveyOverviewData?.onGoingPercentage
        },
        {
            status: "Not Started",
            numberOfSurvey: surveyOverviewData?.notStarted,
            percentage: surveyOverviewData?.notStartedPercentage
        },
    ]
    useEffect(() => {
        if(projectId && projectId !== ""){
            dispatch(getScopeData(projectId));
            dispatch(getUseCaseList({ pageNumber: 1, pageSize: "5", searchString: "", projectBusinessId: projectId }));
            dispatch(getSurveyListData({pageNumber: 1, pageSize: 3, projectBusinessId: projectId, surveyStatus: "inReview"}))
            dispatch(getSurveyOverviewData(projectId));
        }
        dispatch(setUserEmailsForProject(getEmailsList()));
    }, [dispatch, getEmailsList, projectId, selectedProject]);
    
    const tableData = JSON.parse(JSON.stringify(useSelector(selectUserEmailsForProject)));

    if (loading) {
        return (
            <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
        )
    }
    return (
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        dispatch(getProjectsList());
                        navigate("/dashboard")
                    }}>All Projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    {selectedProject?.name}
                </BreadcrumbItem>
            </Breadcrumb>
            <h1 className="font-sans font-medium text-xl mt-4">{selectedProject?.name}</h1>
            <div className="text-[#696969] mb-4">{selectedProject?.description}</div>   
            <div className='my-4 flex flex-row gap-4'>
                <Panel title={"Scope overview"} className="flex-1">
                    <div className="flex flex-row border-b-2 justify-between">
                        <div className="py-4">
                            <span className={`ap-font-24 pr-2`} aria-hidden="true">{scopeOverviewData?.totalAssets}</span>
                            <span>data assets in total</span>
                        </div> 
                        <Button className="mb-6" kind="secondary" icon="icon-arrow-right-outline" onClick={() => navigate(`/project/${selectedProject?.projectBusinessId}/dataAsset`)}>Details</Button>
                    </div>
                    {Object.entries(scopeOverviewData).map(([key, value]) => (
                        key === "lastUpdated" && 
                            <div className="flex justify-between border-b-2 p-2" key={key}>
                                <div>{scopeOverviewKeyLabel?.filter(keyLabel=>keyLabel.value === key)[0].label}</div>
                                <div>{value ? dayjs(new Date(value)).format("MM/DD/YYYY") : "NA"}</div>
                            </div>
                    ))}
                    {Object.entries(scopeOverviewData).map(([key, value]) => (
                        key !== "totalAssets"  && key !== "lastUpdated" &&
                            <div className="flex justify-between border-b-2 p-2" key={key}>
                                <div>{scopeOverviewKeyLabel?.filter(keyLabel=>keyLabel.value === key)[0].label}</div>
                                <div>{value}</div>
                            </div>
                    ))}
                </Panel>
                <Panel title={<div className="min-h-7"><Badge className="px-2 py-1 mr-2" value={newRequestsData?.length || 0}  type={'primary'} /><span>New requests</span></div>} className="flex-1">
                    <div className="flex flex-row justify-between">
                        <div className="pt-3">Request Management</div> 
                        <Button className="mb-6" kind="secondary" icon="icon-arrow-right-outline" onClick={() => navigate(`/project/${selectedProject?.projectBusinessId}/requests`)}>See all</Button>
                    </div>
                    {newRequestsData?.length > 0 ?
                        <Table className="bg-[#F3F3F3]" originalData={JSON.parse(JSON.stringify(newRequestsData))} hasTitle>
                            <Column field='requestBy' sortKey='requestBy'>User</Column>
                            <Column field='requestType' sortKey='requestType' renderCell={(row) => <span>{requestTypeMapping.filter(type => type.value === row.requestType)[0]?.label}</span>}>Request Type</Column>
                        </Table>
                        :
                        <div className="my-4"> No requests found </div>
                    }  
                </Panel>
            </div>
            <Accordion multiple={true} onClick={onClickAccordion} activeKeys={activeKeys}>
                <AccordionItem templateHeader={() => <div className="text-xl ml-4">Survey overview</div>} itemKey="1" className="flex-1 mt-6 bg-white hover:bg-white">
                    <div className="flex justify-between m-4">
                        <div>
                            <span className='text-2xl'>{surveyOverviewData?.numberOfSurvey}</span> surveys in total and <span className='text-2xl'>{surveyOverviewData?.inReview}</span> waiting to be reviewed
                        </div>
                        <Button kind='secondary' icon="icon-arrow-right-small-outline" onClick={() => navigate(`/project/${selectedProject?.projectBusinessId}/survey`)}>See all</Button>
                    </div>
                    <div className="flex justify-between m-4">
                        {dataAssetsData.map((dataAsset, index)=> 
                            <div className="w-1/3 my-4 mx-4" key={index}>
                                <Panel className="bg-gray-100 p-4">
                                    <div className="font-medium text-xl flex flex-row my-4 mx-2">
                                        <Badge className="px-4 mt-5 mr-2"  type={dataAsset.status === "Completed" ? 'success' : dataAsset.status === "Ongoing" ? "warning" : "info-outlined"} />
                                        <div className='mt-4'>{dataAsset.status}</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div>
                                            <div className="ml-2 text-6xl">{dataAsset.numberOfSurvey}</div>
                                            <div className="ml-2">Number of Survey</div>
                                        </div>
                                        <div>
                                            <div className="ml-2 text-6xl">{dataAsset.percentage}%</div>
                                            <div className="ml-2">Percentage</div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                        )}
                    </div>
                    <div className="text-xl text-black m-4">Surveys to be reviewed</div>
                    {surveyListingData?.data && surveyListingData?.data?.length > 0 &&
                        <Table originalData={JSON.parse(JSON.stringify(surveyListingData?.data))} hasTitle className="bg-[#F3F3F3] m-4">
                            <Column field='assetType' sortKey='assetType' renderCell={
                                (row) => {
                                    return (
                                        <>
                                            <span className={`Appkit4-icon icon-${dataAssetsWithPreviewMapping.filter(dataAsset => dataAsset.name === row.assetType)[0]?.prefixIcon}-outline ap-font-24 px-4`} aria-hidden="true"></span>
                                            <span className="pl-2">{assetTypeOptions.filter(option => option.value === row.assetType)[0]?.label}</span>
                                        </>
                                    )
                                }
                            }>Type</Column>
                            <Column field='assetName' sortKey='assetName'>Name</Column>
                            <Column field='environment' sortKey='environment' renderCell={(row) => environmentOptions.filter(option => option.value === row.environment)?.[0]?.label}>Environment</Column>
                            <Column field='owner' sortKey='owner'>Owner</Column>
                            <Column field='surveyStatus' renderCell={(row) => {
                                const selectedStatus = surveyStatusIndicators.filter(statusDetail => statusDetail.status === row.surveyStatus)[0]
                                return <Badge type={selectedStatus?.statusIndicator} value={selectedStatus?.label} />
                            }}>Survey status</Column>
                            <Column field='wave' sortKey='wave'>Wave</Column>
                            <Column field='migrationStatus' sortKey='migrationStatus' renderCell={(row) => migrationStatusOptions.filter(option=> option.value === row.migrationStatus)?.[0]?.label}>Migration status</Column>
                        </Table>
                    }
                </AccordionItem> 
                <AccordionItem templateHeader={() => <div className="text-xl ml-4">Use cases</div>} itemKey="2" className="flex-1 mt-6 bg-white hover:bg-white">
                    <div className="flex justify-between m-4">
                        <div>
                            <span className='text-2xl'>{useCaseListData?.totalItems}</span> use cases in total
                        </div>
                        <Button kind='secondary' icon="icon-arrow-right-small-outline" onClick={() => navigate(`/project/${selectedProject?.projectBusinessId}/useCase`)}>See all</Button>
                    </div>
                    <div className="mx-4">
                        {useCaseListData && useCaseListData.data && useCaseListData.data.length > 0 ?
                            <Table className="shadow-none" originalData={useCaseListData?.data ? JSON.parse(JSON.stringify(useCaseListData?.data)) : []} hasTitle>
                                <Column field='name' sortKey='name'>Name</Column>
                                <Column field='owner' sortKey='owner'>Owner</Column>
                                <Column field='type' sortKey='type' renderCell={(row) => <span>{useCaseTypeOptions.filter(type => type.value === row.type)[0]?.label}</span>}>Type</Column>
                                <Column field='size' sortKey='size' renderCell={(row) => <span>{useCaseSizeOptions.filter(size => size.value === row.size)[0]?.label}</span>}>Size</Column>
                                <Column field='complexity' sortKey='complexity' renderCell={(row) => <span>{useCaseComplexityOptions.filter(comp => comp.value === row.complexity)[0]?.label}</span>}>complexity</Column>
                                <Column field='asset' sortKey='asset'>Asset</Column>
                            </Table>
                            :
                            <div>There is no data to display</div>
                        }
                    </div>
                </AccordionItem>
                <AccordionItem templateHeader={() => <div className="text-xl ml-4">Wave management</div>} itemKey="3" className="flex-1 mt-6 bg-white hover:bg-white">
                    <div className="flex justify-between m-4">No wave data found</div>
                    {/* <div className="flex justify-between m-4">
                        <div>
                            <span className='text-2xl'>{30}</span> waves in total
                        </div>
                        <Button kind='secondary' icon="icon-arrow-right-small-outline" onClick={() => console.log("see all click for use cases")}>See all</Button>
                    </div>
                    {wavesMockData?.length > 0 &&
                        <Table originalData={wavesMockData} hasTitle className="bg-[#F3F3F3] m-4">
                            <Column field='name' sortKey='name'>Name</Column>
                            <Column field='owner' sortKey='owner'>Owner</Column>
                            <Column field='status' sortKey='status' renderCell={(row) => <Badge className="mb-6" value={row.status} type={row.status === "Ongoing" ? "warning" : row.status === "Completed" ? "success" : "primary"} />}>Status</Column>
                            <Column field='startDate' sortKey='startDate'>Start Date</Column>
                            <Column field='endDate' sortKey='endDate'>End Date</Column>
                        </Table>
                    } */}
                </AccordionItem>
                <AccordionItem templateHeader={() => <div className="text-xl ml-4">Team</div>} itemKey="4" className="flex-1 mt-6 bg-white hover:bg-white">
                    <div className="flex justify-between m-4">
                        <div>
                            <span className='text-2xl'>{(selectedProject?.adminList?.length || 0) + (selectedProject?.managerList?.length || 0) + (selectedProject?.businessUserList?.length || 0)}</span> members
                        </div>
                        <Button kind='secondary' icon="icon-arrow-right-small-outline" onClick={() => navigate(`/project/${selectedProject?.projectBusinessId}/users`)}>See all</Button>
                    </div>
                    {tableData?.length > 0 &&
                        <Table originalData={tableData} hasTitle className="bg-[#F3F3F3] m-4">
                            <Column field='email' sortKey='email'>Email</Column>
                            <Column field='role' renderCell={(row: any) => <span>{row.role === "admin" ? "Admin" : row.role === "migration-manager" ? "Manager" : "Business User"}</span>} sortKey='role'>Role</Column>
                        </Table>
                    }
                </AccordionItem>
            </Accordion>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default ProjectDetailsContainerManager;