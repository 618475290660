import { Button, Loading } from '@appkit4/react-components';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { actionOnAssetRequest, getAllAssetRequests, getAssetDetails, getProjectsList, viewAssetRequests } from '../../../store/project/projectThunks';
import { selectNewAssetRequestsList, selectPendingAssetRequestsList, selectProjectError, selectProjectLoading, selectSelectedProjectData } from '../../../store/project/projectSelectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AssetRequestReviewManager, AssetRequestsSectionManager, RequestHistoryListManager } from '../../../components/project';
import { useEffect, useState } from 'react';
import { ActionOnRequest, RequestDTO, ReviewedRequest } from '../../../api';

const ProjectAssetRequestContainerManager = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const selectedProject = useSelector(selectSelectedProjectData);
    const loading = useSelector(selectProjectLoading);
    const error = useSelector(selectProjectError);
    const [assetClicked, setAssetClicked] = useState(false);
    const [currentPending, setCurrentPending] = useState(1);
    const [selectedValuePending, setSelectedValuePending] = useState("10");
    const [currentNew, setCurrentNew] = useState(1);
    const [selectedValueNew, setSelectedValueNew] = useState("10");
    const [currentHistoryPage, setCurrentHistoryPage] = useState(1);
    const [selectedValueHistoryPage, setSelectedValueHistoryPage] = useState("10");
    const newRequestsList = useSelector(selectNewAssetRequestsList);
    const pendingRequestsList = useSelector(selectPendingAssetRequestsList);
    const [reviewType, setReviewType] = useState("");
    const [selectedAssetReqeust, setSelectedAssetRequest] = useState<RequestDTO>();
    const [isRequestDetailsShow, setIsRequestDetailsShow] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState<RequestDTO>();
    
    const onPageChangePending = (page: number) => {
        setCurrentPending(page);
    }
    const onPageChangeNew = (page: number) => {
        setCurrentNew(page)
    }
    const onPageChangeRequests = (page: number) => {
        setCurrentHistoryPage(page);
    }

    const resultHistoryClicked = () => {
        setAssetClicked(false)
        setReviewType("request_history");
    }

    const reviewAsset = (row: RequestDTO) => {
        setAssetClicked(true)
        setSelectedAssetRequest(row);
        setReviewType(row.requestType);
        dispatch(getAssetDetails(row?.assetBusinessId));
    }

    const gotoAllRequest = () => {
        setAssetClicked(false);
        setReviewType("");
    }

    useEffect(() => {
        if(reviewType === "request_history")
            dispatch(getAllAssetRequests({pageSize: selectedValueHistoryPage, pageNumber: currentHistoryPage.toString(), requestState: "requestHistory", projectBusinessId: selectedProject?.projectBusinessId}));
    }, [dispatch, selectedValueHistoryPage, currentHistoryPage, reviewType, selectedProject]);

    useEffect(() => {
        dispatch(getAllAssetRequests({pageSize: selectedValueNew, pageNumber: currentNew.toString(), requestState: "newRequests", projectBusinessId: selectedProject?.projectBusinessId}));
    }, [dispatch, currentNew, selectedValueNew, selectedProject]);
    useEffect(() => {
        dispatch(getAllAssetRequests({pageSize: selectedValuePending, pageNumber: currentPending.toString(), requestState: "pendingRequests", projectBusinessId: selectedProject?.projectBusinessId}))
    }, [dispatch, currentPending, selectedValuePending, selectedProject]);

    const clearAllNewReq = async() => {
        let payload: Array<ReviewedRequest> = [];
        newRequestsList?.data?.forEach((request) => { payload.push({requestTechnicalId: request?.requestTechnicalId, requestBusinessId: request?.requestBusinessId}) });
        await dispatch(viewAssetRequests(payload));
        refreshDataOnRequestPage();
    }
    
    if (loading) {
        return (
            <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
        )
    }

    const refreshDataOnRequestPage = () => {
        dispatch(getAllAssetRequests({pageSize: selectedValueNew, pageNumber: currentNew.toString(), requestState: "newRequests", projectBusinessId: selectedProject?.projectBusinessId}));
        dispatch(getAllAssetRequests({pageSize: selectedValuePending, pageNumber: currentPending.toString(), requestState: "pendingRequests", projectBusinessId: selectedProject?.projectBusinessId}))
    }

    const actionOnRequest = async(requestPayload: ActionOnRequest) => {
        await dispatch(actionOnAssetRequest(requestPayload));
        refreshDataOnRequestPage();
        gotoAllRequest();

    }

    return (
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        dispatch(getProjectsList());
                        navigate("/dashboard")
                    }}>All Projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        navigate(`/project/${selectedProject?.projectBusinessId}`);
                    }}>{selectedProject?.name}</Button>
                </BreadcrumbItem>
                {assetClicked ? 
                    <>
                        <BreadcrumbItem>
                            <Button className="px-0" kind='text' onClick={() => {setIsRequestDetailsShow(false); setAssetClicked(false)}}>Request management</Button>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {selectedAssetReqeust?.assetName}
                        </BreadcrumbItem>
                    </>
                    :
                    reviewType === "request_history" ?
                        <>
                            <BreadcrumbItem>
                                <Button className="px-0" kind='text' onClick={() => {setIsRequestDetailsShow(false); setAssetClicked(false); setReviewType("")}}>Request management</Button>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                {isRequestDetailsShow ? selectedRequest?.assetName : "Request history"}
                            </BreadcrumbItem>
                        </>
                        :
                        <BreadcrumbItem>
                            Request management
                        </BreadcrumbItem>
                }
            </Breadcrumb>
            {assetClicked ? 
                <>
                    <div className="my-4 flex justify-between">
                        <h1 className="font-sans font-medium text-xl">Request</h1>
                        <Button kind="primary" icon="icon-email-outline" onClick={gotoAllRequest}>All requests</Button>
                    </div>
                    <AssetRequestReviewManager reviewType={reviewType} selectedAssetReqeust={selectedAssetReqeust} actionOnRequest={actionOnRequest}/>
                </>
                :
                <>
                    {!isRequestDetailsShow &&
                        <div className="flex justify-between">
                            <div>
                                <h1 className="font-sans font-medium text-xl my-4">Request management</h1>
                                <div className="text-[#696969] mb-4">Review all incoming and previous requests regarding this project. Here, you can approve new assets, review submitted asset surveys, and approve the removal of assets to ensure a smooth and efficient migration process. </div> 
                            </div>
                            {reviewType === "request_history" &&
                                <Button kind="primary" icon="icon-email-outline" onClick={gotoAllRequest}>All requests</Button>}
                        </div>
                    }
                    {reviewType === "request_history" ?
                        <RequestHistoryListManager
                            currentHistoryPage={currentHistoryPage}
                            selectedValueHistoryPage={selectedValueHistoryPage}
                            onPageChangeRequests={onPageChangeRequests}
                            setSelectedValueHistoryPage={setSelectedValueHistoryPage} 
                            isRequestDetailsShow={isRequestDetailsShow}
                            setIsRequestDetailsShow={setIsRequestDetailsShow}
                            selectedRequest={selectedRequest}
                            setSelectedRequest={setSelectedRequest} />
                        :
                        <>
                            <AssetRequestsSectionManager 
                                currentPending={currentPending}
                                selectedValuePending={selectedValuePending}
                                onPageChangePending={onPageChangePending}
                                setSelectedValuePending={setSelectedValuePending}
                                currentNew={currentNew}
                                selectedValueNew={selectedValueNew}
                                onPageChangeNew={onPageChangeNew}
                                setSelectedValueNew={setSelectedValueNew}
                                reviewAsset={reviewAsset}
                                newRequestsList={newRequestsList}
                                pendingRequestsList={pendingRequestsList}
                                clearAll={clearAllNewReq}/>
                            <div className="bg-white flex justify-between p-6 mb-8">
                                <div className="font-medium text-xl pt-1">Request history</div>
                                <Button kind="primary" icon="icon-update-outline" onClick={resultHistoryClicked}>See history</Button>
                            </div>
                        </>
                    }
                </> 
            }
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default ProjectAssetRequestContainerManager;