import { Button, Checkbox, CheckboxGroup, Input, Search, Tag } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { selectOldTechnologiesValuesOptions, selectTechnologiesValuesOptions } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import { getMasterDataByType } from "../../../store/project/projectThunks";
import { setOldTechnologiesOptions } from "../../../store/project/projectSlice";

const TechnologySelectionSection: React.FC<{setActiveIndex: Function, submitAddAsset: Function, technologyValue: Array<string | number>, setTechnologyValue: Function, otherTechnologyValue: Array<string | number>, setOtherTechnologyValue: Function}> = ({setActiveIndex, submitAddAsset, technologyValue, setTechnologyValue, otherTechnologyValue, setOtherTechnologyValue}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [pageNumberTech, setPageNumberTech] = useState(1);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [customTechnology, setCustomTechnology] = useState("");
    const oldTechnologiesOptions = useSelector(selectOldTechnologiesValuesOptions);
    const technologiesValuesOptions = useSelector(selectTechnologiesValuesOptions);
    const [searchText, setSearchText] = useState("");
    const handleSearch = (e: string) => {
        dispatch(setOldTechnologiesOptions([]));
        setPageNumberTech(0);
        setSearchText(e);
    }
    const addCustomTech = () => {
        const techValues = otherTechnologyValue
        techValues.push(customTechnology);
        setOtherTechnologyValue(techValues);
        setCustomTechnology("")
    }
    const onChange = (value: (string | number)[]) => {
        setTechnologyValue(value as Array<string>);
    };
    const handleScroll = (event: any) => {
        const target = event?.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight && technologiesValuesOptions?.data?.length === 10) {
            dispatch(setOldTechnologiesOptions(technologiesValuesOptions?.data))
            setPageNumberTech(pageNumberTech + 1)
        }
    }
    let options = JSON.parse(JSON.stringify(technologiesValuesOptions?.data))
    if (technologiesValuesOptions && technologiesValuesOptions.data && technologiesValuesOptions?.data?.length > 0 && oldTechnologiesOptions?.length > 0) {
        options = [...oldTechnologiesOptions, ...options];
    }
    useEffect(() => {
        dispatch(getMasterDataByType({masterDataType: 'technologies', pageNumber: pageNumberTech, pageSize: '10', searchString: searchText}));
      }, [dispatch, pageNumberTech, searchText])
    return (
        <div className="p-4">
            <div className="bg-white border-2 p-2 text-[#696969]">
                {technologyValue?.length > 0 ?
                    <>
                        <div>Technologies</div>
                        <div className="flex gap-0.5 flex-wrap">
                            {technologyValue.map((selectedTechnology, index) => <Tag key={index} type='filled' onClose={() => setTechnologyValue(technologyValue.filter((technology) => technology !== selectedTechnology))}>{options?.filter((technology: {id: string, name: string}) => technology.id === selectedTechnology)?.[0]?.name}</Tag>)}
                            {otherTechnologyValue.map((selectedTechnology, index) => <Tag key={index} type='filled' onClose={() => setTechnologyValue(otherTechnologyValue.filter((technology) => technology !== selectedTechnology))}>{selectedTechnology}</Tag>)}
                        </div>
                    </>
                    :
                    <div>Select the technology</div>
                }
            </div>
            <Search className="px-0 w-full"
                onSearch={handleSearch} placeholder="Search" searchValue={searchText} onChange={handleSearch} />
            <div className="border-2 p-4 h-72 overflow-auto" onScroll={handleScroll}>
                <div className="py-4 font-bold">Name</div>
                <CheckboxGroup value={technologyValue} name="table" onChange={onChange}>
                    {options?.map((technology:{id: string, name: string}) => <Checkbox className="p-2" value={technology.id}>{technology.name}</Checkbox>)}
                </CheckboxGroup>
            </div>
            <div className="mt-4">
                <span>If the specific technology you're looking for isn't listed, please check the box below and manually enter the technology name in the provided text input field.</span>
                <div className="my-4">
                    <Checkbox checked={isOtherSelected} onChange={() => setIsOtherSelected(!isOtherSelected)}>Other</Checkbox>
                </div>
                {isOtherSelected &&
                    <div className="flex justify-between">
                        <Input className="w-3/4" title="Insert technology" value={customTechnology} onChange={setCustomTechnology} />
                        <Button kind="tertiary" icon="icon-plus-outline" disabled={customTechnology === ""} onClick={addCustomTech}>Add</Button>
                    </div>
                }
            </div>
            <div className="flex justify-end gap-2 mt-4">
                <Button kind='secondary' onClick={() => setActiveIndex(1)}>Back</Button>
                <Button kind='primary' icon="icon-check-hook-outline" disabled={technologyValue?.length<=0} onClick={submitAddAsset}>Submit request</Button>
            </div>

        </div>
    )
}

export default TechnologySelectionSection;