import { Button, Checkbox, CheckboxGroup, Modal, Search, Tag } from "@appkit4/react-components";
import { UseCaseDependencies } from "../../../api";
import { useEffect, useState } from "react";

const DependenciesSelectionModal: React.FC<{ visible: boolean, closeModal: Function, addDependencyToPayload: Function, searchText: string, dependencies: Array<string|number>, options: Array<UseCaseDependencies>, handleScroll: any, handleSearch: any }> = ({ visible, closeModal, addDependencyToPayload, searchText, dependencies, options, handleScroll, handleSearch }) => {
    const [selectedDependenciesChild, setSelectedDependenciesChild] = useState<Array<string | number>>(dependencies);
    const onChange = (value: (string | number)[]) => {
        setSelectedDependenciesChild(value as Array<string>);
    };
    const addDependencyToPayloadChild = () => {
        addDependencyToPayload(selectedDependenciesChild)
    }
    useEffect(() => {
        setSelectedDependenciesChild(dependencies)
    }, [dependencies]);
    return (
        <Modal
            visible={visible}
            header={
                <div className="flex-col">
                    <h1 className="font-sans font-medium text-xl mb-4">Dependencies</h1>
                </div>}
            onCancel={closeModal}
            modalStyle={{ width: '42rem' }}
            bodyStyle={{ minHeight: '92px', maxHeight: "fit-content"}}
        >
            <div className="p-4">
                <div className="bg-white border-2 p-2 text-[#696969]">
                    {selectedDependenciesChild?.length > 0 ?
                        <>
                            <div>Dependencies</div>
                            <div className="flex gap-0.5 flex-wrap">
                                {selectedDependenciesChild.map((selectedDependency, index) =>
                                    <Tag key={index} type='filled' onClose={() => setSelectedDependenciesChild(selectedDependenciesChild.filter((dependency) => dependency !== selectedDependency))}>{options?.filter((dependency: UseCaseDependencies) => dependency.useCaseTechnicalId === selectedDependency)?.[0]?.name}</Tag>
                                )}
                            </div>
                        </>
                        :
                        <div>Dependencies</div>
                    }
                </div>
                <Search className="px-0 w-full"
                    onSearch={handleSearch} placeholder="Search" searchValue={searchText} onChange={handleSearch} />
                <div className="border-2 p-4 h-72 overflow-auto" onScroll={handleScroll}>
                    <div className="flex">
                        <div className="py-4 font-bold w-1/2">Name</div>
                        <div className="py-4 font-bold w-1/2">Owner</div>
                    </div>
                    <CheckboxGroup value={selectedDependenciesChild} name="dependency" onChange={onChange}>
                        {options?.map((dependency: UseCaseDependencies) => 
                        <div className="flex">
                            <Checkbox className="w-1/2" value={dependency?.useCaseTechnicalId}>{dependency?.name}</Checkbox>
                            <div className="w-1/2">{dependency?.owner}</div>
                        </div>)}
                    </CheckboxGroup>
                </div>
                <div className="flex justify-end gap-2 mt-4">
                    <Button kind='secondary' onClick={closeModal}>Cancel</Button>
                    <Button kind='primary' disabled={selectedDependenciesChild?.length <= 0} onClick={addDependencyToPayloadChild}>Select</Button>
                </div>
            </div>
        </Modal>
    )
}

export default DependenciesSelectionModal;