import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionOnRequest, addTeamsToProject, createAsset, createProject, getAllAssets, getAllProjectScope, getAllMasterData, getAllProjects, getAllRequests, getAllUsers, getAsset, getProject, getProjectConfig, removeAssetOwnership, removeMemberFromProject, requestAssetOwnership, syncAsset, updateAssetOfaProject, updateAssetOptionalDetails, updateMemberRoleOfProject, updateProject, updateProjectConfig, updateProjectStatus, viewRequest, getRequestForAsset, lineageAndGcsBucketConnectivity, updateAssetBlockedStatus, updateAssetOwnershipBymm, getAllUseCasesDependencies, useCaseManagement, getAllUseCases, getUseCaseDetails, getUseCaseAssetList, editUseCase, updateUseCaseOwnership, removeUseCase, getAllSurveyOverview, getAllSurvey, requestSurveyDeadline, getSurveyRequest, updateSurveyRequest, surveyReview } from '../../api/services.gen';
import { ActionOnRequest, AssetRequest, ConnectivityRequest, CreateAssetRequest, CreateProjectRequest, CreateTeamsRequest, CreateUseCaseRequest, EditUseCaseRequest, GetAssetRequest, ProjectConfigUpdateRequest, ReviewedRequest, surveyState, surveyStatus2, UpdateAssetOptionalRequest, UpdateAssetRequest, UpdateMemberRequest, UpdateProjectRequest, UpdateProjectStatusRequest, UpdateSurveyBusinessRequest, UpdateSurveyMigrationRequest, UpdateSurveyTechnologyRequest } from '../../api/types.gen';
import { setLoading, setError, setUsersByRole, setAllProjectsList, setSelectedProjectData, setProjectConfigData, setAssetDataForProject, setSelectedAsset, setAllNewReqDataAsset, setAllPendingReqDataAsset, setRequestHistoryData, setScopeDataOverview, setDatabasesOptions, setTablesOptions, setTechnologiesOptions, setReqDetailForAsset, setConnectivityResponse, setDependenciesOptions, setUseCaseListData, setSelectedUseCase, setUseCaseAssetsList, setProjectInitialDetails, setInitialProjectConfigData, setUserEmailsTableData, setSurveyOverviewData, setSurveyListingData, setSurveyDetails } from './projectSlice';

export const createNewProject = createAsyncThunk(
  'project/create',
  async (projectData: CreateProjectRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await createProject({ body: projectData });
      if (response.data) {
        dispatch(setError(null));
        dispatch(setProjectInitialDetails({
          name: "",
          type: "",
          startDate: null,
          endDate: null,
          description: "",
          adminEmails: [],
          managerEmails: []
        },));
        dispatch(setUserEmailsTableData([]));
        dispatch(setInitialProjectConfigData({}));
        dispatch(setConnectivityResponse({connectivity: false, message: ""}));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to create project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getProjectsList = createAsyncThunk(
  'project/get',
  async (_undefined, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllProjects();
      if (response?.data) {
        dispatch(setAllProjectsList(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get Project List'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getProjectDetails = createAsyncThunk(
  '/project/{id}',
  async (id: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getProject({ path: { projectBusinessId: id } });
      if (response?.data) {
        dispatch(setSelectedProjectData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get Project details'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getUsers = createAsyncThunk(
  'user/getUsers',
  async (_undefined, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllUsers();
      if (response.data) {
        dispatch(setUsersByRole(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get the users list data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const archiveProject = createAsyncThunk(
  'project/{projectBusinessId}/status',
  async (statusBody: UpdateProjectStatusRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateProjectStatus({ path: { projectBusinessId: statusBody?.projectBusinessId }, body: statusBody });
      if (response.data?.projectBusinessId) {
        dispatch(setError(null));
        dispatch(setSelectedProjectData(response.data));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update project as archive'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const EditProjectData = createAsyncThunk(
  'project/{projectBusinessId}',
  async (projectData: UpdateProjectRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateProject({ path: { projectBusinessId: projectData?.projectBusinessId }, body: projectData });
      if (response.data) {
        dispatch(setError(null));
        dispatch(setSelectedProjectData(response.data));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update project data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getProjectConfigData = createAsyncThunk(
  'project/{projectBusinessId}/config',
  async (id: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getProjectConfig({ path: { projectBusinessId: id } });
      if (response?.data) {
        dispatch(setProjectConfigData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get Project Config details'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editProjectConfigDetails = createAsyncThunk(
  'project/{projectBusinessId}/config',
  async (projectConfigData: ProjectConfigUpdateRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateProjectConfig({ path: { projectBusinessId: projectConfigData?.projectBusinessId }, body: projectConfigData });
      if (response?.data) {
        dispatch(setProjectConfigData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update Project Config details'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const removeProjectMember = createAsyncThunk(
  '/project/{projectBusinessId}/member/{userEmail}',
  async (userEmailData: { userEmail: string, projectBusinessId: string; }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await removeMemberFromProject({ path: { projectBusinessId: userEmailData?.projectBusinessId, email: userEmailData?.userEmail } });
      if (response?.data) {
        dispatch(setSelectedProjectData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to delete member from Project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addProjectMember = createAsyncThunk(
  '/project/{projectBusinessId}/teams',
  async (teamsData: CreateTeamsRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await addTeamsToProject({ path: { projectBusinessId: teamsData?.projectBusinessId }, body: teamsData });
      if (response?.data) {
        dispatch(setSelectedProjectData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to add member to Project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editProjectMemberRole = createAsyncThunk(
  '/project/{projectBusinessId}/member/{userEmail}',
  async (memberUpdateData: UpdateMemberRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateMemberRoleOfProject({ path: { projectBusinessId: memberUpdateData?.projectBusinessId, email: memberUpdateData?.userEmail }, body: memberUpdateData });
      if (response?.data) {
        dispatch(setSelectedProjectData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update member role in Project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addNewAssetInProject = createAsyncThunk(
  '/asset',
  async (assetData: { createAssetReq: CreateAssetRequest, getAssetReq: GetAssetRequest }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await createAsset({ body: assetData.createAssetReq });
      if (response?.data) {
        dispatch(setError(null));
        dispatch(getAssetTableData(assetData.getAssetReq))
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to add new asset to Project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getAssetTableData = createAsyncThunk(
  '/asset?projectTechnicalId={projectId}&searchString={searchString}&isOwnedByMeChecked={isOwnedByMeChecked}&isUnassignedChecked={isUnassignedChecked}&pageNumber={pageNumber}&pageSize={pageSize}',
  async (getAssetRequestPayload: GetAssetRequest, { dispatch }) => {
    try {
      const { searchString, isOwnedByMeChecked, isUnassignedChecked, pageNumber, pageSize, projectId } = getAssetRequestPayload;
      dispatch(setLoading(true));
      const response = await getAllAssets({ query: { projectId: projectId, searchString: searchString, isOwnedByMeChecked: isOwnedByMeChecked, isUnassignedChecked: isUnassignedChecked, pageNumber: pageNumber, pageSize: pageSize } });
      if (response?.data) {
        dispatch(setAssetDataForProject(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get asset table data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const requestForAssetOwnership = createAsyncThunk(
  '/asset/{assetBusinessId}/request/request-ownership',
  async (assetReq: AssetRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await requestAssetOwnership({ path: { assetBusinessId: assetReq?.assetBusinessId }, body: assetReq });
      if (response?.data) {
        dispatch(setError(null));
        dispatch(getAssetDetails(assetReq?.assetBusinessId));
        dispatch(getRequestDetailsForAsset(assetReq?.assetBusinessId));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to assign ownership of the asset'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const reqToRemoveAssetOwnership = createAsyncThunk(
  '/asset/{assetBusinessId}/request/remove-ownership',
  async (assetReq: AssetRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await removeAssetOwnership({ path: { assetBusinessId: assetReq?.assetBusinessId }, body: assetReq });
      if (response?.data) {
        dispatch(setError(null));
        dispatch(getAssetDetails(assetReq?.assetBusinessId));
        dispatch(getRequestDetailsForAsset(assetReq?.assetBusinessId));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to remove ownership of the asset'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getAssetDetails = createAsyncThunk(
  '/asset/{assetBusinessId}',
  async (assetId: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAsset({ path: { assetBusinessId: assetId } });
      if (response?.data) {
        dispatch(setSelectedAsset(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get asset table data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateDataAssetDetails = createAsyncThunk(
  '/asset/{assetBusinessId}',
  async (assetData: UpdateAssetRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateAssetOfaProject({ path: { assetBusinessId: assetData?.assetBusinessId }, body: assetData });
      if (response?.data) {
        dispatch(setSelectedAsset(response?.data));
        dispatch(getRequestDetailsForAsset(assetData?.assetBusinessId));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update asset required data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateDataAssetOptionalDetails = createAsyncThunk(
  '/asset/{assetBusinessId}',
  async (assetData: UpdateAssetOptionalRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateAssetOptionalDetails({ path: { assetBusinessId: assetData?.assetBusinessId }, body: assetData });
      if (response?.data) {
        dispatch(setSelectedAsset(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update asset optional data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getAllAssetRequests = createAsyncThunk(
  '/request/{requestState}',
  async (getAllReqPayload: { pageNumber: string, pageSize: string, requestState: string, projectBusinessId: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllRequests({ query: { pageNumber: getAllReqPayload?.pageNumber, pageSize: getAllReqPayload?.pageSize, projectBusinessId: getAllReqPayload?.projectBusinessId }, path: { requestState: getAllReqPayload?.requestState } });
      if (response?.data) {
        if (getAllReqPayload?.requestState === "newRequests") {
          dispatch(setAllNewReqDataAsset(response?.data));
        } else if (getAllReqPayload?.requestState === "pendingRequests") {
          dispatch(setAllPendingReqDataAsset(response?.data));
        } else if (getAllReqPayload?.requestState === "requestHistory") {
          dispatch(setRequestHistoryData(response?.data));
        }
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get request of asset data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const viewAssetRequests = createAsyncThunk(
  '/request/view',
  async (payload: Array<ReviewedRequest>, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await viewRequest({ body: payload });
      if (response?.data) {
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get request of asset data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getScopeData = createAsyncThunk(
  '/project/{projectBusinessId}/scope',
  async (projectBusinessId: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllProjectScope({ path: { projectBusinessId: projectBusinessId } });
      if (response?.data) {
        dispatch(setScopeDataOverview(response.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get scope data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getRequestDetailsForAsset = createAsyncThunk(
  '/asset/{assetBusinessId}/request',
  async (assetBusinessId: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getRequestForAsset({ path: { assetBusinessId: assetBusinessId } });
      if (response?.data) {
        dispatch(setReqDetailForAsset(response.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get request details for asset'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);


export const getMasterDataByType = createAsyncThunk(
  '/asset/{masterDataType}/master',
  async (payload: { masterDataType: string, pageNumber: number, pageSize: string, searchString: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllMasterData({
        path: { masterDataType: payload?.masterDataType },
        query: { pageNumber: payload?.pageNumber, pageSize: payload?.pageSize, searchString: payload?.searchString }
      });
      if (response?.data) {
        if (payload?.masterDataType === 'databases')
          dispatch(setDatabasesOptions(response?.data));
        else if (payload?.masterDataType === 'tables')
          dispatch(setTablesOptions(response?.data));
        else if (payload?.masterDataType === 'technologies')
          dispatch(setTechnologiesOptions(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get master data by type'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const pullNewLineageForProject = createAsyncThunk(
  '/asset/assetSync/{projectId}',
  async (projectId: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await syncAsset({ path: { projectId: projectId } });
      if (response?.data) {
        dispatch(setError(null));
        dispatch(getProjectConfigData(projectId));
        dispatch(getScopeData(projectId));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to sync asset data from the project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const actionOnAssetRequest = createAsyncThunk(
  '/request/{requestBusinessId}/action',
  async (payload: ActionOnRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await actionOnRequest({ path: { requestBusinessId: payload?.requestBusinessId }, body: payload });
      if (response?.data) {
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update the request'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const testConnectivityRequest = createAsyncThunk(
  '/project/check-connectivity',
  async (payload: ConnectivityRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await lineageAndGcsBucketConnectivity({ body: payload });
      if (response?.data) {
        dispatch(setConnectivityResponse({ connectivity: true, message: response?.data }));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setConnectivityResponse({ connectivity: false, message: "Configuration with lineage generator is incorrect." }))
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateAssetBlockUnblockStatus = createAsyncThunk(
  '/asset/{assetBusinessId}/blocked',
  async (payload: { assetId: string, assetBlocked: boolean }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateAssetBlockedStatus({ path: { assetBusinessId: payload?.assetId }, body: { assetBlocked: payload?.assetBlocked } });
      if (response?.data) {
        dispatch(setError(null));
        dispatch(setSelectedAsset(response?.data))
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update the asset block/unblock status'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateOwnershipOfAsset = createAsyncThunk(
  '/asset/{assetBusinessId}/ownership',
  async (payload: { assetId: string, assetTechId: string, assetOwner: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateAssetOwnershipBymm({ path: { assetBusinessId: payload?.assetId }, body: { assetTechnicalId: payload?.assetTechId, assetBusinessId: payload?.assetId, owner: payload.assetOwner } });
      if (response?.data) {
        dispatch(setError(null));
        dispatch(setSelectedAsset(response?.data))
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update ownership of the asset'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getAllDependencies = createAsyncThunk(
  '/use-case/dependencies/{projectBusinessId}',
  async (payload: { pageNumber: number, pageSize: string, searchString: string, projectBusinessId: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllUseCasesDependencies({ query: { searchString: payload.searchString, pageNumber: payload.pageNumber, pageSize: payload.pageSize }, path: {projectBusinessId: payload?.projectBusinessId} });
      if (response?.data) {
        dispatch(setError(null));
        dispatch(setDependenciesOptions(response?.data))
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get dependencies list'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getUseCaseList = createAsyncThunk(
  '/use-case/{projectBusinessId}',
  async (payload: { pageNumber: number, pageSize: string, searchString: string, projectBusinessId: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllUseCases({ query: { pageNumber: payload.pageNumber, pageSize: payload.pageSize, searchString: payload.searchString }, path: {projectBusinessId: payload?.projectBusinessId} });
      if (response?.data) {
        dispatch(setUseCaseListData(response?.data))
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get use case list'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addNewUseCase = createAsyncThunk(
  '/use-case',
  async (payload: CreateUseCaseRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await useCaseManagement({ body: payload });
      if (response?.data) {
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to create new use case'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getDetailsOfUseCase = createAsyncThunk(
  '/use-case/{useCaseId}',
  async (payload: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getUseCaseDetails({ path: { useCaseBusinessId: payload } });
      if (response?.data) {
        dispatch(setSelectedUseCase(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get details of use case'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getAssetListForUseCase = createAsyncThunk(
  '/use-case/{useCaseId}/assetList',
  async (payload: { businessId: string, pageNumber: number, pageSize: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getUseCaseAssetList({ path: { useCaseBusinessId: payload.businessId }, query: { pageNumber: payload?.pageNumber, pageSize: payload?.pageSize } });
      if (response?.data) {
        dispatch(setUseCaseAssetsList(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get list of assets of use case'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editUseCaseData = createAsyncThunk(
  '/use-case/edit/{useCaseId}',
  async (payload: { businessId: string, useCaseData: EditUseCaseRequest }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await editUseCase({ path: { useCaseBusinessId: payload.businessId }, body: payload.useCaseData });
      if (response?.data) {
        dispatch(setSelectedUseCase(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to edit details of use case'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const reqOwnershipUseCase = createAsyncThunk(
  '/use-case/{useCaseId}/assignOwnership/{userEmail}',
  async (payload: { businessId: string, userEmail: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateUseCaseOwnership({ path: { useCaseBusinessId: payload.businessId, userEmail: payload.userEmail }});
      if (response?.data) {
        dispatch(setSelectedUseCase(response?.data))
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to request for ownership for use case'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const removeUseCaseBU = createAsyncThunk(
  '/use-case',
  async (businessId: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await removeUseCase({ path: {useCaseBusinessId: businessId} });
      if (response?.data) {
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to remove use case'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getSurveyOverviewData = createAsyncThunk(
  '/survey/overview',
  async (projectBusinessId: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllSurveyOverview({ query: {projectBusinessId: projectBusinessId} });
      if (response?.data) {
        dispatch(setError(null));
        dispatch(setSurveyOverviewData(response?.data))
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get survey overview data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getSurveyListData = createAsyncThunk(
  '/survey',
  async (payload: {pageNumber?: number, pageSize?: number, projectBusinessId: string, surveyStatus: string}, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllSurvey({ query: {pageNumber: payload.pageNumber, pageSize: payload.pageSize, projectBusinessId: payload.projectBusinessId, surveyStatus: payload.surveyStatus} });
      if (response?.data) {
        dispatch(setError(null));
        dispatch(setSurveyListingData(response?.data))
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get survey list data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const requestDeadLineForSurvey = createAsyncThunk(
  '/survey/{projectBusinessId}/deadline',
  async (payload: {projectBusinessId: string, assets: Array<string>, deadLine: string}, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await requestSurveyDeadline({ path: {projectBusinessId: payload?.projectBusinessId}, body: {assets: payload?.assets, surveyDeadline: payload?.deadLine} });
      if (response?.data) {
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to assign survey deadline'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getSurveyDetailsBU = createAsyncThunk(
  '/survey/{assetBusinessId}',
  async (assetId: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getSurveyRequest({ path: { assetBusinessId: assetId } });
      if (response?.data) {
        dispatch(setSurveyDetails(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get survey data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateSurveyDetailsBU = createAsyncThunk(
  '/survey/{assetBusinessId}',
  async (payload: {assetId: string, surveyState: surveyState, supplementaryDetails?: UpdateAssetOptionalRequest, businessDetails?: UpdateSurveyBusinessRequest,
    technologyDetails?: UpdateSurveyTechnologyRequest, migrationPlanning?: UpdateSurveyMigrationRequest},  { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateSurveyRequest({ path: { assetBusinessId: payload?.assetId }, body: {surveyState: payload?.surveyState, supplementaryDetails: payload?.supplementaryDetails,
        businessDetails: payload?.businessDetails, technologyDetails: payload?.technologyDetails, migrationPlanning: payload?.migrationPlanning}});
      if (response?.data) {
        dispatch(setSurveyDetails(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update survey data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const reviewSurveyMM = createAsyncThunk(
  '/survey/{assetBusinessId}/review',
  async (payload: {assetId: string, surveyState: surveyStatus2, comment?: string},  { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await surveyReview({ path: { assetBusinessId: payload?.assetId }, body: {surveyStatus: payload?.surveyState, comment: payload?.comment}});
      if (response?.data) {
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update survey review data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);