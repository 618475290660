import React, { useEffect, useState } from "react";
import { selectSelectedUseCaseDetails, selectUseCaseAssetsList, selectUseCaseListData } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import { Accordion, AccordionItem, Badge, Button, Column, DropdownButton, Pagination, Select, Table } from "@appkit4/react-components";
import { assetTypeOptions, dataAssetsWithPreviewMapping, itemsPerPageOptions, migrationStatusOptions, surveyStatusIndicators, useCaseComplexityOptions, useCaseSizeOptions, useCaseTypeOptions } from "../../../services/constants";
import dayjs from "dayjs";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { getAssetListForUseCase, reqOwnershipUseCase } from "../../../store/project/projectThunks";
import EditUseCaseData from "./EditUseCaseData";
import OwnershipUpdateUseCaseModal from "./OwnershipUpdateUseCaseModal";
import DeleteUseCaseConfirmationModal from "./DeleteUseCaseConfirmationModal";

const UseCasesTableDataManager: React.FC<{ current: number, setCurrent: Function, selectedValue: string, setSelectedValue: Function, viewDetails: Function, isDetailsOpen: boolean, addUseCaseClicked: Function, editUseCaseDetails: Function, deleteUseCaseData: Function, isEditModeUseCase: boolean, setIsEditModeUseCase: Function }> = ({ current, setCurrent, selectedValue, setSelectedValue, viewDetails, isDetailsOpen, addUseCaseClicked, editUseCaseDetails, deleteUseCaseData, isEditModeUseCase, setIsEditModeUseCase }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPageSize, setSelectedPageSize] = useState("10");
    const useCaseListData = useSelector(selectUseCaseListData);
    const selectedUseCase = useSelector(selectSelectedUseCaseDetails);
    const [activeKeys, setActiveKeys] = useState<string[]>([]);
    
    const [assignOwnershipModalOpen, setAssignOwnershipModalOpen] = useState(false);
    const [isRemoveOwnershipModalOpen, setIsRemoveOwnershipModalOpen] = useState(false);

    const onPageChange = (page: number) => {
        setCurrent(page);
    }
    const onPageChangeAsset = (page: number) => {
        setCurrentPage(page);
    }
    const useCaseAssetsData = useSelector(selectUseCaseAssetsList);
    const onClickAccordion = (activeKeys: string[]) => {
        setActiveKeys(activeKeys);
    }
    let dependencyValues: Array<any> = [];
    dependencyValues.push(selectedUseCase?.dependencies?.map((selectedDependency) =>
        selectedDependency.name
    ));
    const caseActionClicked = (requestType: string) => {
        if(requestType === "assignOwnershipOfUseCase")
            setAssignOwnershipModalOpen(true);
        else if(requestType === "removeUseCase")
            setIsRemoveOwnershipModalOpen(true);
    }
    const useCaseDetails = {
        "Name": selectedUseCase?.name,
        "Description": selectedUseCase?.description,
        "Owner": selectedUseCase?.owner,
        "Creation date": selectedUseCase?.createdAt ? dayjs(new Date(selectedUseCase.createdAt)).format("MM/DD/YYYY") : "",
        "Updated by": selectedUseCase?.updatedBy,
        "Updated date": selectedUseCase?.updatedAt ? dayjs(new Date(selectedUseCase?.updatedAt)).format("MM/DD/YYYY") : "",
        "Type": useCaseTypeOptions.filter(option => option.value === selectedUseCase?.type)?.[0].label,
        "Size": useCaseSizeOptions.filter(option => option.value === selectedUseCase?.size)?.[0].label,
        "Complexity": useCaseComplexityOptions.filter(option => option.value === selectedUseCase?.complexity)?.[0].label,
        "Dependencies": selectedUseCase?.dependencies?.map(dependency => dependency?.name),
        "Assets in total": selectedUseCase?.asset,
        "Migration status": migrationStatusOptions.filter(option => option.value === selectedUseCase?.migrationStatus)?.[0].label
    }
    const closeOwnershipModal = () => {
        setAssignOwnershipModalOpen(false);
    }
    const editOwnership = (userEmail: string) => {
        if(userEmail && userEmail !== "" )
            dispatch(reqOwnershipUseCase({businessId: selectedUseCase?.useCaseBusinessId, userEmail: userEmail}))
        setAssignOwnershipModalOpen(false)
    }
    const closeModal = () => {
        setIsRemoveOwnershipModalOpen(false);
    }
    const deleteUseCase = () => {
        deleteUseCaseData();
        setIsRemoveOwnershipModalOpen(false);
    }
    const editUseCase = (useCaseFormValues: any) => {
        const payload = {
            businessId: selectedUseCase?.useCaseBusinessId,
            useCaseData: {
                name: useCaseFormValues?.useCaseName,
                description: useCaseFormValues?.useCaseDesc,
                type: useCaseFormValues?.useCaseType,
                size: useCaseFormValues?.useCaseSize,
                complexity: useCaseFormValues?.useCaseComplexity,
                owner: useCaseFormValues?.useCaseOwner,
                dependencies: useCaseFormValues?.dependencies,
                comment: useCaseFormValues?.useCaseComment
            }
        }
        editUseCaseDetails(payload)
        setIsEditModeUseCase(false);
    }
    useEffect(() => {
        if (selectedUseCase && selectedUseCase.useCaseBusinessId)
            dispatch(getAssetListForUseCase({ businessId: selectedUseCase?.useCaseBusinessId, pageNumber: currentPage, pageSize: selectedPageSize }));
    }, [dispatch, selectedUseCase, currentPage, selectedPageSize])
    return (
        <>
            {isDetailsOpen ?
                <div>
                    <div className="flex justify-between pb-4">
                        <div className="flex flex-row my-2">
                            <span className={`Appkit4-icon icon-management-fill ap-font-16 pt-1 text-[#415385]`} aria-hidden="true"></span>
                            <h1 className="font-sans font-medium text-xl text-[#415385]">{selectedUseCase?.name}</h1>
                        </div>
                        {!isEditModeUseCase && <Button kind='primary' icon="icon-pencil-outline" onClick={() => setIsEditModeUseCase(true)}>Edit</Button>}
                    </div>
                    {isEditModeUseCase ?
                        <EditUseCaseData editUseCase={editUseCase} setIsEditModeUseCase={setIsEditModeUseCase}/>
                        :
                        <>
                            <div className="my-4 bg-white">
                                <h1 className="font-sans font-medium text-xl mx-6 pt-6 pb-6">Use case details</h1>
                                <div className="px-6 pb-6">
                                    {Object.entries(useCaseDetails).map(([key, value]) => (
                                        <div className="flex justify-between border border-t-0 border-l-0 border-r-0 p-2" key={key}>
                                            {key === "Dependencies" ? 
                                                <>
                                                    <div>{key}</div>
                                                    <div>{`${dependencyValues}`}</div>
                                                </>
                                                :
                                                <>
                                                    <div>{key}</div>
                                                    <div>{value}</div>
                                                </>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Accordion multiple={false} onClick={onClickAccordion} activeKeys={activeKeys}>
                                <AccordionItem title={"Related data assets"} itemKey="1" className="my-6 bg-white hover:bg-white">
                                    {useCaseAssetsData && useCaseAssetsData.data && useCaseAssetsData.data.length > 0 ?
                                        <Table className="shadow-none border" originalData={useCaseAssetsData?.data ? JSON.parse(JSON.stringify(useCaseAssetsData?.data)) : []} hasTitle>
                                            <Column field='assetType' sortKey='assetType' renderCell={
                                                (row) => {
                                                    return (
                                                        <>
                                                            <span className={`Appkit4-icon icon-${dataAssetsWithPreviewMapping.filter(dataAsset => dataAsset.name === row.assetType)[0]?.prefixIcon}-outline ap-font-24 px-4`} aria-hidden="true"></span>
                                                            <span className="pl-2">{assetTypeOptions.filter(option => option.value === row.assetType)[0]?.label}</span>
                                                        </>
                                                    )
                                                }
                                            }>Type</Column>
                                            <Column field='assetName' sortKey='assetName'>Name</Column>
                                            <Column field='owner' sortKey='owner'>Owner</Column>
                                            <Column field='surveyStatus' sortKey='surveyStatus' renderCell={(row) => {
                                                const selectedStatus = surveyStatusIndicators.filter(statusDetail => statusDetail.status === row.surveyStatus)[0]
                                                return (
                                                    !row.assetActiveStatus ?
                                                        <div className="ml-12"> --- </div>
                                                        :
                                                        <div>
                                                            <Badge type={selectedStatus?.statusIndicator} value={selectedStatus?.label} />
                                                        </div>
                                                )
                                            }}>Survey status</Column>
                                            <Column field='migrationStatus' sortKey='migrationStatus' renderCell={(row) => migrationStatusOptions?.filter(option => option.value === row.migrationStatus)?.[0].label}>Migration status</Column>
                                        </Table>
                                        :
                                        <div>There is no data to display</div>
                                    }
                                    <div className="flex justify-between">
                                        <div className="flex mt-4 gap-2 items-center">
                                            <span>Show</span>
                                            <Select className="!w-20"
                                                placeholder="drop"
                                                hideTitleOnInput={true}
                                                data={itemsPerPageOptions}
                                                value={selectedPageSize}
                                                onSelect={(e) => setSelectedPageSize(e.toString())}
                                                dropdownAlwaysDown={false}
                                            >
                                            </Select>
                                            <span>items per page</span>
                                        </div>
                                        <Pagination current={currentPage} total={useCaseAssetsData?.totalPages || 1} onPageChange={onPageChangeAsset}></Pagination>
                                    </div>
                                </AccordionItem>
                            </Accordion>
                            <div className="my-4 bg-white flex justify-between">
                                <div>
                                    <h1 className="font-sans font-medium text-xl mx-6 pt-6 pb-2">Use case administration</h1>
                                    <h3 className="mx-6 pb-6">You have the ability to remove a use case or assign ownership of the use case to a different business user.</h3>
                                </div>
                                <DropdownButton className="m-4" data={
                                    [
                                        { label: "Remove", value: 'removeUseCase', disabled: false },
                                        { label: "Assign Ownership", value: 'assignOwnershipOfUseCase', disabled: false }
                                    ]} ariaLabel="choose button action" onSelect={(e) => caseActionClicked(e.toString())}
                                    >
                                    Options
                                </DropdownButton>
                                <OwnershipUpdateUseCaseModal visible={assignOwnershipModalOpen}  closeModal= {closeOwnershipModal} editOwnership = {editOwnership} ownerEmail={selectedUseCase?.owner}/>
                                <DeleteUseCaseConfirmationModal visible={isRemoveOwnershipModalOpen} closeModal={closeModal} deleteUseCase={deleteUseCase} />
                            </div>
                        </>
                    }

                </div>
                :
                <>
                    <div className="flex justify-between">
                        <div className="pb-4">
                            <h1 className="font-sans font-medium text-xl">Use case management</h1>
                            <h3>Here you can access use cases to date. Utilize the search and filter functions to easily find specific use cases. Click on any use case to view detailed information.</h3>
                        </div>
                        <DropdownButton data={[{ label: "Create use case", value: 'manual', disabled: false }]} ariaLabel="choose button action" onSelect={(e) => addUseCaseClicked(e.toString())}>
                            Add use case
                        </DropdownButton>
                    </div>
                    {useCaseListData && useCaseListData.data && useCaseListData.data.length > 0 ?
                        <Table className="shadow-none" originalData={useCaseListData?.data ? JSON.parse(JSON.stringify(useCaseListData?.data)) : []} hasTitle>
                            <Column field='name' sortKey='name'>Name</Column>
                            <Column field='owner' sortKey='owner'>Owner</Column>
                            <Column field='type' sortKey='type' renderCell={(row) => <span>{useCaseTypeOptions.filter(type => type.value === row.type)[0]?.label}</span>}>Type</Column>
                            <Column field='size' sortKey='size' renderCell={(row) => <span>{useCaseSizeOptions.filter(size => size.value === row.size)[0]?.label}</span>}>Size</Column>
                            <Column field='complexity' sortKey='complexity' renderCell={(row) => <span>{useCaseComplexityOptions.filter(comp => comp.value === row.complexity)[0]?.label}</span>}>complexity</Column>
                            <Column field='asset' sortKey='asset'>Asset</Column>
                            <Column field='name' renderCell={(row: any) => <Button kind="primary" onClick={() => viewDetails(row)}>View details</Button>}>Actions</Column>
                        </Table>
                        :
                        <div>There is no data to display</div>
                    }
                    <div className="flex justify-between">
                        <div className="flex mt-4 gap-2 items-center">
                            <span>Show</span>
                            <Select className="!w-20"
                                placeholder="drop"
                                hideTitleOnInput={true}
                                data={itemsPerPageOptions}
                                value={selectedValue}
                                onSelect={(e) => setSelectedValue(e.toString())}
                                dropdownAlwaysDown={false}
                            >
                            </Select>
                            <span>items per page</span>
                        </div>
                        <Pagination current={current} total={useCaseListData?.totalPages || 1} onPageChange={onPageChange}></Pagination>
                    </div>
                </>
            }
        </>

    )
}

export default UseCasesTableDataManager;