import { Button, Modal } from "@appkit4/react-components";
import { useEffect, useState } from "react";

interface AssetBlockUnblockModalProps {
    visible: boolean;
    closeModal: Function;
    blockUnblockAsset: Function;
    isAssetBlockedValue: boolean
}

const AssetBlockUnblockModal: React.FC<AssetBlockUnblockModalProps> = ({ visible, closeModal, blockUnblockAsset, isAssetBlockedValue = false}) => {
    const [isAssetBlocked, setIsAssetBlocked] = useState(isAssetBlockedValue);
    useEffect(() => {
        setIsAssetBlocked(isAssetBlockedValue);
    }, [isAssetBlockedValue]);
    // const changeAssetStatus = (status: string) => {
    //     setIsAssetBlocked(status === "blocked" ? true : false);
    // }
    return (
        <Modal
            visible={visible}
            title={"Block/Unblock asset status"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
            footer={
                <>
                    <Button onClick={closeModal} kind="secondary">Cancel</Button>
                    <Button onClick={()=>blockUnblockAsset(!isAssetBlocked)}>Confirm</Button>
                </>
            }
            bodyStyle={{ minHeight: '92px' }}
        >
            <div className="font-small text-[#696969] my-4">
                Here you can change the migration status of an asset. You have the flexibility to update the status at any time, ensuring that you can adapt to changes and maintain accurate project tracking as needed.
            </div>
            {/* <Select className="py-2" data={AssetBlockedMapping} dropdownRenderMode="portal" placeholder="Block status" value={isAssetBlocked ? "blocked" : "unblocked"} onSelect={(e) => changeAssetStatus(e.toString())}></Select> */}
        </Modal>);
}

export default AssetBlockUnblockModal;