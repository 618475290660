export const navigationItemsWithProject = [
    {
        name: 'Home',
        prefixIcon: 'grid-view',
        prefixCollapsedIcon: 'grid-view',
    },
    {
        name: 'Project Overview',
        prefixIcon: 'list-view',
        prefixCollapsedIcon: 'list-view',
    },
    {
        name: 'Team Members',
        prefixIcon: 'community',
        prefixCollapsedIcon: 'community',
    },
    {
        name: 'Lineage Model',
        prefixIcon: 'upload',
        prefixCollapsedIcon: 'upload',
    },
    // {
    //     name: 'Data Assets',
    //     prefixIcon: 'data',
    //     prefixCollapsedIcon: 'data',
    // },
    {
        name: 'Project Configuration',
        prefixIcon: 'cogs',
        prefixCollapsedIcon: 'cogs',
    }
];
 
export const navigationItemsWithoutProject = [
    {
        name: 'Home',
        prefixIcon: 'grid-view',
        prefixCollapsedIcon: 'grid-view',
    }
];
 
export const navigationItemsWithProjectManager = [
    {
        name: 'Home',
        prefixIcon: 'grid-view',
        prefixCollapsedIcon: 'grid-view',
    },
    {
        name: 'Project overview',
        prefixIcon: 'list-view',
        prefixCollapsedIcon: 'list-view',
    },
    {
        name: 'Scope overview',
        prefixIcon: 'data',
        prefixCollapsedIcon: 'data',
    },
    {
        name: 'Requests management',
        prefixIcon: 'email',
        prefixCollapsedIcon: 'email',
    },
    {
        name: 'Survey overview',
        prefixIcon: 'document-multiple',
        prefixCollapsedIcon: 'document-multiple',
    },
    {
        name: 'Use case management',
        prefixIcon: 'management',
        prefixCollapsedIcon: 'management',
    },
    {
        name: 'Team management',
        prefixIcon: 'community',
        prefixCollapsedIcon: 'community',
    }
];
 
export const navigationItemsWithProjectBusinessUser = [
    {
        name: 'Project overview',
        prefixIcon: 'document-multiple',
        prefixCollapsedIcon: 'document-multiple',
    },
    {
         name: 'Use case management',
         prefixIcon: 'management',
         prefixCollapsedIcon: 'management',
    },
    {
        name: 'Back',
        prefixIcon: 'arrow-left',
        prefixCollapsedIcon: 'arrow-left',
    }
]
 
export const initialProjectDTO =
{
    "name": "",
    "type": "",
    "description": "",
    "startDate": "",
    "endDate": "",
    "id": "",
    "projectStatus": ""
}
 
export const projectRoles = [
    {
        value: "admin",
        label: "Admin"
    },
    {
        value: "migration-manager",
        label: "Manager"
    },
    {
        value: "business-user",
        label: "Business User"
    }
]
 
export const dataAssetsWithPreviewMapping = [
    {
        name: 'report',
        prefixIcon: 'document-text'
    },
    {
        name: 'sasScript',
        prefixIcon: 'query'
    },
    {
        name: 'dashboard',
        prefixIcon: 'dashboard'
    },
    {
        name: 'application',
        prefixIcon: 'box'
    },
    {
        name: 'operationalDatafeed',
        prefixIcon: 'group'
    },
    {
        name: 'aiModel',
        prefixIcon: 'bot'
    }
];
 
export const surveyStatusIndicators = [
    {
        "status": "complete",
        "statusIndicator": "success",
        "label": "Complete",
        "text": "Survey has been approved."
    },
    {
        "status": "inReview",
        "statusIndicator": "success-outlined",
        "label": "In review",
        "text": "Survey is being reviewed."
    },
    {
        "status": "onGoing",
        "statusIndicator": "warning",
        "label": "Ongoing",
        "btnText": "Continue",
        "text": "Survey currently in progress"
    },
    {   
        "status": "requested",
        "statusIndicator": "danger",
        "label": "Requested",
        "btnText": "Start",
        "text": "Survey ready to be started."
    },
    {   
        "status": "notStarted",
        "statusIndicator": "info-outlined",
        "label": "Not Started",
        "text": "Request to start has not been placed."
    },
    {
        "status": "waitingForApproval",
        "statusIndicator": "",
        "label": "Waiting for Approval",
        "text": "Waiting for new asset request."
    }
];
 
export const AssetStatusMapping = [
    {
        value: 'confirmed',
        label: 'Confirmed'
    },
    {
        value: 'pendingForReview',
        label: 'Pending For Review'
    },
    {
        value: 'removed',
        label: 'Removed'
    },
    {
        value: 'rejected',
        label: 'Rejected'
    },
]
 
export const assetTypeOptions = [
    {
        label: 'AI Model',
        value: 'aiModel'
    },
    {
        label: 'Application',
        value: 'application'
    },
    {
        label: 'SAS Script',
        value: 'sasScript'
    },
    {
        label: 'Dashboard',
        value: 'dashboard'
    },
    {
        label: 'Report',
        value: 'report'
    }
];
 
export const assetCriticalityOptions = [
    {
        label: 'Low',
        value: 'low'
    },
    {
        label: 'Medium',
        value: 'medium'
    },
    {
        label: 'High',
        value: 'high'
    },
    {
        label: 'Very High',
        value: 'veryHigh'
    }
];

export const environmentOptions = [
    {
        label: 'Development',
        value: 'development'
    },
    {
        label: 'Test',
        value: 'test'
    },
    {
        label: 'Staging',
        value: 'staging'
    },
    {
        label: 'Production',
        value: 'production'
    }
]
 
export const itemsPerPageOptions = [
    {
        value: "10",
        label: "10"
    },
    {
        value: "20",
        label: "20"
    },
    {
        value: "30",
        label: "30"
    }
];
 
export const frequencyOfUseOptions = [
    {
        label: "Real time",
        value: "realTime"
    },
    {
        label: "Hourly",
        value: "hourly"
    },
    {
        label: "Daily",
        value: "daily"
    },
    {
        label: "Weekly",
        value: "weekly"
    },
    {
        label: "Monthly",
        value: "monthly"
    },
    {
        label: "Quarterly",
        value: "quarterly"
    },
    {
        label: "Yearly",
        value: "yearly"
    }
];
 
export const targetAudienceOptions = [
    {
        value: "internal",
        label: "Internal"
    },
    {
        value: "external",
        label: "External"
    }
]
 
export const radioOptions = [
    {
        value: "yes",
        label: "Yes"
    },
    {
        value: "no",
        label: "No"
    },
    {
        value: "NA",
        label: "N.a."
    }
]

export const yesNoOptions = [
    {
        value: "yes",
        label: "Yes"
    },
    {
        value: "no",
        label: "No"
    }
]
 
export const migrationStatusOptions = [
    {
        value: 'planned',
        label: 'Planned'
    },
    {
        value: 'migrated',
        label: 'Migrated'
    },
    {
        value: 'pendingForReview',
        label: 'Pending for review'
    },
    {
        value: 'active',
        label: 'Active'
    },
    {
        value: 'design',
        label: 'Design'
    },
    {
        value: 'readyForMigration',
        label: 'Ready for migration'
    },
    {
        value: 'inMigration',
        label: 'In migration'
    }
];

export const requestTypeMapping = [
    {
        label: "Request of Asset Ownership",
        value: "ownershipOfAsset"
    },
    {
        label: "Removal of Asset",
        value: "removeOwnership"
    },
    {
        label: "Approved Asset",
        value: "approvedNewAsset"
    },
    {
        label: "Approved Edited Asset",
        value: "approvedAssetChange"
    },
    {
        label: "Review pending for Asset Survey",
        value: "reviewSurvey"
    },
    {
        label: "Request for new Asset Addition",
        value: "requestAddAsset"
    },
    {
        label: "Request for Edit Asset",
        value: "requestEditAsset"
    }
];

export const scopeOverviewKeyLabel = [
    {
        label: 'AI Model',
        value: 'aiModel'
    },
    {
        label: 'Application',
        value: 'application'
    },
    {
        label: 'SAS Script',
        value: 'sasScript'
    },
    {
        label: 'Dashboard',
        value: 'dashboard'
    },
    {
        label: 'Report',
        value: 'report'
    },
    {
        label: 'Operational Datafeed',
        value: 'operationalDatafeed'
    },
    {
        label: 'Last Updated',
        value: 'lastUpdated'
    }
];

export const AssetBlockedMapping = [
    {
        value: 'blocked',
        label: 'Blocked'
    },
    {
        value: 'unblocked',
        label: 'Unblocked'
    }
];

export const useCaseTypeOptions = [
    {
        label: 'Operational',
        value: 'operational'
    },
    {
        label: 'Analytical',
        value: 'analytical'
    }
];
export const useCaseSizeOptions = [
    {
        label: 'XS',
        value: 'xs'
    },
    {
        label: 'Small',
        value: 's'
    },
    {
        label: 'Medium',
        value: 'm'
    },
    {
        label: 'Large',
        value: 'l'
    },
    {
        label: 'XL',
        value: 'xl'
    },
    {
        label: 'XXL',
        value: 'xxl'
    },
    {
        label: 'XXXL',
        value: 'xxxl'
    }
];

export const useCaseComplexityOptions = [
    {
        label: 'Low',
        value: 'low'
    },
    {
        label: 'Medium',
        value: 'medium'
    },
    {
        label: 'High',
        value: 'high'
    },
    {
        label: 'Very High',
        value: 'veryHigh'
    }
];

export const useCaseFormKeyValues = [
    {
        label: 'Complexity',
        value: 'useCaseComplexity'
    },
    {
        label: 'Description',
        value: 'useCaseDesc'
    },
    {
        label: 'Name',
        value: 'useCaseName'
    },
    {
        label: 'Size',
        value: 'useCaseSize'
    },
    {
        label: 'Owner',
        value: 'useCaseOwner'
    },
    {
        label: 'Type',
        value: 'useCaseType'
    },
    {
        label: 'Dependencies',
        value: 'dependencies'
    }
];

export const techValuesOptions = [
    {
        label: "SAP Business object",
        value: "sapBusinessObject"
    },
    {
        label: "SQL Reporting",
        value: "sqlReporting"
    }
];

export const trainingLifeCycleOptions = [
    {
        label: "Real time",
        value: "realTime"
    },
    {
        label: "Hourly",
        value: "hourly"
    },
    {
        label: "Daily",
        value: "daily"
    },
    {
        label: "Weekly",
        value: "weekly"
    },
    {
        label: "Monthly",
        value: "monthly"
    },
    {
        label: "Querterly",
        value: "quarterly"
    },
    {
        label: "Yearly",
        value: "yearly"
    }
];

export const apiLayerOptions = [
    {
        label: "Custom",
        value: "custom"
    },
    {
        label: "COTs",
        value: "ctos"
    },
    {
        label: "SaaS",
        value: "saas"
    }
]