import { Button, Checkbox, CheckboxGroup, Search, Tag } from "@appkit4/react-components";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSurveyListData } from "../../../store/project/projectThunks";
import { selectOldSurveyListingData, selectSelectedProjectData, selectSurveyListingData } from "../../../store/project/projectSelectors";
import { setOldSurveyListOptions } from "../../../store/project/projectSlice";
import { SurveyDTO } from "../../../api";
import { assetTypeOptions, dataAssetsWithPreviewMapping } from "../../../services/constants";

const SelectAssetItems: React.FC<{ setActiveIndex: Function, setIsRequestInfoClicked: Function, selectedSurveys: Array<string>, setSelectedSurveys: Function }> = ({ setActiveIndex, setIsRequestInfoClicked, selectedSurveys, setSelectedSurveys }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [pageNumber, setPageNumber] = useState(1);
    const selectedProject = useSelector(selectSelectedProjectData);
    const surveyListOptions = useSelector(selectSurveyListingData);
    const oldSurveyListOptions = useSelector(selectOldSurveyListingData);
    const [searchText, setSearchText] = useState("");
    const handleSearch = (e: string) => {
        dispatch(setOldSurveyListOptions([]))
        setPageNumber(0);
        setSearchText(e);
    }
    const onChange = (value: (string | number)[]) => {
        setSelectedSurveys(value as Array<string>);
    };
    const handleScroll = (event: any) => {
        const target = event?.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight && surveyListOptions?.data?.length === 10) {
            dispatch(setOldSurveyListOptions(surveyListOptions?.data));
            setPageNumber(pageNumber + 1)
        }
    }
    let options = JSON.parse(JSON.stringify(surveyListOptions?.data))
    if (surveyListOptions && surveyListOptions.data && surveyListOptions?.data?.length > 0 && oldSurveyListOptions?.length > 0) {
        options = [...oldSurveyListOptions, ...options];
    }
    useEffect(() => {
        if (selectedProject?.projectBusinessId)
            dispatch(getSurveyListData({ pageNumber: pageNumber, pageSize: 10, projectBusinessId: selectedProject?.projectBusinessId, surveyStatus: "" }));
    }, [dispatch, pageNumber, selectedProject]);
    return (
        <div className="my-4">
            <h1 className="font-sans font-medium text-xl ml-4 mt-2">Select items</h1>
            <div className="m-4">Here find a list of items where you can choose which assets you want to start surveys for to request information. Note that the list includes both use cases and assets. While use cases don't have surveys, they are made up of assets that do. Be sure to select the assets within the use cases to begin the survey process.</div>
            <div className="bg-white">
                <div className="p-4">
                    <Search className="pr-10 w-2/3"
                        onSearch={handleSearch} placeholder="Search" searchValue={searchText} onChange={handleSearch} />
                    {/* {selectedSurveys?.length > 0 &&
                        <div className="flex gap-0.5 flex-wrap">
                            {selectedSurveys.map((selectedSurvey, index) =>
                                <Tag key={index} type='filled' onClose={() => setSelectedSurveys(selectedSurveys.filter((database) => database !== selectedSurvey))}>{options?.filter((survey: SurveyDTO) => survey.assetBusinessId === selectedSurvey)?.[0]?.assetName}</Tag>
                            )}
                        </div>
                    } */}
                </div>
                <div className="bg-white">
                    <div className="flex gap-2">
                        <div className="w-2/3">
                            <div className="border-2 p-4 m-4 h-72 overflow-auto" onScroll={handleScroll}>
                                <div className="flex justify-start">
                                    <div className="py-4 pl-4 w-1/3 font-bold">Item</div>
                                    <div className="py-4 w-1/3 font-bold">Type</div>
                                </div>
                                <CheckboxGroup value={selectedSurveys} name="survey" onChange={onChange}>
                                    {options?.map((survey: SurveyDTO) =>
                                        <div className="flex justify-start">
                                            <Checkbox className="w-1/3 p-2" value={survey?.assetBusinessId}>
                                                <span className={`Appkit4-icon icon-${dataAssetsWithPreviewMapping.filter(dataAsset => dataAsset.name === survey.assetType)[0]?.prefixIcon}-outline ap-font-24 px-4`} aria-hidden="true"></span>
                                                <span className="pl-2">{survey.assetName}</span>
                                            </Checkbox>
                                            <div className="w-1/3 p-2">{assetTypeOptions.filter(option => option.value === survey?.assetType)?.[0]?.label}</div>
                                        </div>)}
                                </CheckboxGroup>
                            </div>
                        </div>
                        <div className="w-1/3 p-6 m-4 bg-[#F3F3F3]">
                            <Checkbox checked>Selected items</Checkbox>
                            <CheckboxGroup value={selectedSurveys} name="survey" onChange={onChange}>
                                {selectedSurveys?.map((survey: string) =>
                                    <div className="flex justify-start">
                                        <Checkbox className="w-1/3 p-2" checked>
                                            {options.filter((option: SurveyDTO) => option.assetBusinessId === survey)?.[0].assetName}
                                        </Checkbox>
                                    </div>)}
                            </CheckboxGroup>
                        </div>
                    </div>
                    <div className="flex justify-end gap-2 p-4">
                        <Button kind='secondary' onClick={() => setIsRequestInfoClicked(false)}>Back</Button>
                        <Button kind='primary' disabled={selectedSurveys?.length < 1}icon="icon-check-hook-outline" onClick={() => setActiveIndex(1)}>Save and next</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SelectAssetItems;