import React from "react"
import { useSelector } from "react-redux"
import dayjs from "dayjs"
import { Button, Input } from "@appkit4/react-components"
import { selectSurveyDetails } from "../../../store/project/projectSelectors"

const MigrationPlanning: React.FC<{ setActiveIndex: Function }> = ({ setActiveIndex }) => {
    const surveyDetailsData = useSelector(selectSurveyDetails);
    const surveyData = JSON.parse(JSON.stringify(surveyDetailsData?.surveyDetailsDTO?.surveyData));
    return (
        <div>
            <div className="flex justify-between">
                <h1 className="font-sans font-bold font-medium text-2xl py-4">Migration planning</h1>
                {surveyDetailsData?.surveyDetailsDTO?.updatedAt && surveyDetailsData?.surveyDetailsDTO?.updatedAt !== "" &&
                    <div className="w-fit mr-4 mt-4">
                        <span className={`Appkit4-icon icon-update-outline ap-font-24 mr-2`} aria-hidden="true"></span>
                        <span>Last saved at {dayjs(new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "")).format("MM/DD/YYYY")}, </span>
                        <span>{Math.floor((new Date().getTime() - new Date(surveyDetailsData?.surveyDetailsDTO?.updatedAt || "").getTime()) / (1000 * 60 * 60 * 24))} days ago</span>
                    </div>
                }
            </div>
            <div className="p-8 bg-white">
                <div className="my-4">
                        <div className="mt-4 mb-2">What is the acceptable downtime window for this asset?</div>
                        <Input title="Downtime" readonly value={surveyData?.migrationPlanning?.downTime} />
                </div>
                <div className="flex justify-end gap-2">
                    <Button kind='secondary' icon="icon-arrow-left-outline" onClick={() => setActiveIndex(2)}>Back</Button>
                </div>
            </div>
        </div>
    )
}

export default MigrationPlanning;